@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.page-background {
    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80vh;
        max-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        opacity: 0.3;
        &:after {
            content: "";
            background: linear-gradient(transparent, $base-black);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            max-height: 20vh;
            height: 250px;
        }
    }
}

.page-content {
    position: relative;
    z-index: 2;
    h3,
    h4,
    h5 {
        margin-bottom: 10px;
        @include pux-scale-from("margin-bottom", lg, 10px);
    }
}

.page-header {
    padding-top: 165px;
    padding-bottom: 60px;
    color: $base-white;
    position: relative;
    z-index: 2;
    @include media(lg) {
        padding-top: calc(11.5rem + #{rem(xxl, $menu-height)});
        padding-bottom: 6rem;
    }
    h1 {
        @include pux-scale-with-min("line-height", 76px, 38px);
        margin-bottom: 0;
    }
    &-menu {
        position: relative;
        overflow: hidden;
        &-inner {
            display: flex;
            justify-content: flex-start;
            width: calc(100% - 15px);
            overflow: auto;
            //flex-wrap: wrap;
            @include media(lg) {
                width: 100%;
                overflow: visible;
            }
        }
        &-item {
            //font-family: 'Zirkel-Medium';
            flex: 0 0 auto;
            font-weight: 500;
            font-size: 18px;
            padding-right: 30px;
            @include pux-scale-from("font-size", lg, 18px);
            @include pux-scale-from("padding-right", lg, 30px);
            white-space: nowrap;
            &.active,
            &:hover,
            &:focus {
                color: $base-white;
                font-weight: 900;
                outline: 0;
            }
            &.active {
                text-decoration: underline;
            }
            &:last-of-type {
                padding-right: 200px;
            }
        }
        .slick-prev {
            display: none !important;
        }
    }
}

.small-page-header {
    .page-header {
        @include media(xxs, md) {
            padding-bottom: 20px !important;
        }
    }
}

.text-page-header {
    .page-header {
        @include media(lg) {
            padding-top: calc(5.5rem + #{rem(xxl, $menu-height)});
            padding-bottom: 3rem;
        }
    }
}

.arrow-next {
    position: absolute;
    top: 0;
    right: 0;
    //background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #1b1b1b 100%);
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include transition;
    transform: translateX(50px);
    opacity: 0;
    &:after {
        content: "\e904";
        color: $base-white;
        font-size: 17px;
        @include transition;
        @include pux-scale-from("font-size", lg, 17px);
    }
    &.black-mode {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 100%);
        width: 25%;
        max-width: 150px;
    }
    &.white-mode {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
        width: 25%;
        max-width: 150px;
        &:after {
            color: $base-black;
        }
    }
    &:active {
        &:after {
            //color: $main-color;
        }
    }
    &.active {
        transform: translateX(0px);
        opacity: 1;
    }
}

.bread-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    .breadcrumbs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        overflow: auto;
        * {
            flex: 0 0 auto;
            white-space: nowrap;
            &.CMSBreadCrumbsCurrentItem {
                display: inline-block;
                padding-right: 150px;
            }
        }
    }
}

.submenu-btn {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 5px;
		right: -5px;
		@include flex-block;
		@include align-items(center);
		@include justify-content(center);
		cursor: pointer;
    @include media(md) {
        top: 10px;
    }
		@include media(lg) {
				display: none !important;
		}
		&:before {
				content: "\e91f";
				color: $base-white;
			  font-size: 24px;
				@include transition;
				@include rotate(90deg);
				font-weight: 800;
		}
		&.open {
				&:before {
						@include rotate(270deg);
				}
		}
}

.links-table {
		position: relative;
    max-width: 100%;
    overflow: hidden;
		@include media(lg) {
				display: block;
		}
		.row {
				width: 100%;
		}
		&-item {
				height: 40px;
				position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
				@include media(md) {
						height: 70px;
						flex: none;
						margin-top: 0;
				}
		}
		&.menu-links {
				border-top: 0;
        margin-top: 5px;
        @include media(md) {
            margin-top: 10px;
        }
				@include media(lg) {
						display: none;
				}
        .links-table-item {
            height: 30px;
            display: flex;
            align-items: center;
            a {
                &:before {
                    font-size: 23px;
                    @include transition;
                    @include media(sm) {
                        font-size: 36px;
                    }
                }
                &:hover,
                &:focus {
                    &:before {
                        color: $base-black;
                    }
                }
                &.ico-logo,
                &.ico-header-logo {
                    &:before {
                        font-size: 20px;
                        @include media(sm) {
                            font-size: 30px;
                        }
                    }
                }
                &.ico-logo2 {
                    &:before {
                        font-size: 26px;
                        @include media(sm) {
                            font-size: 40px;
                        }
                    }
                }
                &.ico-exe-technology {
                    &:before {
                        font-size: 28px;
                        @include media(sm) {
                            font-size: 40px;
                        }
                    }
                }
                &.ico-tomcat-logo {
                    &:before {
                        font-size: 20px;
                        @include media(sm) {
                            font-size: 30px;
                        }
                    }
                }
                &.ico-flexa-logo{
                  &:before {
                      font-size: 20px;
                      @include media(sm) {
                          font-size: 30px;
                      }
                  }
                }
                &.ico-mobiltechlifts {
                    &:before {
                        font-size: 32px;
                        @include media(sm) {
                            font-size: 44px;
                        }
                    }
                }
                &.ico-xstage {
                    &:before {
                        font-size: 26px;
                        @include media(sm) {
                            font-size: 40px;
                        }
                    }
                }
                &.ico-prolyte {
                    &:before {
                        font-size: 36px;
                        @include media(sm) {
                            font-size: 50px;
                        }
                        @include media(md) {
                            font-size: 58px;
                        }
                    }
                }
            }
        }
		}
}

.hp-banner {
    position: relative;
    width: 100%;
    z-index: 5;
    @include media(lg) {
        padding-top: rem(xxl, $menu-height);
    }
    &-video {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        &:after {
            content: "";
            background: linear-gradient(transparent, $base-black);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            max-height: 15vh;
            height: 150px;
        }
        video {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    &-content {
        width: 100%;
        position: relative;
        z-index: 1;
        min-height: 100vh;
        @include media(lg) {
            flex-direction: row;
            display: flex;
            align-items: stretch;
            min-height: 0;
            min-height: calc(100vh - #{rem(xxl, $menu-height)});
        }
        &-left {
            width: 100%;
            @include media(lg) {
                width: 71rem;
                display: flex;
                flex-direction: column;
                min-height: calc(100vh - #{rem(xxl, $menu-height)}) ;
            }
            &-title {
                color: $base-white;
                //padding: 160px 0 60px 0;
                opacity: 0;
                animation: show linear 500ms forwards;
                animation-delay: 0.5s;
                display: flex;
                align-items: center;
                height: 76vh;
                padding-top: 60px;
                @include media(lg) {
                    flex: 1;
                    padding: 0;
                    margin: 0;
                    height: auto;
                    display: flex;
                    align-items: center;
                    margin: 3rem 0;
                    transform: translateY(4.3rem);
                }
                h1 {

                }
            }
            &-box {
            }
        }
        &-right {
            @include media(lg) {
                display: flex;
                align-items: flex-end;
                min-height: calc(100vh - #{rem(xxl, $menu-height)});
                flex: 0 0 47rem;
                width: 47rem;
            }
        }
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.redbox {
    padding: 40px 20px 20px;
    background-color: $main-color;
    position: relative;
    width: 100%;
    margin-top: 10px;
    opacity: 0;
    animation: show linear 1000ms forwards;
    animation-delay: 1.3s;
    @include media(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;
    }
    @include media(lg) {
        margin: 0;
        padding: 3rem 2.8rem 3rem 4.5rem;
        transform: translate(-4rem, 8.6rem);
    }
    &-inner{
      width: 100%;
      @include media(md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 15px;
      }
    }
    &-content {
        font-size: 14px;
        color: $base-white;
        //font-family: 'Zirkel-Medium';
        font-weight: 500;
        @include media(md) {
            //flex: 0 1 calc(100% - 120px - 4rem);
            flex: 1;
            max-width: calc(100% - 150px)
        }
        @include media(lg) {
            //flex: 0 1 calc(100% - #{rem(lg, 120px)} - 4rem);
            max-width: calc(100% - #{rem(lg, 120px)} - 4rem);
        }
        @include pux-scale-from("font-size", lg, 14px);
        @include media(xl) {
            //flex: 0 1 calc(100% - #{rem(xl, 120px)} - 4rem);
            max-width: calc(100% - #{rem(xl, 120px)} - 4rem);
        }
        @include media(xxl) {
            //flex: 0 1 calc(100% - #{rem(xxl, 120px)} - 4rem);
            max-width: calc(100% - #{rem(xxl, 120px)} - 4rem);
        }
        &-list {
            display: grid;
            justify-items: center;
            grid-template-columns: 1fr 1fr;
            @include media(xs) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include media(sm) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            @include media(md) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include media(lg) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            @include media(xl) {
                grid-template-columns: 1fr 1.5fr 1.5fr 1fr 1fr;
            }
            .link-item {
                margin-right: 10px;
                margin-top: 20px;
                display: inline-flex;
                align-items: flex-end;
                @include media(md) {
                    margin-right: 40px;
                }
                @include media(lg) {
                    margin-top: 2.6rem;
                    margin-right: 1rem;
                }
                a {
                    &:before {
                        @include transition;
                        font-size: 25px;
                        @include media(lg) {
                            font-size: 2.5rem;
                        }
                    }
                    &:hover,
                    &:focus {
                        &:before {
                            color: $base-black;
                        }
                    }
                    &.ico-logo,
                    &.ico-header-logo {
                        &:before {
                            font-size:  22px;
                            @include media(lg) {
                                font-size: 2.2rem;
                            }
                        }
                    }
                    &.ico-logo2 {
                        &:before {
                            font-size:  26px;
                            @include media(lg) {
                                font-size: 2.6rem;
                            }
                        }
                    }
                    &.ico-mobiltechlifts {
                        &:before {
                            font-size:  33px;
                            @include media(lg) {
                                font-size: 3.3rem;
                            }
                        }
                    }
                    &.ico-xstage {
                        &:before {
                            font-size:  25px;
                            @include media(lg) {
                                font-size: 2.5rem;
                            }
                        }
                    }
                    &.ico-prolyte{
                        &:before {
                            font-size: 38px;
                            transform: translateY(16%);
                            display: inline-block;
                            @include media(xxl) {
                                font-size: 3.8rem;
                                transform: translateY(21%);
                            }
                        }
                    }
                    &.ico-prolyft-logo,
                    &.ico-stagedex-logo {
                        &:before {
                            font-size: 38px;
                            transform: translateY(16%);
                            display: inline-block;
                            @include media(xxl) {
                                font-size: 3.8rem;
                                transform: translateY(21%);
                            }
                        }
                    }
                }
            }
        }
        h3 {
            @include pux-static-size("font-size", 34px);
            @include pux-static-size("line-height", 38px);
        }
    }
    &-link {
        margin-top: 40px;
        @include media(md) {
            order: 3;
        }
        @include media(md) {
            flex: 0 0 calc(120px + 4rem);
            max-width: calc(120px + 4rem);;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0;
        }
        @include media(lg) {
            flex: 0 0 16rem;
            max-width: 16rem;
        }
        .arrow-btn {
            margin-top: 0.4rem;
            @include pux-scale-with-min("line-height", 38px, 28px);
        }
    }
    &-logos {
        @include media(md) {
            flex: 0 1 100%;
            max-width: 100%;
        }
    }
}

.hp-youtube {
    color: $base-white;
    opacity: 0;
    animation: show linear 500ms forwards;
    animation-delay: 1.8s;
    @include media(xxs, md) {
        position: static !important;
        animation-delay: 0.8s;
    }
    @include media(lg) {
        transform: translate3d(5rem,0,0);
        //padding-bottom: 3.4rem;
        position: relative;
        pointer-events: none;
        //position: fixed;
        pointer-events: auto;
        will-change: scroll-position;
        transform-origin: center center;
        bottom: 0;
    }
    &.fixed {
        @include media(lg) {
            position: fixed;
            transform: translate3d(5rem,0,0) !important;
        }
        &.transition-on {
            @include media(lg) {
                transition: transform 200ms linear;
            }
        }
    }
    &.small-size {
        @include media(lg) {
            transform-origin: center center;
            transform: translate(25%, 25%) scale(0.5) !important;
            padding-bottom: 2rem;
            padding-right: 3rem;
            right: 0;
            bottom: 0;
            transition: all 200ms linear;
        }
        &:hover,
        &:focus {
            @include media(lg) {
                transform: translate(0, 0rem) scale(1) !important;
            }
        }
        &.fix-size {
            @include media(lg) {
                transition-duration: 200ms !important;
                transform: translate(0, 0rem) scale(1) !important;
            }
        }
    }
    &-title {
        font-size: 24px;
        margin-bottom: 5px;
        @include media(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @include media(lg) {
            font-size: 2.4rem;
            margin-bottom: 1rem;
        }
        .youtube-title {
            //font-family: 'Zirkel-Black';
            font-weight: 900;
            @include pux-static-size("font-size", 24px);
        }
        .see-more {
            display: none;
            @include media(md) {
                display: flex;
            }
            @include pux-static-size("font-size", 18px);
        }
    }
    &-content {
        position: relative;
        overflow: hidden;
        background-color: $base-black;
        @include media(lg) {
            width: 47rem;
        }
        &:before {
            content: "";
            display: block;
            padding-top: 67%;
        }
        &-play {
            width: 75px;
            height: 75px;
            background-color: $base-white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
            cursor: pointer;
            opacity: 0;
            @include transition;
            @include media(xl) {
                width: 9rem;
                height: 9rem;
            }
            &:after {
                content: "";
                display: block;
                @include triangle-right(17px, 17px, 31px, $base-black);
                transform: translateX(12%);
                @include transition;
                @include media(xl) {
                      @include triangle-right(1.7rem, 1.7rem, 3.1rem, $base-black);
                }
            }
            &:hover,
            &:focus {
                background-color: $main-color;
                &:after {
                    border-right-color: $base-white;
                }
            }
            &.visible {
                opacity: 1;
            }
        }
        #hp-youtube-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}

.edge,
.IE11 {
    .hp-youtube.small-size {
        &:hover,
        &:focus {
            @include media(lg) {
                transform: translate(25%, 25%) scale(0.5);
            }
        }
    }
}

.IE11 {
    .redbox-content-list {
        @include media(xxl) {
            width: 100%;
        }
        .link-item {
            @include media(xxl) {
                text-align: center;
            }
            &:nth-of-type(1),
            &:nth-of-type(6) {
                @include media(xxl) {
                    max-width: 13%;
                    width: 13%;
                }
            }
            &:nth-of-type(2),
            &:nth-of-type(7) {
                @include media(xxl) {
                    max-width: 20%;
                    width: 20%;
                }
            }
            &:nth-of-type(3),
            &:nth-of-type(8) {
                @include media(xxl) {
                    max-width: 20%;
                    width: 20%;
                }
            }
            &:nth-of-type(4),
            &:nth-of-type(9) {
                @include media(xxl) {
                    max-width: 15%;
                    width: 15%;
                }
            }
            &:nth-of-type(5),
            &:nth-of-type(10) {
                @include media(xxl) {
                    max-width: 18%;
                    width: 18%;
                }
            }
        }
    }
}

.mobile-show {
    @include media(lg) {
        display: none;
    }
}

.desktop-show {
    @include media(xxs, md) {
        display: none;
    }
    .arrow-btn {
        z-index: auto;
    }
}

.hp-education {
    margin-top: 50px;
    max-width: 100%;
    overflow: hidden;
    @include media(md) {
        margin-top: 17.5rem;
    }
    &-top {
        @include media(md) {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        &-content {
            @include media(md) {
                flex: 0 1 50%;
                max-width: 50%;
            }
        }
        &-link {
            @include media(md) {
                flex: 0 0 150px;
                max-width: 150px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
            @include media(xl) {
                flex: 0 0 15rem;
                max-width: 15rem;
            }
        }
    }
    &-list {
        margin-top: 30px;
        @include media(lg) {
            margin-top: 4rem;
        }
    }
    .slick-list {
        width: 80vw;
        overflow: visible;
        * {
            outline: 0 !important;
        }
    }
}

.space-big {
    .row {
        @include pux-scale-from("margin-right", lg, -20px);
        @include pux-scale-from("margin-left", lg, -20px);
        [class*=col-] {
            @include pux-scale-from("padding-right", lg, 20px);
            @include pux-scale-from("padding-left", lg, 20px);
        }
    }
}

.space-medium {
    .row {
        @include pux-scale-from("margin-right", lg, -13px);
        @include pux-scale-from("margin-left", lg, -13px);
        [class*=col-] {
            @include pux-scale-from("padding-right", lg, 13px);
            @include pux-scale-from("padding-left", lg, 13px);
        }
    }
}

.space-30 {
    .row {
        @include pux-scale-from("margin-right", lg, -15px);
        @include pux-scale-from("margin-left", lg, -15px);
        [class*=col-] {
            @include pux-scale-from("padding-right", lg, 15px);
            @include pux-scale-from("padding-left", lg, 15px);
        }
    }
}

.space-small {
    .row {
        margin: 0 -10px;
        @include pux-scale-from("margin-right", lg, -10px);
        @include pux-scale-from("margin-left", lg, -10px);
        [class*=col-] {
            padding: 10px;
            @include pux-scale-from("padding", lg, 10px);
        }
    }
}

.space-card {
    .row {
        margin: 0 -10px;
        @include pux-scale-from("margin-right", lg, -15px);
        @include pux-scale-from("margin-left", lg, -15px);
        [class*=col-] {
            padding-top: 0;
            padding-bottom: 22px;
            @include pux-scale-from("padding-left", lg, 15px);
            @include pux-scale-from("padding-right", lg, 15px);
            @include pux-scale-from("padding-bottom", lg, 22px);
        }
    }
}

.thin-vertical-space {
    [class*=col-] {
        @include media(xxs, xs) {
            padding-top: 0 !important;
            padding-bottom: 10px !important;
        }
    }
}

.hp-news {
    position: relative;
    padding-bottom: 30px;
    &-bg {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        &-container {
            position: absolute;
            top: 0%;
            right: 0;
            width: 60%;
            height: 100%;
            display: none;
            @include media(lg) {
                display: flex;
            }
        }
    }
    &-content {
        position: relative;
        z-index: 2;
        margin-top: 85px;
        @include media(lg) {
            margin-top: 10.5rem;
        }
        &-text {
            @include media(md) {
                padding-right: 20%;
            }
            @include media(lg) {
                padding-right: 40%;
            }
        }
        &-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 85px;
            @include media(lg) {
                margin-top: 12.5rem;
            }
        }
        &-list {
            padding-bottom: 20px;
            @include media(md) {
                padding-bottom: 0;
            }
            .row {
                @include media(xxs,sm) {
                    margin: 0 -10px;
                }
            }
            [class^="col-"] {
                @include media(xxs, sm) {
                    padding: 20px 10px 0;
                }
                &:first-of-type {
                    @include media(xxs, sm) {
                        padding-top: 10px;
                    }
                }
            }
        }
    }
}

.top-projects {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    &-bg {
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        &-container {
            position: absolute;
            top: 0%;
            left: 0;
            width: 60%;
            height: 100%;
            display: none;
            @include media(lg) {
                display: flex;
            }
        }
    }
    &-content {
        position: relative;
        z-index: 2;
        width: 100%;
        &-text {
            padding-top: 20px;
            @include media(md) {
                padding-top: 13rem;
                max-width: 50rem;
            }
        }
        &-list {
            padding-top: 30px;
            @include media(lg) {
                padding-top: 5rem;
            }
            .slick-list {
                width: 80vw;
                overflow: visible;
                * {
                    outline: 0 !important;
                }
            }
            .row {
                @include media(xxs,sm) {
                    margin: 0 -10px;
                }
            }
            [class^="col-"] {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &-bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 60px;
            @include media(lg) {
                margin-top: 16rem;
            }
            &-text {
                max-width: 60rem;
            }
            a {
                margin-top: 35px;
                @include pux-scale-from("margin-top", lg, 20px);
            }
        }
    }
}

.footer-newsletter-wrapper {
    padding-top: 17rem;
}

.hp-top-image{
    position: relative;
    min-height: 350px;
    @include media(xxl){
        height: 100dvh;
    }
}

.hp-top-image-bgc{
    position: relative;
    //height: 100dvh;
    @include media(xxl){
        height: 100dvh;
    }
    img{
        //position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

.hp-top-image-text{
    text-align: center;
    z-index: 33;
    padding-bottom: 3rem;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 14rem;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%,  rgba(0, 0, 0, 0) 100%);
        z-index: 22;
    }
    .pux-container{
        position: relative;
        z-index: 33;
    }
    h1{
        @include pux-scale-with-min("font-size", 46px, 28px);
        @include pux-scale-with-min("line-height", 50px, 32px);
        font-weight: 900;
        text-shadow: rgba(0, 0, 0, 0.6) 1px 0 10px;
    }
}

.hp-top-image-bottom{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.hp-brand-slider{
    background: #f73c37;
    padding: 15px 0;
    @include media(md){
        padding: 25px 0;
    }
    a {
        &:before {
            @include transition;
            font-size: 25px;
            @include media(lg) {
                font-size: 2.5rem;
            }
        }
        &:hover,
        &:focus {
            &:before {
                color: $base-black;
            }
        }
        &.ico-logo,
        &.ico-header-logo {
            &:before {
                font-size:  22px;
                @include media(lg) {
                    font-size: 2.2rem;
                }
            }
        }
        &.ico-logo2 {
            &:before {
                font-size:  26px;
                @include media(lg) {
                    font-size: 2.6rem;
                }
            }
        }
        &.ico-mobiltechlifts {
            &:before {
                font-size:  33px;
                @include media(lg) {
                    font-size: 3.3rem;
                }
            }
        }
        &.ico-xstage {
            &:before {
                font-size:  25px;
                @include media(lg) {
                    font-size: 2.5rem;
                }
            }
        }
        &.ico-prolyte{
            &:before {
                font-size: 38px;
                display: inline-block;
                @include media(xxl) {
                    font-size: 3.8rem;
                }
            }
        }
        &.ico-prolyft-logo,
        &.ico-stagedex-logo {
            &:before {
                font-size: 38px;
                display: inline-block;
                @include media(xxl) {
                    font-size: 3.8rem;
                }
            }
        }
    }
}

.hp-brand-slider-inner{
    display: flex;
    .link-item{
        flex-grow: 1;
        //width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
    &.slick-initialized{
        display: block;
    }
}

.hp-top-image + .hp-education{
    margin-top: 4rem;
}