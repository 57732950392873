
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
@include font-face-otf("Zirkel-Black", "/AFI/media/system/css/fonts/Zirkel-Black", 900);
@include font-face-otf("Zirkel-Bold", "/AFI/media/system/css/fonts/Zirkel-Bold", 700);
@include font-face-otf("Zirkel-Medium", "/AFI/media/system/css/fonts/Zirkel-Medium", 500);
@include font-face-otf("Zirkel-Regular", "/AFI/media/system/css/fonts/Zirkel-Regular", 400);

@include font-face-woff("Zirkel-Black", "/AFI/media/system/css/fonts/zirkel-black", 900);
@include font-face-woff("Zirkel-Bold", "/AFI/media/system/css/fonts/zirkel-bold", 700);
@include font-face-woff("Zirkel-Medium", "/AFI/media/system/css/fonts/Zirkel-Medium", 500);
@include font-face-woff("Zirkel-Regular", "/AFI/media/system/css/fonts/zirkel-regular", 400);
*/

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-light.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-light.otf) format('otf');
  font-weight: 300;
}

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-regular.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-Regular.otf) format('otf');
  font-weight: 400;
}
@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-Medium.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-Medium.otf) format('otf');
  font-weight: 500;
}
@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-bold.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-Black.otf) format('otf');
  font-weight: 700;
}
@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-black.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-Black.otf) format('otf');
  font-weight: 900;
}

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-LightItalic.woff) format('woff'),
       url(/AFI/media/system/css/fonts/Zirkel-LightItalic.otf) format('otf');
  font-weight: 400;
	font-style: italic;
}

.text-medium {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    @include pux-scale-from("font-size", lg, 18px);
}

.top-space-base {
    margin-top: 40px;
    @include media(lg) {
        margin-top: 4.5rem;
    }
}

.helper-4k{
  max-width: 200rem;
  margin:auto;
}

.hide{
  display: none !important;
}

.show{
  display:block !important;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.bold{
  font-weight: bold;
}

.underline{
  text-decoration: underline;
}

.line-through{
  text-decoration: line-through;
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size*$scale-ratio-xxl;
		}
    @include breakpointhelper(1600px, 0) {
        font-size: $scale-base-size*$scale-ratio-1600;
    }
    @include breakpointhelper(1920px, 0) {
        font-size: $scale-base-size*$scale-ratio-1920;
    }
    @include breakpointhelper(2048px, 0) {
        //font-size: $scale-base-size*$scale-ratio-2048;
    }
    @include breakpointhelper(2304px, 0) {
        //font-size: $scale-base-size*$scale-ratio-2304;
    }
    @include breakpointhelper(2560px, 0) {
        //font-size: $scale-base-size*$scale-ratio-2560;
    }
    @include breakpointhelper(2880px, 0) {
        //font-size: $scale-base-size*$scale-ratio-2880;
    }
    @include breakpointhelper(3440px, 0) {
        //font-size: $scale-base-size*$scale-ratio-3440;
    }
    @include breakpointhelper(3840px, 0) {
        //font-size: $scale-base-size*$scale-ratio-3840;
    }
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl * $ratio-125;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl * $ratio-125;
				}
        @include breakpointhelper(1600px, 0) {
            font-size: $scale-base-size*$scale-ratio-1600 * $ratio-125;
        }
        @include breakpointhelper(1920px, 0) {
            font-size: $scale-base-size*$scale-ratio-1920 * $ratio-125;
        }
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $base-white;
    background-color: $base-black;
}

body{
	 @include pux-scale-with-min("font-size", 16px, 14px);
   &[white-theme] {
      color: $base-black;
      background-color: $base-white;
      header {
          background-color: $base-white;
          color: $base-black;
          &:after {
              display: none;
          }
          .header-right-mobile-menu-inner {
              span {
                  background: $base-black;
                  &:after,
                  &:before {
                      background: $base-black;
                  }
              }
          }
          #menuElem {
              & > li > a {
                  @include media(lg) {
                      color: $base-black;
                  }
              }
          }
      }
      main,
      footer {
          color: $base-black;
          background-color: $base-white;
          .footer-newsletter {
              padding-top: 10rem;
          }
          .ico-social {
              &:before {
                  color: $base-black;
              }
          }
      }
   }
}

.bg-black {
    background-color: $base-black;
    color: $base-white;
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
  body .pt-#{strip-unit($value)} {
			@include pux-scale-with-min("padding-top", $value, 10px);
  }

  body .pb-#{strip-unit($value)} {
			@include pux-scale-with-min("padding-bottom", $value, 10px);
  }

  body .mt-#{strip-unit($value)} {
			@include pux-scale-with-min("margin-top", $value, 10px);
  }

  body .mb-#{strip-unit($value)} {
			@include pux-scale-with-min("margin-bottom", $value, 10px);
  }
}

h1,
.h1{
  @include media(xxs, xxs) {
      font-size: 34px;
      line-height: 38px;
  }
  @include pux-scale-with-min("font-size", 72px, 34px);
  @include pux-scale-with-min("line-height", 76px, 38px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

h2,
.h2{
  @include pux-scale-with-min("font-size", 54px, 30px);
  @include pux-scale-with-min("line-height", 58px, 34px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

h3,
.h3{
  @include pux-scale-with-min("font-size", 34px, 24px);
  @include pux-scale-with-min("line-height", 38px, 28px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

h4,
.h4{
  @include pux-scale-with-min("font-size", 24px, 18px);
  @include pux-scale-with-min("line-height", 28px, 22px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

h5,
.h5{
  @include pux-scale-with-min("font-size", 18px, 16px);
  @include pux-scale-with-min("line-height", 22px, 20px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

h6,
.h6{
  @include pux-scale-with-min("font-size", 14px, 14px);
  @include pux-scale-with-min("line-height", 18px, 18px);
  font-weight: 900;
  //font-family: 'Zirkel-Black';
}

big,
.perex{
  @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
  @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
}

small,
.text-small{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
}

a{
    color:$color-link;
    cursor: pointer;
    font-weight: 400;
    &:hover{
      color:$color-link;
      //font-family: 'Zirkel-Medium';
      font-weight: 500;
      outline:none;
      text-decoration: none;
    }
}

main {
    a{
        &:active{
          outline: none;
          text-decoration: underline;
          font-weight: 500;
        }
    }
}

.btn{
  display: inline-block;
  @include border-radius(0);
  border: 1px solid $base-white;
  padding:1.5rem 4rem;
  @include box-shadow(none);
  font-size: 16px;
  font-weight: 900;
  outline: none!important;
  @include media(md){
    padding:1.5rem 6rem;
  }
  @include pux-scale-from("font-size", lg, 16px);
  &:focus,
  &:hover{
    text-decoration: none;
    @include box-shadow(none);
  }
}

.arrow-btn{
  //@include flex-block();
  display: inline-flex;
  @include align-items(center);
  font-size: 18px;
  @include pux-scale-from("font-size", lg, 18px);
  position: relative;
  z-index: 2;
  text-decoration: none !important;
  &:before,
  &:after{
    content: "\e908";
    width: 30px;
    height: 30px;
    font-size: 29px;
    margin-left: 10px;
    @include border-radius(50%);
    border: 2px solid #fff;
    @include flex-block();
    @include align-items(center);
    @include justify-content(center);
    font-weight: 900;
    text-decoration: none !important;
    padding-top: 0.24rem;
    @include media(md) {
        padding-top: 0;
    }
    @include pux-scale-from("font-size", lg, 29px);
    @include pux-scale-from("width", lg, 30px);
    @include pux-scale-from("height", lg, 30px);
    @include pux-scale-from("margin-left", lg, 10px);
  }
  &:before{
    content: "\e90d";
    margin-left: 0;
    margin-right: 10px;
    display: none;
    @include pux-scale-from("margin-right", lg, 10px);
  }
  &.back{
    padding-top: 2rem;
    transform: translateY(70px);
    position: relative;
    z-index: 8;
    @include media(lg) {
        transform: translateY(8.5rem);
    }
    &:after{
      display: none;
    }
    &:before{
      @include flex-block();
    }
  }
  &:hover{
    color: $base-white;
    &:after,
    &:before{
      background: #fff;
      color: #000;
    }
  }
  &.back-black{
    position: relative;
    z-index: 8;
    color: $base-black;
    &:after{
      display: none;
    }
    &:before{
      @include flex-block();
      color: $base-black;
      border-color: $base-black;
    }
    &:hover,
    &:focus {
        color: $base-black;
        &:before {
            background-color: $base-black;
            border-color: $base-black;
            color: $base-white;
        }
    }
  }
  &:active{
    text-decoration: none;
    &:after,
    &:before{
      color: $main-color;
    }
  }
}

.FormButton{
  outline: none!important;
  border-color: $base-white!important;
}

.btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  &:focus,
  &:hover{
    text-decoration: none;
    background: #efefef;
    color: $btn-primary-color;
    font-weight: 900 !important;
  }
  &:active{
    background: $main-color;
    color: $base-white;
  }

}

.btn-default{
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-default-background, 5);
      color: $btn-default-color;
  }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:40rem;
    background-color: $base-black;
    position: relative;
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 1.5rem 0;
            padding:0 0 0 2rem;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:3px;
                font-weight: bold;
                font-family: $font-family !important;
            }
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            margin:0 0 1.5rem 0;
            padding:0 0 0 1.5rem;
            position:relative;
            &:before{
                content:"";
                width:6px;
                height:6px;
                background:$ul-bullet;
                position:absolute;
                top:6px;
                left:0;
                @include border-radius(50%);
            }
        }
    }
    table{
      width:100%;
      tr{
        border-bottom: 1px solid $table-tr-border;
        td{
          padding:1rem 1.5rem;
        }
        &:nth-child(even){
          background: $table-even-background;
        }
      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media($respo-table-break){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:1rem 1.5rem;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 2rem 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media($respo-table-break){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media($respo-table-break){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 1.5rem 0 0;
                  @include media($respo-table-break){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}

.text-underline {
    text-decoration: underline;
}


.btn-prize,
.prize-form-submit input[type=submit],
.prize-form-file label {
		padding: 0rem 9rem;
	  min-height: 40px;
	  font-size: 15px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		color: $base-white;
		font-weight: 700 !important;
		@include transition;
		@include media(md) {
				font-size: 2rem;
				min-height: 4rem;
		}
}

.btn-pink {
		background-color: #f677ba;
		&:hover,
		&:focus {
				background-color: darken(#f677ba, 5);
		}
}

.btn-orange {
		background-color: #ffa200;
		&:hover,
		&:focus {
				background-color: darken(#ffa200, 5);
		}
}

.btn-purple {
		background-color: #9a18e0;
		&:hover,
		&:focus {
				background-color: darken(#9a18e0, 5);
		}
}

.prize-h2 {
		font-size: 5.4rem;
		font-weight: 400;
		font-style: italic;
		letter-spacing: normal;
		line-height: 1.08;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
}

.prize-h2-text h2 {
		font-size: 5.4rem;
		font-weight: 400;
		font-style: italic;
		letter-spacing: normal;
		line-height: 1.08;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
}
