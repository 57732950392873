
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: rem(xxl, $container-max-width);
    @include media(xl) {
        width: 91%;
    }
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row,
    .items-container{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    [class^="col-"]{
				padding: $col-padding-left;
        //padding-right: $col-padding-right;
        @include media(md){
          padding: $col-padding-left-md;
          //padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

header{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
		background-color: transparent;
		width: 100%;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 14rem;
        width: 100%;
        z-index: -1;
        pointer-events: none;
        @include media(lg) {
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000000 100%);
        }
    }
		.header {
				@include flex-block;
				@include justify-content(space-between);
				height: 60px;
        width: 100%;
        max-width: rem(xl, $max-page-width);
        border-bottom: 1px solid $main-color;
        margin: 0 auto;
        @include media(lg) {
            height: rem(xxl, $menu-height);
        }
				&-left {
						@include flex-block;
						@include align-items(center);
						@include justify-content(space-between);
						@include flex(0 0 50%);
						max-width: 50%;
						@include media(lg) {
								@include flex(0 0 16rem);
								max-width: 16rem;
								@include justify-content(flex-start);
						}
						&-logo {
                padding-left: 2px;
                @include media(md) {
                    padding-left: 4px;
                }
                @include pux-scale-from("padding-left", lg, 4px);
								img	{
										height: 33px !important;
                    @include media(lg) {
                        height: 5.5rem !important;
                    }
								}
						}
				}
				&-right {
						overflow: hidden;
						position: fixed;
						top: 0;
						right: 0;
						background-color: $main-color;
						transition: transform 200ms ease-out;
						transform: translate3d(100%, 0,0);
						height: 100vh;
						width: 220px;
						padding: 85px 15px 25px 25px;
						overflow-x: visible;
						overflow-y: hidden;
            @include media(sm) {
                width: 440px;
                padding: 105px 30px 50px 50px;
            }
						@include media(lg) {
								transform: translate3d(0, 0,0);
                transition: none;
								height: auto;
								width: auto;
								padding: 0;
								overflow: visible;
						}
						&.open {
								transform: translate3d(0, 0,0);
						}
						@include media(lg) {
								position: static;
								background-color: transparent;
						}
						&-menu {
								max-height: calc(100vh - 130px);
								overflow-x: visible;
								overflow-y: auto;
								position: relative;
                user-select: none;
								@include media(lg) {
										max-height: none;
										padding: 0;
										overflow: visible;
                    width: rem(xxl, 680px);

								}
                #menuElem {
                    @include flex-block;
                    @include flex-flow(column);
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    overflow: hidden;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $base-white;
                    @include media(md) {
                        padding-bottom: 30px;
                    }
                    @include media(lg) {
                        @include flex-flow(row);
                        overflow: visible;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        height: rem(xxl, $menu-height);
                        border: 0;
                        padding: 0;
                    }
                    & > li {
                        height: 100%;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        & > span,
                        & > a {
                            @include transition;
                            font-size: 24px;
                            line-height: 40px;
                            font-weight: 900;
                            //font-family: 'Zirkel-Black';
                            color: $base-white;
                            flex: 0 1 100%;
                            max-width: 100%;
                            @include media(lg) {
                                font-size: 1.8rem;
                                height: 100%;
                                line-height: normal;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                overflow: hidden;
                            }
                            &:after {
                                display: block;
                                width: 100%;
                                height: 4px;
                                background-color: $main-color;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transform: translateY(100%);
                                @include transition;
                                @include media(lg) {
                                    content: "";
                                }
                            }
                            &:hover,
                            &:focus {
                                text-decoration: underline;
                                @include media(lg) {
                                    color: $main-color;
                                    text-decoration: none;
                                }
                            }
                        }
                        ul {
                            display: none;
                            list-style-type: none;
                            padding-left: 9px;
                            margin: 5px 0px;
                            @include media(md) {
                                padding-left: 18px;
                                margin: 7px 0;
                            }
                            @include media(lg) {
                                background-color: rgba($main-color, 0.93);
                                padding: 10px 25px;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                margin: 0;
                                opacity: 0;
                                transform: translate(0px, 100%);
                                color: $base-white;
                                display: inline-block;
                                visibility: hidden;
                                transition: all 200ms ease-in-out;
                            }
                            li {
                                padding: 0;
                                margin: 0;
                                a {
                                    color: $base-white;
                                    //font-family: 'Zirkel-Black';
                                    font-weight: 900;
                                    font-size: 18px;
                                    line-height: 30px;
                                    position: relative;
                                    display: inline-block;
                                    @include transition;
                                    @include media(lg) {
                                        padding-bottom: 3px;
                                        margin-bottom: 7px;
                                        font-size: 16px;
                                        line-height: 3rem;
                                        white-space: nowrap;
                                    }
                                    @include media(xxl) {
                                        font-size: 1.6rem;
                                    }
                                    &:after {
                                        @include media(lg) {
                                            content: "";
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 2px;
                                            background-color: $color-link;
                                            @include transition;
                                            transition-timing-function: ease-out;
                                            opacity: 0;
                                        }
                                    }
                                    &:hover,
                                    &:focus {
                                        @include media(xxs, md) {
                                            text-decoration: underline;
                                        }
                                        &:after {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover,
                        &:focus {
                            a {
                                &:after {
                                    transform: translateY(0%);
                                }
                            }
                            ul {
                                @include media(lg) {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                        &.Highlighted  {
                            a {
                                &:after {
                                    transform: translateY(0%);
                                }
                            }
                        }
                    }
                }
						}
				}
				&-right-mobile-menu {
						height: 100%;
						@include flex-block;
						@include align-items(center);
						@include media(lg) {
								display: none;
						}
						&-inner {
								position:relative;
								cursor: pointer;
								width: 60px;
								height: 55px;
								@include flex-block;
								@include align-items(center);
                justify-content: flex-end;
                @include media(md) {
                    margin: 7 14px;
                }
                @include media(lg) {
                    margin: 0;
                }
								span{
										width:21px;
										position:absolute;
										margin-top:-1px;
										background: $base-white;
										height:2px;
										margin-left:-11px;
										@include transition;
										&:before,
										&:after{
												content:"";
												width:21px;
												height:2px;
												position:absolute;
												display:block;
												background:$base-white;
												@include transition;
										}
										&:before{
												top:-8px;
										}
										&:after{
												bottom:-8px;
										}
								}
								@include media(lg){
										display:none !important;
								}
								&.open{
										span{
												background:transparent !important;
												&:before{
														@include rotate(45deg);
														top:0;
												}
												&:after{
														@include rotate(-45deg);
														top:0;
												}

										}
								}
						}
				}
		}
    &.small-type {
        @include media(xxs, md) {
            animation: small-header-mobile linear 250ms forwards;
        }
        @include media(lg) {
            animation: small-header linear 500ms forwards;
        }
        .header {
            @include media(lg) {
                animation: small-header-inner linear 500ms forwards;
            }
            .header-left-logo  img {
                @include media(lg) {
                    height: 4rem !important;
                    transition: all 0ms linear;
                    transition-delay: 0.25s;
                }
            }
            #menuElem {
                @include media(lg) {
                    animation: small-header-inner linear 500ms forwards;
                }
            }
        }
    }
    &.normal-type {
        @include media(xxs, md) {
            animation: small-header-mobile-reverse linear 250ms forwards;
        }
        @include media(lg) {
            opacity: 0;
            animation: show-header ease-in 350ms forwards;
        }
    }
}

@keyframes small-header{
    0% {
        opacity: 1;
        background-color: transparent;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        background-color: $base-black;
    }
    100% {
        opacity: 1;
        background-color: $base-black;
    }
}

@keyframes small-header-mobile {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: $base-black;
    }
}

@keyframes small-header-mobile-reverse {
    0% {
        background-color: $base-black;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes small-header-inner {
    0% {
    }
    50% {
        height: 6.4rem;
        border: 0;
    }
    100% {
        opacity: 100%;
        height: 6.4rem;
        border: 0;
    }
}

@keyframes show-header{
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  background-color: $base-black;
}

.footer-social {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    @include media(lg) {
        margin-top: 5rem;
    }
    &-container {
        width: 100%;
        @include media(sm) {
            max-width: 300px;
        }
        @include media(lg) {
            max-width: 35rem;
        }
    }
    &-title {

    }
    &-list {
        padding: 20px 0 30px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include media(lg) {
            padding: 3.6rem 0 5rem;
        }
        .ico-social {
            display:inline-block;
            //background:#fff;
            border-radius: 50%;
            margin:6px;
            height:27px;
            width:27px;
            text-decoration:none !important;
            position:relative;
            font-size: 0;
            @include pux-scale-from("margin", lg, 6px);
            @include pux-scale-from("width", lg, 27px);
            @include pux-scale-from("height", lg, 27px);
            &:before{
                color:#fff;
                @include transition;
                font-size: 27px;
                position:absolute;
                top:0;
                left:0;
                @include pux-scale-from("font-size", lg, 27px);
            }
            &:focus,
            &:hover{
                &:before{
                    color: $main-color;
                }
            }
            &:first-child{
                margin:6px 6px 6px 0;
                @include media(md){
                    margin:6px;
                }
                @include pux-scale-from("margin", lg, 6px);
            }
            @each $name, $icon-number in $share-icon-names {
                &.s-#{$name} {
                    &:before {
                        content: "#{$icon-number}";
                    }
                }
            }
        }
        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 1px;
            position: absolute;
            background-color: $base-white;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            @include media(lg) {
                width: 5rem;
            }
            @include pux-scale-from("height", lg, 1px);
        }
    }
}

.footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding: 10px 0 30px;
    @include media(lg) {
        padding: 2rem 0 5rem;
    }
    &:after {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        position: absolute;
        background-color: $base-white;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include media(lg) {
            width: 5rem;
        }
        @include pux-scale-from("height", lg, 1px);
    }
    &-item {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
        margin-top: 30px;
        @include media(xs) {
            flex: 0 1 50%;
            max-width: 50%;
        }
        @include media(lg) {
            flex: 0 1 20%;
            max-width: 20%;
            margin-top: 5rem;
        }
        &-title {
            font-weight: 900 !important;
            font-size: 20px;
            padding-bottom: 6px;
            display: inline-block;
            @include pux-scale-from("font-size", lg, 20px);
            @include pux-scale-from("padding-bottom", lg, 8px);
        }
        &-list {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &-link {

        }
        a {
            @include transition;
            font-size: 16px;
            line-height: 24px;
            @include pux-scale-from("font-size", lg, 16px);
            @include pux-scale-from("line-height", lg, 24px);
            &:hover,
            &:focus {
                //color: $main-color;
                text-decoration: underline;
            }
        }
    }
}

.footer-text {
    width: 100%;
    text-align: center;
    padding: 30px 0;
    @include media(md) {
        padding: 4.4rem 0;
    }
}

.is-sm{
    display:none;
    @include media(sm){
        display:block;
    }
}

.is-md{
    display:none;
    @include media(md){
        display:block;
    }
}

.is-lg{
    display:none;
    @include media(lg){
        display:block;
    }
}

.is-xl{
    display:none;
    @include media(xl){
        display:block;
    }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}

//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}

.menu-layer {
		&:before {
				content: "";
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				background-color: rgba($base-black, 0.7);
				width: 100%;
				height: 100vh;
				opacity: 0;
				animation: menu-layer 150ms linear forwards;
				animation-delay: 100ms;
				z-index: 99;
				@include media(lg) {
						display: none !important;
				}
		}
}

@keyframes menu-layer {
		0% {
				opacity: 0;
		}
		100% {
				opacity: 1;
		}
}

.space-10 {
		$space-base: 10px;

	  .row,
	  .items-container {
		    margin-left: -$space-base;
		    margin-right: -$space-base;
        @include pux-scale-from("margin-left", lg, -$space-base);
        @include pux-scale-from("margin-right", lg, -$space-base);

		    [class*="col-"] {
		      padding-left: $space-base;
		      padding-right: $space-base;
          @include pux-scale-from("padding-left", lg, -$space-base);
          @include pux-scale-from("padding-right", lg, -$space-base);
		    }
	  }
}

.fancybox-image-wrap {
    height: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    max-height: 100vh;
    @include pux-scale-from("border-width", lg, 10px);
    .fancybox-image {
        position: static !important;
    }
    .fancy-box-closer-caption {
        background-color: $base-black;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        padding: 12px 30px 7px;
        @include pux-scale-from("font-size", lg, 18px);
        @include media(lg) {
            padding: 1.2rem 3rem 0.7rem;
        }
    }
}
