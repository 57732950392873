@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.gallery-container {
  @include media(md) {
    margin: 0 -2rem;
  }
  //max-width: 1150px;
  //margin: auto
}

.gallery-photo {
  width: 100%;
  &-item {
    position: relative;
    width: 100%;
    display: block;
    img {
      width: 100%;
    }
  }
}

.gallery-preview {
  width: 100%;
  margin-top: 15px;
  @include slick-arrows;
  .slick-track {
    margin: 0 -7.5px;
  }
  .slick-disabled {
    @include opacity(0.5);
    background: transparent !important;
    &:before {
      color: #fff !important;
    }
  }
  &-item {
    position: relative;
    //width: 100%;
    max-width: 260px;
    height: 20rem;
    display: block;
    margin: 0 7.5px;
    @include media(xl) {
      max-width: 26rem;
    }
    &-bgr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  }
}

.fancybox-image-wrap {
  border: 10px solid #000 !important;
}

.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  height: 50px !important;
  width: 50px !important;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.25s;
  outline: 0 !important;
}

.fancybox-arrow::after {
  content: "";
  position: absolute;
  top: 28px;
  width: 44px;
  height: 44px;
  background-color: none !important;
  border: 4px solid #fff !important;
  @include border-radius(50%);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
  //background-image: none!important;
  background-repeat: no-repeat;
  //background-position: center center;
  background-size: 24px 24px;
}

.card-item {
  @include flex-block;
  overflow: hidden;
  width: 100%;
  //height: 34rem;
  //max-width: 34rem;
  padding-top: 100%;
  margin: auto;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: $milos-color;
    @include pux-scale-from("height", lg, 10px);
  }
  &-bgr {
    @include transition;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      @include opacity(0.5);
    }
  }
  &-content {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px 20px;
    @include media(md) {
      padding: 20px 25px 20px;
    }
    @include media(lg) {
      padding: 2rem 2rem 2.3rem;
    }
    &-title {
      @include pux-scale-with-min("font-size", 34px, 24px);
      @include pux-scale-with-min("line-height", 34px, 24px);
      font-weight: 900;
      color: #fff;
    }
    &-subtitle {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 5px;
      @include pux-scale-from("font-size", lg, 16px);
      @include pux-scale-from("line-height", lg, 20px);
      @include media(lg) {
        margin-top: 0.7rem;
      }
    }
  }
  &:hover,
  &:focus {
    .card-item-bgr {
      @include transform(scale(1.05));
    }
  }
  &.prolyte:after {
    background: $prolyte-color;
  }
  &.exe:after {
    background: $exe-color;
  }
  &.afi:after {
    background: $afi-color;
  }
  &.milos:after {
    background: $milos-color;
  }
  &.litec:after {
    background: $litec-color;
  }
  &.jte:after {
    background: $jte-color;
  }
  &.tomcat:after {
    background: $tomcat-color;
  }
  &.mobiltech:after {
    background: $mobil-color;
  }
  &.xstage:after {
    background: $xstage-color;
  }
  &.xtruss:after {
    background: $xtruss-color;
  }
  &.stagedex:after {
    background: $stagedex-color;
  }
  &.prolyft:after {
    background: $prolyft-color;
  }
  &.theme-red:after {
    background: $main-color;
  }
  &.middle-card,
  &.big-card {
    height: 46rem;
    padding-bottom: 25px;
    @include media(xl) {
      padding-bottom: 2.5rem;
    }
    .card-item-content {
      &-title {
        @include pux-scale-with-min("font-size", 45px, 36px);
        @include pux-scale-with-min("line-height", 45px, 36px);
      }
      &-subtitle {
        @include pux-scale-with-min("font-size", 21px, 17px);
        @include pux-scale-with-min("line-height", 22px, 20px);
      }
    }
  }
  &.big-card {
    .card-item-content {
      padding-bottom: 20px;
      @include media(lg) {
        padding-bottom: 2rem;
      }
    }
  }
}

.big-card {
  min-height: 46rem;
}

.big-card-wrapper {
  height: 100%;
  @include flex-block;
  @include flex-direction(column);
}

.big-card-content {
  padding: 20px;
  border: 1px solid #7f7f7f;
  background: #000;
  color: $base-white;
  flex: 1 1 auto;
  @include media(md) {
    padding: 25px;
  }
  @include media(lg) {
    padding: 2.5rem;
  }
  @include flex-block;
  @include flex-direction(column);
  @include justify-content(space-between);
  &-btns {
    padding-top: 5rem;
    .arrow-btn {
      padding-top: 10px;
      margin-right: 3rem;
      @include media(xl) {
        padding-top: 1rem;
      }
    }
  }
  .arrow-btn {
    &:after {
      opacity: 0 !important;
    }
  }
}

//.press-releases{
a.press-item {
  text-decoration: none !important;
  font-weight: normal !important;
  &:hover,
  &:active {
    font-weight: normal !important;
    .press-item-content {
      font-weight: 400 !important;
    }
  }
}
//}

.press-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  @include flex-block;
  @include flex-direction(column);
  flex: 0 1 auto;
  text-decoration: none !important;
  //font-family: 'Zirkel-Regular', sans-serif!important;
  &-image {
    width: 100%;
    padding-top: 73.3%;
    position: relative;
    margin-bottom: 30px;
    @include pux-scale-from("margin-bottom", lg, 30px);
    &-bgr {
      mix-blend-mode: overlay;
      -webkit-filter: grayscale(100%) contrast(100%);
      filter: grayscale(100%) contrast(100%);
      //@include opacity(0.5);
      transition: all ease-in-out 250ms;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 10px;
      @include pux-scale-from("height", lg, 10px);
      @include pux-scale-from("bottom", lg, -30px);
    }
  }
  &-content {
    background: #000;
    padding: 10px 20px 40px;
    border: 1px solid #7f7f7f;
    height: 100%;
    color: $base-white;
    @include flex-block;
    @include flex-direction(column);
    flex: 1 1 auto;
    @include media(lg) {
      padding: 1.6rem 2rem 4rem;
    }
    &-title {
      @include pux-scale-with-min("font-size", 22px, 17px);
      @include pux-scale-with-min("line-height", 24px, 18px);
      font-weight: 900;
      color: #fff;
      padding-bottom: 15px;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 2px solid #7f7f7f;
      @include media(xl) {
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;
      }
    }
    &-subtitle {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
      @include media(lg) {
        padding-bottom: 0.4rem;
      }
      @include pux-scale-from("font-size", lg, 16px);
      @include pux-scale-from("line-height", lg, 20px);
    }
  }
  &:hover {
    .press-item-image-bgr {
      mix-blend-mode: initial;
      -webkit-filter: none;
      filter: none;
    }
  }
  &.prolyte {
    .press-item-image {
      background: $prolyte-color;
      &:after {
        background: $prolyte-color;
      }
    }
  }
  &.exe {
    .press-item-image {
      background: $exe-color;
      &:after {
        background: $exe-color;
      }
    }
  }
  &.afi {
    .press-item-image {
      background: $afi-color;
      &:after {
        background: $afi-color;
      }
    }
  }
  &.milos {
    .press-item-image {
      background: $milos-color;
      &:after {
        background: $milos-color;
      }
    }
  }
  &.jte {
    .press-item-image {
      background: $jte-color;
      &:after {
        background: $jte-color;
      }
    }
  }
  &.tomcat {
    .press-item-image {
      background: $tomcat-color;
      &:after {
        background: $tomcat-color;
      }
    }
  }
  &.xstage {
    .press-item-image {
      background: $xstage-color;
      &:after {
        background: $xstage-color;
      }
    }
  }
  &.xtruss {
    .press-item-image {
      background: $xtruss-color;
      &:after {
        background: $xtruss-color;
      }
    }
  }
  &.litec {
    .press-item-image {
      background: #c5c5c5;
      &:after {
        background: $litec-color;
      }
    }
  }
  &.mobiltech {
    .press-item-image {
      background: $mobil-color;
      &:after {
        background: $mobil-color;
      }
    }
  }
  &.stagedex {
    .press-item-image {
      background: $stagedex-color;
      &:after {
        background: $stagedex-color;
      }
    }
  }
  &.prolyft {
    .press-item-image {
      background: $prolyft-color;
      &:after {
        background: $prolyft-color;
      }
    }
  }
  &.hp-press {
    .press-item-image {
      height: auto;
      margin-bottom: 10px;
      @include media(xxs, sm) {
        padding-top: 0;
      }
      @include media(md) {
        margin-bottom: 30px;
      }
      @include pux-scale-from("margin-bottom", lg, 30px);
      &:after {
        @include media(xxs, sm) {
          bottom: auto;
        }
      }
      .press-item-image-bgr {
        display: none;
        @include media(md) {
          width: 101%;
          display: block;
        }
      }
    }
    .press-item-content {
      background-color: transparent;
      @include pux-scale-from("font-size", lg, 14px);
      @include pux-scale-from("padding-bottom", lg, 20px);
      &-title {
        margin-top: 5px;
        font-size: 22px;
        line-height: 1.4;
        @include pux-scale-from("font-size", lg, 22px);
        @include pux-scale-from("margin-top", lg, 5px);
      }
      &-subtitle {
        display: none;
      }
    }
  }
}

.logo-item {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background: $base-white;
  padding: 20px 20px 20px;
  @include flex-block;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include media(xl) {
    padding: 2rem 2rem 2.5rem;
  }
  &-content {
    color: $base-black;
    &-title {
      padding-top: 10px;
      font-weight: 900;
      padding-bottom: 1.5rem;
      color: $base-black;
      @include media(xl) {
        padding-top: 1rem;
      }
    }
    a {
      color: $base-black;
      display: block;
      text-decoration: underline;
      &.arrow-btn {
        display: inline-flex;
      }
      &:after {
        border-color: $base-black;
      }
      &:hover {
        color: $base-black;
        &:after {
          background: $base-black;
          color: $base-white;
        }
      }
      &:active:after {
        color: $main-color;
      }
    }
  }
  img {
    width: 200px;
    @include media(xl) {
      width: 20rem;
    }
  }
  &.large-card {
    @include flex-direction(row);
    @include flex-wrap(wrap);
    .logo-item-image {
      width: 100%;
      margin-bottom: 20px;
      @include media(md) {
        width: 35%;
        margin-bottom: 0px;
        padding-right: 4rem;
      }
    }
    .logo-item-content {
      width: 100%;
      @include media(md) {
        width: 65%;
      }
      @include flex-block;
      @include flex-direction(column);
      @include justify-content(space-between);
      &-ref {
        margin-top: 3rem;
        @include media(md) {
          text-align: right;
        }
      }
    }
  }
  &.normal-card {
    .logo-item-image {
      margin-bottom: 20px;
    }
    .logo-item-content {
      &-ref {
        margin-top: 3rem;
      }
    }
  }
  &.exe {
    background: $exe-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.afi {
    background: $afi-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.prolyte {
    background: $prolyte-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.milos {
    background: $milos-color;
  }
  &.jte {
    background: $jte-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.tomcat,
  &.mobiltech {
    background: $mobil-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.tomcat {
    background: $tomcat-color;
  }
  &.xstage,
  &.xtruss {
    background: $xtruss-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.xstage {
    background: $xstage-color;
  }
  &.litec {
    background: $litec-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.stagedex {
    background: $stagedex-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
  &.prolyft {
    background: $prolyft-color;
    .logo-item-content {
      color: $base-white;
      &-title {
        color: $base-white;
      }
      a {
        color: $base-white;
        &:after {
          border-color: $base-white;
        }
        &:hover {
          &:after {
            background: $base-white;
            color: $base-black;
          }
        }
        &:active:after {
          color: $main-color;
        }
      }
    }
  }
}

.social-media-card {
  width: 100%;
  height: 100%;
  padding: 22px;
  @include flex-block;
  @include flex-direction(column);
  @include justify-content(space-between);
  background: $base-black;
  @include pux-scale-from("padding", lg, 22px);
  &-content {
    color: $base-white;
    font-weight: 900;
    h4 {
      padding-bottom: 3rem;
    }
  }
  &-join {
    padding-top: 2.5rem;
    text-align: right;
    .arrow-btn {
      font-size: 18px;
      @include pux-scale-from("font-size", lg, 18px);
    }
  }
  &.s-facebook {
    background: #4469b0;
  }
  &.s-linkedin {
    background: #1178b3;
  }
  &.s-twitter {
    background: #1da1f2;
  }
  &.s-instagram {
    background: #8a3ab9;
  }
  &.s-youtube {
    background: #fc0d1b;
  }
  &.s-a4i {
    background: $main-color;
  }
}

.row.row-contact {
  margin: 0 -10px;
  @include media(xl) {
    margin: 0 -1rem;
  }
  [class^="col-"] {
    padding: 10px;
    @include media(xl) {
      padding: 1rem;
    }
  }
  .arrow-btn:after {
    @include pux-scale-from("margin-left", lg, 12px);
  }
}

.contact-card {
  padding: 20px 20px 20px;
  border: 1px solid #979797;
  background: #000;
  height: 100%;
  min-height: 27rem;
  width: 100%;
  @include flex-block;
  @include flex-direction(column);
  @include justify-content(space-between);
  @include media(xl) {
    padding: 2rem 2.5rem 2.5rem;
  }
  &-content {
    color: $base-white;
    @include pux-scale-with-min("font-size", 14px, 14px);
    @include pux-scale-with-min("line-height", 18px, 18px);
    &-row {
      padding: 2.5rem 0;
      @include flex-block;
      @include flex-wrap(wrap);
      margin: 0 -10px;
      &-column {
        width: 100%;
        padding: 0 10px;
        @include media(lg) {
          width: 50%;
        }
      }
    }
  }
  &-refs {
    text-align: right;
    .arrow-btn {
      padding-top: 10px;
      margin-left: 3rem;
    }
  }
  &-row {
    @include flex-block;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-items(flex-end);
    @include media(xl) {
      @include flex-wrap(nowrap);
    }
    .contact-card-brands {
      color: $base-white;
      margin: 0 -10px;
      max-width: 320px;
      @include media(xl) {
        max-width: 34rem;
      }
      &-title {
        padding-left: 10px;
      }
      .brand-logo {
        display: inline-block;
        padding: 10px;
        @include pux-scale-from("padding", lg, 10px);
        padding-top: 0 !important;
        //padding-left: 0 !important;
        &:before {
          font-size: 15px;
          @include pux-scale-from("font-size", xl, 13px);
        }
        &.ico-logo2 {
          &:before {
            font-size: 27px;
            @include media(xl) {
              font-size: 2.5rem;
            }
          }
        }
        &.ico-prolyte {
          &:before {
            font-size: 42px;
            transform: translateY(22%);
            display: inline-block;
            @include media(xl) {
              font-size: 3.8rem;
              transform: translateY(25%);
            }
          }
        }
        &.ico-xstage,
        &.ico-xtruss {
          &:before {
            font-size: 21px;
            @include media(xl) {
              font-size: 1.9rem;
            }
          }
        }
        &.ico-mobiltechlifts,
        &.ico-exe-technology {
          &:before {
            font-size: 23px;
            @include media(xl) {
              font-size: 2.1rem;
            }
          }
        }
      }
    }
    .arrow-btn {
      padding-top: 10px;
    }
  }
  &.wide-card {
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include align-items(center);
    .contact-card-content {
      width: 100%;
      @include media(xl) {
        width: 50%;
      }
    }
    .contact-card-row {
      width: 100%;
      @include media(xl) {
        width: 50%;
      }
    }
  }
}

.breadcrumbs {
  &-separator {
    padding: 0 0.5rem;
    display: inline-block;
    &:before {
      content: ">";
      @include pux-scale-with-min("font-size", 18px, 16px);
      @include pux-scale-with-min("line-height", 22px, 20px);
    }
  }
  a {
    color: $base-white;
    @include pux-scale-with-min("font-size", 18px, 16px);
    @include pux-scale-with-min("line-height", 22px, 20px);
    &:hover,
    &:focus {
      color: $main-color;
    }
  }
  span {
    color: $base-white;
    @include pux-scale-with-min("font-size", 18px, 16px);
    @include pux-scale-with-min("line-height", 22px, 20px);
  }
}

.product-container {
  padding: 6rem 0 0;
  position: relative;
  z-index: 2;
  @include media(md) {
    margin: 0 -20px;
  }
}

.product-item {
  padding: 5rem 0;
  @include media(xl) {
    padding: 0;
  }
  &-row {
    @include flex-block;
    @include flex-wrap(wrap);
    @include justify-content(center);
    flex: 1 1 auto;
    .product-item-column {
      width: 100%;
      height: 23rem;
      border-collapse: collapse;
      border: 1px solid #979797;
      margin: -0.5px;

      @include flex-block;
      @include media(sm) {
        width: 50%;
      }
      @include media(md) {
        width: 33.3333%;
      }
      @include media(xl) {
        width: 15%;
      }
      &:first-child,
      &:nth-child(2) {
        @include media(xl) {
          width: 20%;
        }
      }
      &:first-child {
        padding: 12px 20px;
      }
      &:nth-child(2) {
        @include align-items(center);
        @include justify-content(center);
      }
      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
      .product-ref {
        @include flex-block;
        @include flex-direction(column);
        @include justify-content(space-between);
        height: 100%;
        width: 100%;
        padding: 12px 12px 12px 20px;
        font-weight: 900;
        text-decoration: none !important;
        @include pux-scale-with-min("font-size", 34px, 24px);
        @include pux-scale-with-min("line-height", 38px, 28px);
        @include pux-scale-from("padding", lg, 12px);
        @include pux-scale-from("padding-left", lg, 20px);
        span {
          display: block;
          @include align-self(flex-end);
          &:after {
            content: "\e908";
            width: 28px;
            height: 28px;
            font-size: 22px;
            margin-left: 15px;
            @include border-radius(50%);
            border: 2px solid #fff;
            @include flex-block();
            @include align-items(center);
            @include justify-content(center);
            font-weight: 900;
            @include pux-scale-from("width", lg, 28px);
            @include pux-scale-from("height", lg, 28px);
            text-decoration: none !important;
            @include media(xxl) {
              font-size: 2.2rem;
            }
          }
        }
        &:hover {
          span:after {
            color: $base-black;
            background: $base-white;
          }
        }
        &:active {
          span:after {
            color: $main-color;
          }
        }
        &.milos {
          background: $milos-color;
          color: $base-black;
          span:after {
            border-color: $base-black;
          }
          &:hover {
            span:after {
              color: $base-white;
              background: $base-black;
            }
          }
          &:active {
            span:after {
              color: $main-color;
            }
          }
        }
        &.tomcat {
          background: $tomcat-color;
        }
        &.litec {
          background: $litec-color;
          color: $base-black;
          span:after {
            border-color: $base-black;
          }
          &:hover {
            span:after {
              color: $base-white;
              background: $base-black;
            }
          }
          &:active {
            span:after {
              color: $main-color;
            }
          }
        }
        &.jte {
          background: $jte-color;
          @include pux-scale-with-min("font-size", 24px, 18px);
          @include pux-scale-with-min("line-height", 28px, 22px);
        }
      }
      &.empty {
        display: none;
        @include media(sm) {
          display: block;
        }
      }
    }
  }
}

.brand-item {
  @include flex-block;
  @include flex-wrap(wrap);
  @include justify-content(center);
  max-width: 900px;
  flex: 1 1 auto;
  padding: 5rem 0;
  @include media(sm) {
    padding: 0;
  }
  .brand-item-name,
  .brand-item-products {
    width: 100%;
    //height: 23rem;
    border-collapse: collapse;
    border: 1px solid #979797;
    margin: -0.5px;
    @include flex-block;
    @include media(sm) {
      width: 66.6666%;
    }
    &:first-child {
      @include media(sm) {
        width: 33.3333%;
      }
    }
  }
  .brand-item-name {
    .milos-bgr {
      width: 100%;
      background: $milos-color;
      padding: 12px 20px;
      h3 {
        color: $base-black;
      }
    }
    .tomcat-bgr {
      width: 100%;
      background: $tomcat-color;
      padding: 12px 20px;
    }
    .litec-bgr {
      width: 100%;
      background: $litec-color;
      padding: 12px 20px;
      h3 {
        color: $base-black;
      }
    }
    .jte-bgr {
      width: 100%;
      background: $jte-color;
      padding: 12px 20px;
    }
  }
  .brand-item-products {
    display: block;
    border: none;
    margin: 0;
    .brand-item-product {
      @include flex-block();
      margin: -0.5px 0;
      //@include align-items(center);
      //@include justify-content(center);
      @include flex-wrap(wrap);
      &-image {
        width: 100%;
        height: 23rem;
        border: 1px solid #979797;
        margin: -0.5px;
        overflow: hidden;
        @include media(sm) {
          width: 50%;
        }
        @include flex-block();
        @include align-items(center);
        @include justify-content(center);
        img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
        }
      }
      &-name {
        width: 100%;
        height: 23rem;
        border: 1px solid #979797;
        margin: -0.5px;
        @include media(sm) {
          width: 50%;
        }
        a {
          width: 100%;
          height: 100%;
          padding: 12px 20px;
          @include flex-block;
          @include flex-direction(column);
          @include justify-content(space-between);
          span {
            display: block;
            @include align-self(flex-end);
            &:after {
              content: "\e908";
              width: 28px;
              height: 28px;
              font-size: 22px;
              margin-left: 15px;
              @include border-radius(50%);
              border: 2px solid #fff;
              @include flex-block();
              @include align-items(center);
              @include justify-content(center);
              font-weight: 900;
              @include pux-scale-from("width", lg, 28px);
              @include pux-scale-from("height", lg, 28px);
              text-decoration: none !important;
              @include media(xxl) {
                font-size: 2.2rem;
              }
            }
          }
          &:hover {
            span:after {
              color: $base-black;
              background: $base-white;
            }
          }
          &:active {
            text-decoration: none;
            span:after {
              color: $main-color;
            }
          }
        }
      }
    }
  }
}

.products-wrapper,
.brands-wrapper {
  display: none;
  &.active {
    display: block;
  }
}

.media-space {
  .row,
  .items-container {
    @include pux-scale-from("margin-right", lg, -13px);
    @include pux-scale-from("margin-left", lg, -13px);
    [class*="col-"] {
      @include pux-scale-from("padding", lg, 13px);
    }
  }
}

.filter-row {
  padding-top: 6rem;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 18px;
  @include pux-scale-from("font-size", lg, 18px);
  .product-switch {
    display: inline-block;
    padding: 0 1rem;
    cursor: pointer;
    font-weight: 900;
    text-decoration: underline;
    &:hover,
    &.active {
      color: $main-color;
    }
  }
}

.text-bold {
  font-weight: 700;
}

.text-narrow {
  max-width: 720px;
  position: relative;
  z-index: 2;
  @include media(xxl) {
    max-width: 72rem;
  }
}

.person-item {
  width: 100%;
  &-photo {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    &-bgr {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
    }
  }
  &-name {
    padding-top: 1.5rem;
  }
  &-position {
    //text-transform: uppercase;
  }
}

.about-us-container {
  @include flex-block;
  @include justify-content(space-between);
  padding-bottom: 5.5rem;
}

.about-us-right {
  display: none;
  position: relative;
  z-index: 2;
  @include media(lg) {
    display: block;
    width: 30rem;
    margin-left: 2rem;
  }
  .newsletter-box {
    @include pux-scale-from("margin-bottom", lg, 30px);
  }
  .newsletter-row {
    display: block;
    &-btn,
    &-input {
      width: 100%;
      max-width: none;
      input,
      .btn {
        width: 100%;
        text-align: center;
      }
    }
    &-btn {
      padding: 1.2rem 0.5rem;
    }
  }

  .press-item {
    margin-top: 10px;
    margin-bottom: 30px;
    height: auto;
    overflow: initial;
    position: relative;
    @include pux-scale-from("margin-top", lg, 10px);
    @include pux-scale-from("margin-bottom", lg, 30px);
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      @include pux-scale-from("height", lg, 10px);
      @include pux-scale-from("top", lg, -10px);
    }
    &.exe {
      &:before {
        background: $exe-color;
      }
    }
    &.afi {
      &:before {
        background: $afi-color;
      }
    }
    &.prolyte {
      &:before {
        background: $prolyte-color;
      }
    }
    &.milos {
      &:before {
        background: $milos-color;
      }
    }
    &.jte {
      &:before {
        background: $jte-color;
      }
    }
    &.tomcat {
      &:before {
        background: $tomcat-color;
      }
    }
    &.mobiltech {
      &:before {
        background: $mobil-color;
      }
    }
    &.stagedex {
      &:before {
        background: $stagedex-color;
      }
    }
    &.prolyft {
      &:before {
        background: $prolyft-color;
      }
    }
    &:hover {
      .press-item-content-title {
        text-decoration: underline;
      }
    }
  }
}

.big-photo-container {
  position: relative;
  z-index: 2;
  width: 100%;
  img {
    width: 100%;
    margin-bottom: 7rem;
  }
}

[data-aos="fade"] {
  @include media(xxs, md) {
    opacity: 1 !important;
  }
  @include media(lg) {
    opacity: 0;
    transition-property: opacity;
  }
}

[data-aos="fade"].aos-animate {
  @include media(lg) {
    opacity: 1;
  }
}

.big-photo-wrapper {
  //width: 100%;
  @include media(md) {
    margin: 0 -2rem;
  }
}

.top-content {
  @include flex-block;
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  position: relative;
  max-width: 100%;
  z-index: 2;
  &-right {
    width: 100%;
    @include media(md) {
      width: auto;
    }
    .active {
      display: none;
    }
  }
  &-left {
    padding-bottom: 6rem;
    .event-text {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

.items-container {
  min-height: 62rem;
}

.event-filter {
  font-weight: 500;
  &:hover,
  &:active {
    color: $main-color;
  }
}

.brand-filter-row {
  @include flex-block;
  @include align-items(center);
  @include flex-wrap(wrap);
  padding-bottom: 6rem;
  span {
    font-weight: 500;
    @include pux-scale-with-min("font-size", 18px, 16px);
    padding-right: 10px;
    @include media(xxl) {
      padding-right: 1rem;
    }
  }
  .brand-category-filter {
    display: block;
    padding: 9px;
    @include media(xxl) {
      padding: 1rem;
    }
    &:before {
      @include pux-scale-with-min("font-size", 25px, 18px);
      color: $base-white;
      @include opacity(0.5);
    }
    &.Milos {
      &:before {
        content: "\e91b";
        @include pux-scale-with-min("font-size", 15px, 15px);
      }
    }
    &.Litec {
      &:before {
        content: "\e91c";
      }
    }
    &.Tomcat {
      &:before {
        content: "\e901";
      }
    }
    &.JTE {
      &:before {
        content: "\e923";
      }
    }
    &.Prolyte {
      &:before {
        content: "\e90b";
        @include pux-scale-with-min("font-size", 22px, 16px);
      }
    }
    &.EXE {
      &:before {
        content: "\e924";
      }
    }
    &.Xstage {
      &:before {
        content: "\e927";
      }
    }
    &.Xtruss {
      &:before {
        content: "\e926";
      }
    }
    &.Mobiltechlifts {
      &:before {
        content: "\e925";
        @include pux-scale-with-min("font-size", 40px, 26px);
      }
    }
    &:hover {
      &:before {
        @include opacity(1);
      }
    }
    &:active,
    &.active {
      text-decoration: none;
      &:before {
        @include opacity(1);
        color: $main-color;
      }
    }
  }
}

.detail-image {
  margin-bottom: 10px;
  @include pux-scale-from("margin-bottom", lg, 10px);
}

.map {
  &-filter {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
    @include media(lg) {
      margin-top: 1rem;
    }
    @include pux-scale-from("font-size", lg, 18px);
    &-title {
      flex: 0 0 auto;
      padding-right: 5px;
    }
    &-list {
      position: relative;
      overflow: hidden;
      &-inner {
        display: flex;
        //flex-wrap: wrap;
        justify-content: flex-start;
        overflow: auto;
      }
      .slick-prev {
        display: none !important;
      }
    }
    &-item {
      cursor: pointer;
      margin-left: 20px;
      @include transition;
      user-select: none;
      outline: 0;
      white-space: nowrap;
      flex: 0 0 auto;
      @include media(lg) {
        margin-left: 2rem;
      }
      &:hover,
      &.active {
        color: $main-color;
        font-weight: 700;
      }
      &:last-of-type {
        padding-right: 150px;
      }
    }
  }
  &-preview {
    width: 100%;
    padding-top: 59%;
    border: 1px solid #979797;
    @include pux-scale-from("border-width", lg, 1px);
    position: relative;
    margin-top: 20px;
    background-color: $base-black;
    @include media(lg) {
      margin-top: 2.5rem;
    }
    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0;
      @include transition;
      &.active {
        opacity: 1;
        transition: opacity 400ms linear;
        z-index: 9;
      }
    }
  }
}

.IE11 {
  .career-card-title {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  #menuElem {
    & > li {
      @include media(lg) {
        flex: 1 1 auto;
      }
    }
  }
}

.IE11,
.edge {
  .watch-video {
    height: auto !important;
  }
}

.headquarters {
  .filter-item {
    padding-top: 20px;
    @include pux-scale-from("padding-top", lg, 20px);
    padding-bottom: 0 !important;
    .contact-head-card {
      min-height: 0;
      @include media(md) {
        padding-bottom: 5rem;
      }
      &-row {
        display: flex;
        flex-wrap: wrap;
      }
      &-item {
        padding-top: 25px;
        @include pux-scale-from("padding-top", lg, 25px);
        flex: 0 1 100%;
        max-width: 100%;
        padding-right: 1rem;
        @include media(sm) {
          flex: 0 1 50%;
          max-width: 50%;
        }
        @include media(lg) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @include pux-scale-with-min("font-size", 16px, 16px);
        @include pux-scale-with-min("line-height", 20px, 20px);
        &.last-item {
          @include media(sm) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: 0;
          }
          @include media(lg) {
            flex: 0 1 20%;
            max-width: 20%;
          }
        }
        &.brands-item {
          @include media(lg) {
            flex: 1 0 30%;
            max-width: 30%;
          }
          .brand-logo {
            display: inline-block;
            padding: 20px;
            padding-bottom: 10px;
            @include pux-scale-from("padding", lg, 20px);
            @include pux-scale-from("padding-bottom", lg, 10px);
            padding-top: 0 !important;
            padding-left: 0 !important;
            &:before {
              font-size: 15px;
              @include pux-scale-from("font-size", xl, 13px);
            }
            &.ico-logo2 {
              &:before {
                font-size: 27px;
                @include media(xl) {
                  font-size: 2.5rem;
                }
              }
            }
            &.ico-prolyte {
              &:before {
                font-size: 42px;
                transform: translateY(22%);
                display: inline-block;
                @include media(xl) {
                  font-size: 3.8rem;
                  transform: translateY(25%);
                }
              }
            }
            &.ico-xstage,
            &.ico-xtruss {
              &:before {
                font-size: 21px;
                @include media(xl) {
                  font-size: 1.9rem;
                }
              }
            }
            &.ico-mobiltechlifts,
            &.ico-exe-technology {
              &:before {
                font-size: 23px;
                @include media(xl) {
                  font-size: 2.1rem;
                }
              }
            }
            &.ico-prolyft-logo,
            &.ico-stagedex-logo { 
              &:before {
                font-size: 42px;
                transform: translateY(22%);
                display: inline-block;
                @include media(xl) {
                  font-size: 5rem;
                  transform: translateY(25%);
                }
              }
            }
          }
        }
      }
    }
  }
}

.small-title-padding {
  padding-bottom: 10px;
  @include pux-scale-from("padding-bottom", lg, 20px);
}

.afi-gallery {
  &-item {
    padding-left: 5px;
    padding-bottom: 5px;
    flex: 0 1 100%;
    max-width: 100%;
    @include media(xs) {
      flex: 0 1 50%;
      max-width: 50%;
    }
    @include media(lg) {
      flex: 0 1 33.333%;
      max-width: 33.333%;
    }
    &-inner {
      position: relative;
      overflow: hidden;
      display: block;
      &:before {
        content: "";
        display: block;
        padding-top: 80%;
      }
      img {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  &.large-space {
    .afi-gallery-item {
      padding-bottom: 40px;
    }
  }
}

.fancybox-show-nav .fancybox-arrow {
  opacity: 1;
}

.modal {
  .modal-dialog {
    border: 0;
    max-width: 90vw;
    @include pux-scale-from("max-width", lg, 900px);
    .modal-content {
      border: 0;
      padding: 3rem;
      background-color: $base-white;
      width: 100%;
      border-radius: 0;
    }
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 18px;
    background-color: $main-color;
    font-size: 0;
    border: 0;
    z-index: 9;
    cursor: pointer;
    opacity: 1 !important;
    @include flex-block;
    @include justify-content(center);
    @include align-items(center);
    @include pux-scale-from("padding", lg, 18px);
    &:before {
      content: "\e921";
      display: block;
      position: relative;
      color: $base-white;
      font-size: 14px;
      @include pux-scale-from("font-size", lg, 14px);
      font-weight: 300;
      text-shadow: none;
      @include pux-scale-from("left", lg, 1px);
      @include transition;
    }
    &:hover,
    &:focus {
      outline: 0;
      border: 0;
      &:before {
        transform: rotate(90deg);
      }
    }
  }
}

.new-newsletter-iframe {
  width: 100%;
  border: none;
  overflow: visible;
  height: 660px;
}

.prize-banner {
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 5rem;
  @include media(md) {
    padding-top: 5rem;
  }
  &-subtitle {
    color: #e3e57a;
    font-family: Zirkel;
    font-weight: 700;
    font-size: 20px;
    @include media(lg) {
      font-size: 25px;
    }
  }
  &-text {
    display: none;
    @include media(sm) {
      display: flex;
    }
    &-left {
      font-size: 25.5rem;
      letter-spacing: normal;
      line-height: 0.82;
      font-weight: 900;
      background-image: url("/AFI/media/system/img/Ebene_13.png");
      background-attachment: fixed;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-position: 0% 0%;
      background-size: 80% 80%;
      background-repeat: no-repeat;
      @include media(md) {
        background-position: 10% 24%;
        background-size: 75% 75%;
      }
      @include media(xl) {
        background-position: 18% 75%;
        background-size: 63% 64%;
      }
    }
    &-right {
    }
    &.line-1 {
      margin-top: 2.2rem;
      .prize-banner-text-right {
      }
    }
    &.line-2 {
      .prize-banner-text-right {
      }
    }
    &-btn {
      text-align: center;
      padding-top: 6rem;
      .btn-prize {
        text-transform: uppercase;
        padding: 1rem 6.7rem;
        height: auto;
      }
    }
  }
  &-mobile {
    @include media(sm) {
      display: none;
    }
    &-title {
      background-image: url("/AFI/media/system/img/Ebene_13.png");
      background-attachment: fixed;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 15px;
      h1 {
        font-size: 20rem;
        letter-spacing: normal;
        line-height: 0.82;
        font-weight: 900;
      }
    }
    &-subtitle {
    }
  }
  .text-1 {
    color: #000000;
    font-size: 4.8rem;
    font-weight: 400;
    font-style: italic;
    letter-spacing: normal;
    line-height: 1.08;
    text-transform: uppercase;
    padding-top: 15px;
    @include media(sm) {
      padding-top: 1rem;
      padding-left: 10px;
    }
  }
  .text-2 {
    color: #e61c4a;
    font-family: Zirkel;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.08;
    padding-top: 15px;
    @include media(sm) {
      font-size: 2.4rem;
      padding-top: 1rem;
      padding-left: 15px;
    }
  }
}

.prize-font {
  font-size: 2.8rem;
  line-height: 1.35;
  font-weight: 300;
  padding: 0;
  margin: 0;
  a {
    color: $base-black;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.prize-text-image {
  display: flex;
  flex-wrap: wrap;
  padding: 5.5rem 0;
  border-bottom: 1px solid #2d2d2d;
  text-align: center;
  @include media(md) {
    flex-wrap: nowrap;
    text-align: left;
  }
  &-left {
    margin-top: 7rem;
    flex-grow: 1;
    @include media(md) {
      padding-right: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .btn-prize {
      margin-top: 30px;
      @include media(md) {
        align-self: flex-end;
      }
    }
  }
  &-right {
    margin-top: 7rem;
    flex: 0 1 100%;
    max-width: 100%;
    @include media(md) {
      flex: 0 0 35rem;
      max-width: 35rem;
    }
  }
}

.prize-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  border-bottom: 1px solid #2d2d2d;
  &-title {
    flex: 0 1 100%;
    max-width: 100%;
    @include media(md) {
      max-width: 70%;
    }
  }
  &-item {
    flex: 0 1 100%;
    max-width: 100%;
    padding-top: 5rem;
    @include media(sm) {
      flex: 0 1 50%;
      max-width: 50%;
      padding-right: 3rem;
    }
    @include media(lg) {
      flex: 0 1 33.333%;
      max-width: 33.333%;
    }
    h4 {
      @include media(md) {
        font-size: 2.8rem;
      }
    }
  }
  &.columns-3 {
    .prize-columns-item {
      &:nth-child(2) {
        h4 {
          color: #ff1727;
        }
      }
      &:nth-child(3) {
        h4 {
          color: #ee82b3;
        }
      }
      &:nth-child(4) {
        h4 {
          color: #60c6ed;
        }
      }
    }
  }
  &.columns-2 {
    .prize-columns-item {
      &:nth-child(2) {
        h4 {
          color: #6f539e;
        }
        @include media(sm) {
          flex: 0 1 40%;
          max-width: 40%;
        }
      }
      &:nth-child(3) {
        h4 {
          color: #f7bdc1;
        }
        @include media(sm) {
          flex: 0 1 60%;
          max-width: 60%;
        }
      }
    }
  }
}

.prize-comitte,
.prize-registration {
  padding: 5rem 0;
  border-bottom: 1px solid #9f9f9f;
  &-title {
    h2 {
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.prize-registration {
  border: 0;
  .ErrorLabel {
    font-weight: 900;
    margin-top: 2rem;
    font-size: 18px;
    display: block;
    @include media(lg) {
      font-size: 2rem;
    }
  }
  .InfoLabel {
    font-weight: 900;
    margin-top: 3rem;
    font-size: 20px;
    display: block;
    @include media(lg) {
      font-size: 2.6rem;
    }
  }
}

.comitte-item {
  flex: 0 1 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 3rem 3rem 0;
  @include media(sm) {
    flex: 0 1 50%;
    max-width: 50%;
  }
  @include media(md) {
    flex: 0 1 33.333%;
    max-width: 33.333%;
  }
  @include media(lg) {
    flex: 0 1 25%;
    max-width: 25%;
  }
  &-photo {
    padding: 3rem 2rem 0;
    &-inner {
      position: relative;
      overflow: hidden;
      border: 1px solid #9f9f9f;
      border-radius: 50%;
      @include media(xxs, xxs) {
        max-width: 280px;
        margin: 0 auto;
      }
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }
    }
  }
  &-name {
    h4 {
      font-size: 17px;
      font-weight: 700;
      padding-top: 2rem;
      line-height: 1.1;
      @include media(md) {
        font-size: 2rem;
      }
    }
  }
  &-position {
    font-size: 15px;
    line-height: 1.2;
    @include media(xl) {
      font-size: 1.6rem;
    }
  }
}

.prize-bottom {
  padding: 5.5rem 0;
  border-top: 1px solid #2d2d2d;
  text-align: center;
  @include media(sm) {
    text-align: left;
  }
  h2 {
    margin-bottom: 2rem;
  }
  h2:not(:first-child) {
    margin-top: 5rem;
  }
}

.prize-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-left {
    flex: 0 1 100%;
    max-width: 100%;
    margin-top: 30px;
    @include media(md) {
      flex: 0 1 48%;
      max-width: 48%;
      margin-top: 4rem;
    }
    @include media(xl) {
      flex: 0 1 39%;
      max-width: 39%;
    }
    &.last-type {
      @include media(xxs, sm) {
        order: 6;
      }
    }
  }
  &-right {
    flex: 0 1 100%;
    max-width: 100%;
    margin-top: 30px;
    @include media(md) {
      flex: 0 1 48%;
      max-width: 48%;
      margin-top: 4rem;
    }
    @include media(xl) {
      flex: 0 1 52%;
      max-width: 52%;
    }
  }
  &-item {
    &:not(.type-area) {
      border-bottom: 1px solid #2d2d2d;
    }
    textarea,
    input,
    .selectric .label {
      color: $base-black !important;
      padding: 0;
      font-size: 18px;
      line-height: 24px;
      padding: 15px 0 5px;
      height: auto;
      border: 0 !important;
      @include media(md) {
        font-size: 2.8rem;
        line-height: 3.4rem;
        padding: 1.6rem 0 0.6rem;
      }
      &::placeholder {
        color: $base-black !important;
        font-weight: 300;
        line-height: 1.5;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $base-black !important;
        font-weight: 300;
        line-height: 1.5;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $base-black !important;
        font-weight: 300;
        line-height: 1.5;
      }
    }
    textarea {
      border-bottom: 0;
      background-image: linear-gradient(#000 1px, transparent 1px) !important;
      background-size: 100% 44px !important;
      background-position: 0 43px !important;
      line-height: 44px;
      height: calc(44px * 6);
      resize: none;
      padding: 2px 0 0;
      @include media(md) {
        background-image: linear-gradient(#000 0.1rem, transparent 0.1rem) !important;
        background-size: 100% 5.9rem !important;
        background-position: 0 5.7rem !important;
        line-height: 5.8rem;
        height: calc(5.8rem * 6);
        padding: 0.6rem 0 0;
      }
      @include media(xxl) {
        background-size: 100% 5.8rem !important;
      }
    }
    .selectric {
      .label {
        color: $base-black;
        font-weight: 300;
      }
    }
    .selectric-items {
      .selectric-scroll {
        background-color: $base-black !important;
      }
    }
  }
  &-files {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
  }
  &-file {
    display: none;
    flex: 0 1 100%;
    max-width: 100%;
    &:first-of-type {
      display: block;
    }
    input[type="file"] {
      display: none;
    }
    label {
      background-color: #ffa200;
      width: 100%;
      padding: 0 15px;
      &:hover,
      &:focus {
        background-color: darken(#ffa200, 5);
      }
    }
    .Error {
      &:after {
        display: none !important;
      }
    }
    &.filled {
      order: -1;
    }
  }
  &-submit {
    margin-top: 10px;
    margin-top: Max(1rem, 10px);
    input[type="submit"] {
      width: 100%;
      background-color: #9a18e0;
      &:hover,
      &:focus {
        background-color: darken(#9a18e0, 5);
      }
    }
  }
  &-text {
    font-size: 17px;
    margin-top: 1.8rem;
    @include media(xl) {
      font-size: 1.8rem;
    }
  }
}

.Gecko {
  .prize-banner-text-left {
    @include media(xl) {
      background-position: 18% 85%;
      background-size: 63% 85%;
    }
  }
}

.headquarters .filter-item .contact-head-card-item.brands-item .brand-logo{
    &.ico-logo:before,
    &.ico-logo:before,
    &.ico-tomcat-logo:before,
    &.ico-header-logo:before{
      font-size: 20px !important;
    }
    &.ico-exe-technology:before,
    &.ico-xstage:before,
    &.ico-xtruss:before{
      font-size: 30px !important;
    }
    &.ico-prolyte:before,
    &.ico-prolyft-logo:before,
    &.ico-stagedex-logo:before{
      font-size: 35px !important;
    }
}