
@import "css-rules.scss";
@import "../../_variables.scss";


@function strip-unit($number) {
  @return $number / ($number * 0 + 1);
}

@function calc-responzive-rem($font-size, $scale-ratio) {
		$font-size-px: strip-unit($font-size)/$scale-ratio;
		$font-size-rem: $font-size-px/10#{rem};
		@return $font-size-rem;
}

@function str-split($string, $separator) {

    $split-arr: ();

    $index : str-index($string, $separator);

    @while $index != null {

        $item: str-slice($string, 1, $index - 1);

        $split-arr: append($split-arr, $item);

        $string: str-slice($string, $index + 1);

        $index : str-index($string, $separator);
    }

    $split-arr: append($split-arr, $string);

    @return $split-arr;
}

@function get-scale-ratio($breakpoint) {
		$scale: $scale-ratio-xxs;

			@if $breakpoint == xxs {
				$scale: $scale-ratio-xxs;
		} @else if $breakpoint == xs{
				$scale: $scale-ratio-xs;
		} @else if $breakpoint == sm {
				$scale: $scale-ratio-sm;
		} @else if $breakpoint == md {
				$scale: $scale-ratio-md;
		} @else if $breakpoint == lg {
				$scale: $scale-ratio-lg;
		} @else if $breakpoint == xl {
				$scale: $scale-ratio-xl;
		} @else if  $breakpoint == xxl {
				$scale: 1;
		} @else {
				$scale: $scale-ratio-xxs;
		}

		@return $scale;
}


@function rem($scale-ratio,$size-values...) {
	$i:0;
	$temp: 0;
  $space: " ";

		$scale: get-scale-ratio($scale-ratio);

    @each $current-value in $size-values {

        @if(type-of($current-value) == number) {
            $temp: calc-responzive-rem($current-value, $scale);
        }
      	@else {
            $temp: $current-value;
        }
            $i: $i#{$space}$temp;
		}

  @return str-slice($i, 3);
}


@function px-in-rem($scale-ratio,$value) {
		$scale: get-scale-ratio($scale-ratio);
		@return $value*$scale;
}

@mixin pux-static-font($font-size) {
			font-size: rem(xxs, $font-size);
		@include media(xs){
			font-size: rem(xs, $font-size);
		}
		@include media(sm){
			font-size: rem(sm, $font-size);
		}
		@include media(md){
			font-size: rem(md, $font-size);
		}
		@include media(lg){
			font-size: rem(lg, $font-size);
		}
		@include media(xl){
			font-size: rem(xl, $font-size);
		}
		@include media(xxl){
			font-size: rem(xxl, $font-size);
		}
}

@mixin pux-static-size($key, $font-size...) {
				#{$key}: rem(xxs, $font-size...);
		@include media(xs){
				#{$key}: rem(xs, $font-size...);
		}
		@include media(sm){
				#{$key}: rem(sm, $font-size...);
		}
		@include media(md){
				#{$key}: rem(md, $font-size...);
		}
		@include media(lg){
	    	#{$key}: rem(lg, $font-size...);
	  }
		@include media(xl){
				#{$key}: rem(xl, $font-size...);
		}
	  @include media(xxl){
	    	#{$key}: rem(xxl, $font-size...);
	  }
}

@mixin pux-scale-with-min($property, $value-init, $min-value-init, $important: 0) {
		$breakpoints: ("xxs","xs","sm","md","lg","xl","xxl");
		$min-value: strip-unit($min-value-init);
		$value: strip-unit($value-init);
		$isDone: "false";
    $important-value: null;
    @if $important == 1 {
        $important-value: !important;
    }

		@each $one-breakpoint in $breakpoints {
				$current-value: px-in-rem($one-breakpoint, $value);

				@if($min-value > $current-value) {
						@include media($one-breakpoint){
								#{$property}: rem($one-breakpoint, $min-value-init) $important-value;
						}
				}
				@else {
						@if($isDone == "false") {
								@include media($one-breakpoint){
										#{$property}: rem(xxl, $value-init) $important-value;
								}
						}
						$isDone: "true";
				}
		}
}

@mixin pux-scale-from($key,$from, $size) {
		$breakpoints: ("xxs","xs","sm","md","lg","xl","xxl");
		$i: 1;
		$isBreakpoint: false;
		$fromXxs: false;
		$firstChar: str-slice($from, 0, 1);
		@if($firstChar == "-") {
				$from: str-slice($from, 2);
				$isBreakpoint: true;
				$fromXxs: true;
		}

		@each $one-breakpoint in $breakpoints {
				@if(nth($breakpoints, $i) == $from AND $fromXxs == false) {
						$isBreakpoint: true;
				}

				@if ($isBreakpoint == true){
						@include media($one-breakpoint){
							#{$key}: rem($one-breakpoint, $size);
						}
				}

				@if(nth($breakpoints, $i) == $from AND $fromXxs == true) {
						$isBreakpoint: false;
				}
				$i: $i + 1;
		}
}
