@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?a399er');
  src:  url('fonts/icomoon.eot?a399er#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?a399er') format('woff2'),
    url('fonts/icomoon.ttf?a399er') format('truetype'),
    url('fonts/icomoon.woff?a399er') format('woff'),
    url('fonts/icomoon.svg?a399er#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-flexa-logo:before {
  content: "\e93f";
}
.ico-prolyft-logo:before {
  content: "\e93d";
  color: #fff;
}
.ico-stagedex-logo:before {
  content: "\e93e";
  color: #fff;
}
.ico-afi-arrow-circle_reapired:before {
  content: "\e902";
  color: #fff;
}
.ico-afi-menu-arrow_reapired:before {
  content: "\e904";
  color: #fff;
}
.ico-tomcat-logo:before {
  content: "\e901";
  color: #fff;
}
.ico-prolyte:before {
  content: "\e917";
}
.ico-prolyte-old:before {
  content: "\e90b";
}
.ico-exe-technology:before {
  content: "\e924";
}
.ico-mobiltechlifts:before {
  content: "\e925";
}
.ico-xstage:before {
  content: "\e926";
}
.ico-xtruss:before {
  content: "\e927";
}
.ico-logo2:before {
  content: "\e91c";
}
.ico-logo:before {
  content: "\e91b";
}
.ico-header-logo:before {
  content: "\e923";
}
.ico-Vector-2:before {
  content: "\e940";
  color: #ffdf00;
}
.ico-Fill_3:before {
  content: "\e90a";
}
.ico-instagram:before {
  content: "\e900";
}
.ico-reddit-social-logo-character:before {
  content: "\e919";
  color: #043a63;
}
.ico-x:before {
  content: "\e921";
  color: #ffd100;
}
.ico-litec_arrow_detail_arrow_small-left:before {
  content: "\e920";
}
.ico-litec_arrow_detail_arrow_small:before {
  content: "\e91f";
}
.ico-ico-arrow-big-ng2:before {
  content: "\e90e";
}
.ico-ico-arrow3:before {
  content: "\e90c";
}
.ico-ico-arrow2:before {
  content: "\e90d";
}
.ico-ico-arrow-big-down:before {
  content: "\e903";
}
.ico-ico-region:before {
  content: "\e905";
}
.ico-ico-yt-icon:before {
  content: "\e906";
}
.ico-ico-arrow-big-ng:before {
  content: "\e907";
}
.ico-ico-arrow:before {
  content: "\e908";
}
.ico-ico-search:before {
  content: "\e909";
}
.ico-ico-share-button-linkedin-icon:before {
  content: "\e910";
}
.ico-ico-share-button-mail-icon:before {
  content: "\e911";
}
.ico-ico-share-button-tweet-icon:before {
  content: "\e912";
}
.ico-ico-share-button-vimeo-icon:before {
  content: "\e913";
}
.ico-ico-share-button-facebook-icon:before {
  content: "\e914";
}
.ico-ico-share-button-google-icon:before {
  content: "\e915";
}
.ico-ico-share-button-twitter-icon:before {
  content: "\e916";
}
.ico-chevron-down:before {
  content: "\e918";
}
.ico-chevron-left:before {
  content: "\e91a";
}
.ico-chevron-right:before {
  content: "\e91d";
}
.ico-chevron-small-down:before {
  content: "\e91e";
}
.ico-chevron-small-left:before {
  content: "\e928";
}
.ico-chevron-small-right:before {
  content: "\e929";
}
.ico-chevron-small-up:before {
  content: "\e92a";
}
.ico-chevron-thin-down:before {
  content: "\e92b";
}
.ico-chevron-thin-left:before {
  content: "\e92c";
}
.ico-chevron-thin-right:before {
  content: "\e92d";
}
.ico-chevron-thin-up:before {
  content: "\e92e";
}
.ico-chevron-up:before {
  content: "\e92f";
}
.ico-circle-with-minus:before {
  content: "\e930";
}
.ico-circle-with-plus:before {
  content: "\e931";
}
.ico-info:before {
  content: "\e932";
}
.ico-link:before {
  content: "\e933";
}
.ico-mail:before {
  content: "\e934";
}
.ico-map:before {
  content: "\e935";
}
.ico-paper-plane:before {
  content: "\e936";
}
.ico-phone:before {
  content: "\e937";
}
.ico-facebook:before {
  content: "\e938";
}
.ico-instagram1:before {
  content: "\e939";
}
.ico-linkedin:before {
  content: "\e93a";
}
.ico-pinterest:before {
  content: "\e93b";
}
.ico-twitter:before {
  content: "\e93c";
}
.ico-map-pin:before {
  content: "\e90f";
}
.ico-spinner:before {
  content: "\e922";
}
.ico-loading:before {
  content: "\e922";
}
.ico-loading-wheel:before {
  content: "\e922";
}
.ico-busy:before {
  content: "\e922";
}
.ico-wait:before {
  content: "\e922";
}
