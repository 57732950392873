
@import "breakpoints.scss";
@import "css-rules.scss";
@import "scale.scss";
@import "../../_variables.scss";

@mixin bs-grid($space, $important: 0) {
  $important-value: null;
  @if $important == 1 {
    $important-value: !important;
  }
    .row {
        margin-left: -$space $important-value;
        margin-right: -$space $important-value;

        [class*="col-"] {
            padding-left: $space $important-value;
            padding-right: $space $important-value;
        }
    }
}

@mixin slick-arrows-size($arrow-width, $arrow-height){
  .slick-arrow{
    width: $arrow-width;
    height: $arrow-height;
  }
}

@mixin slick-arrows-position($arrow-left, $arrow-right){
  .slick-arrow{
    &.slick-prev{
      left: $arrow-left;
    }
    &.slick-next{
      right:  $arrow-right;
    }
  }
}

@mixin slick-arrows($arrow-width: $slider-arrow-width, $arrow-height: $slider-arrow-width,$arrow-background: $slider-arrow-background,$arrow-radius: $slider-arrow-radius,$arrow-border: $slider-arrow-border,$arrow-left: $slider-arrow-left,$arrow-right: $slider-arrow-right,$arrow-before-size: $slider-arrow-before-size,$arrow-prev-code: $slider-arrow-prev-code,$arrow-next-code: $slider-arrow-next-code){
  .slick-arrow{
    position:absolute;
    top:50%;
    margin-top: -($arrow-width/2);
    @include pux-scale-with-min("width", 50px, 40px);
    @include pux-scale-with-min("height", 50px, 40px);
    font-size:0;
    background: $arrow-background;
    border-radius: 50%;
    border: 2px solid $base-white;
    @include pux-scale-with-min("border-width", 3px, 2.6px);
    outline: 0;
    z-index: 22;
    cursor: pointer;
    @include transition;
    &:before{
      color: #fff;
      @include pux-scale-with-min("font-size", 50px, 40px);
      @include transition;
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 700;
      transform: translate(-50%, -50%);
    }
    &.slick-prev{
      left: $arrow-left;
      transform: rotate(180deg);
      &:before{
        content: "\e908";
      }
    }
    &.slick-next{
      right: $arrow-right;
      &:before{
        content: "\e908"
      }
    }
    &:hover{
      background: #fff;
      &:before{
        color: $base-black;
      }
    }
    &:active{
      background: #fff;
      &:before{
        color: $main-color;
      }
    }
  }
}


@mixin slick-dots($dot-bottom: $slider-dot-bottom, $dot-padding: $slider-dot-padding, $dot-width: $slider-dot-width, $dot-height: $slider-dot-height, $dot-border: $slider-dot-border, $dot-radius: $slider-dot-radius, $dot-background: $slider-dot-background, $dot-active: $slider-dot-active){
  .slick-dots{
    @include flex-block;
    @include align-items(center);
    position: relative;
    top:-11rem;
    left:0;
    width:100%;
    max-width: 169rem;
    margin:auto;
    bottom: $dot-bottom;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    li{
      margin:0;
      padding: $dot-padding;
      &:before{
        display:none;
      }
      &:last-child{
        padding:0;
      }
      button{
        font-size: 0;
        outline: 0;
        @include pux-static-size("width", $dot-width);
        @include pux-static-size("height", $dot-height);
        border: $dot-border;
        background: $dot-background;
        @include border-radius($dot-radius);
        padding: 0;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        &:before{
          content:"";
          @include pux-static-size("width", 6px);
          @include pux-static-size("height", 6px);
          background: #fff;
          @include border-radius(50%);
        }
      }
      &.slick-active{
        button{
          background: $dot-active;
        }
      }
    }
  }
}
