
$px-values: (5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px);

// bootstrap
$main-left: 10px;
$main-right: 10px;
$main-left-md: 4.5rem;
$main-right-md: 4.5rem;

// container
$container-max-width: 1300px;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;
$max-page-width: $container-max-width;

$menu-height: 85px;


// row
$row-margin-left: -10px;
$row-margin-right: -10px;
$row-margin-left-md: -2.5rem;
$row-margin-right-md: -2.5rem;

// col
$col-padding-left: 10px;
$col-padding-right: 10px;
$col-padding-left-md: 2.5rem;
$col-padding-right-md: 2.5rem;

// typography
$font-family: 'Zirkel', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 26px;
$min-line-height-perex: 22px;

// small text
$font-size-small: 14px;
$min-font-size-small: 14px;
$line-height-small: 18px;
$min-line-height-small: 18px;

// main colors
$color-link: #ffffff;
$main-color: #f73c37;
$second-color: #b7b7b7;
$color-line: #f1f1f1;
$base-black: #000;
$base-white: #fff;
$base-grey: #cecece;
$base-blue: #2977c9;
$color-gray-2: #e6e6e6;

//brand colors
$afi-color: #f73c37;
$milos-color: #ffd100;
$jte-color: #1F21AB;
$tomcat-color: #00853D;
$exe-color: #F73C37;
$mobil-color: #004494;
$litec-color: #fff;
$xstage-color: #191919;
$xtruss-color: #191919;
$prolyte-color: #44a1d6;
$stagedex-color: #ff4d00;
$prolyft-color: #8cbf30;


// button primary
$btn-primary-background: $base-white;
$btn-primary-color: $main-color;

// button default
$btn-default-background: $second-color;
$btn-default-color: $base-black;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: $base-grey;
$table-tr-border: #f1f1f1;
$respo-table-break: "md";

// menu
$hamburger-width: 6rem;
$menu-break: "md";

// form
$color-placeholder: #fff;

// inputs
$border-color: #ffffff;
$input-color: $base-white;
$input-background: transparent;
$focus-border-color: $base-white;
$input-height: 50px;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $input-color;
$select-border: $border-color;
$select-border-focus: $border-color;
$select-arrow-color: $base-white;
$select-arrow-code: "\e91f";
$select-use-triangle: 0;
$select-height: $input-height;

// datepicker
$datepicker-header-background: darken($main-color, 5);
$datepicker-header-color: $base-white;
$datepicker-header-arrows-color: $base-white;
$datepicker-option-backgorund:$main-color;
$datepicker-option-color: $base-white;
$datepicker-selected-background: $main-color;
$datepicker-selected-color: $base-white;
$datepicker-full-background: $base-white;
$datepicker-th-color: #757575 ;
$datepicker-th-border: #e9ecef;
$datepicker-year-picker-color: $main-color;

// pokud se používá timepicker je potřeba definovat barvy i v /plugins/datetimepicker/bootstrap-material-datetimepicker.js

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: rgba(#fff,0.1);
$error-blink-final: $main-color;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;

$ratio-125: 1.07;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;
$scale-ratio-1600: 1.11;
$scale-ratio-1920: 1.33;
$scale-ratio-2048: 1.42;
$scale-ratio-2304: 1.60;
$scale-ratio-2560: 1.77;
$scale-ratio-2880: 2.0;
$scale-ratio-3440: 2.38;
$scale-ratio-3840: 2.66;

//*** content ***

$slider-arrow-width: 50px;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: transparent;
$slider-arrow-radius: 50%;
$slider-arrow-border: 4px solid #fff;
$slider-arrow-left: 2rem;
$slider-arrow-right: 2rem;
$slider-arrow-before-size: 32px;
$slider-arrow-prev-code: "\e90d";
$slider-arrow-next-code: "\e908";

$slider-dot-width: 30px;
$slider-dot-height: $slider-dot-width;
$slider-dot-background: rgba(30,30,30,0.2);
$slider-dot-active: rgba(30,30,30,1);
$slider-dot-border: none;
$slider-dot-radius: 50%;
$slider-dot-padding: 0 2rem 0 0;
$slider-dot-bottom: 8rem;

//*** lazy load ***
$imgLoadAnimation: animateLoaded__ 0.1s ease-out forwards;

//arrays
$share-icon-names: (
  google: "\e915",
  a4i: "\e",
  instagram: "\e900",
  tumblr: "\e916",
  facebook: "\e914",
  mail: "\e911",
  twitter: "\e912",
  linkedin: "\e910",
  vine: "\e913",
  youtube: "\e906"
);

$brand-colors: (
	areafourindustries: $afi-color,
  milos: $milos-color,
  jte: $jte-color,
  tomcat: $tomcat-color,
  exe: $exe-color,
  mobiletechlifts: $mobil-color,
  litec: #043a63,
  xstage: $xstage-color,
  xtruss: $xtruss-color,
  prolyte: $prolyte-color,
  stagedex: $stagedex-color,
  prolyft: $prolyft-color,
  afi: #f73c37
);
