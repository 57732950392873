@charset "UTF-8";
.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 130rem; }
  @media (min-width: 1200px) {
    .pux-container {
      width: 91%; } }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-container .row,
.pux-container .items-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  @media (min-width: 768px) {
    .pux-container .row,
    .pux-container .items-container {
      margin-left: -2.5rem;
      margin-right: -2.5rem; } }

.pux-container [class^="col-"] {
  padding: 10px; }
  @media (min-width: 768px) {
    .pux-container [class^="col-"] {
      padding: 2.5rem; } }

.pux-fluid-container {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
  width: 100%; }
  header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 14rem;
    width: 100%;
    z-index: -1;
    pointer-events: none; }
    @media (min-width: 992px) {
      header:after {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000000 100%); } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    max-width: 144.44444rem;
    border-bottom: 1px solid #f73c37;
    margin: 0 auto; }
    @media (min-width: 992px) {
      header .header {
        height: 8.5rem; } }
    header .header-left {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex: 0 0 50%;
      -moz-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      -webkit-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
      @media (min-width: 992px) {
        header .header-left {
          -webkit-flex: 0 0 16rem;
          -moz-flex: 0 0 16rem;
          -ms-flex: 0 0 16rem;
          -webkit-flex: 0 0 16rem;
          flex: 0 0 16rem;
          max-width: 16rem;
          -webkit-box-pack: flex-start;
          -moz-box-pack: flex-start;
          -ms-flex-pack: flex-start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start; } }
      header .header-left-logo {
        padding-left: 2px; }
        @media (min-width: 768px) {
          header .header-left-logo {
            padding-left: 4px; } }
        @media (min-width: 992px) {
          header .header-left-logo {
            padding-left: 0.5rem; } }
        @media (min-width: 1200px) {
          header .header-left-logo {
            padding-left: 0.44444rem; } }
        @media (min-width: 1400px) {
          header .header-left-logo {
            padding-left: 0.4rem; } }
        header .header-left-logo img {
          height: 33px !important; }
          @media (min-width: 992px) {
            header .header-left-logo img {
              height: 5.5rem !important; } }
    header .header-right {
      overflow: hidden;
      position: fixed;
      top: 0;
      right: 0;
      background-color: #f73c37;
      transition: transform 200ms ease-out;
      transform: translate3d(100%, 0, 0);
      height: 100vh;
      width: 220px;
      padding: 85px 15px 25px 25px;
      overflow-x: visible;
      overflow-y: hidden; }
      @media (min-width: 545px) {
        header .header-right {
          width: 440px;
          padding: 105px 30px 50px 50px; } }
      @media (min-width: 992px) {
        header .header-right {
          transform: translate3d(0, 0, 0);
          transition: none;
          height: auto;
          width: auto;
          padding: 0;
          overflow: visible; } }
      header .header-right.open {
        transform: translate3d(0, 0, 0); }
      @media (min-width: 992px) {
        header .header-right {
          position: static;
          background-color: transparent; } }
      header .header-right-menu {
        max-height: calc(100vh - 130px);
        overflow-x: visible;
        overflow-y: auto;
        position: relative;
        user-select: none; }
        @media (min-width: 992px) {
          header .header-right-menu {
            max-height: none;
            padding: 0;
            overflow: visible;
            width: 68rem; } }
        header .header-right-menu #menuElem {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-flex-flow: column nowrap;
          -ms-flex-direction: column;
          -ms-flex-wrap: nowrap;
          -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
          padding: 0;
          margin: 0;
          list-style: none;
          overflow: hidden;
          padding-bottom: 20px;
          border-bottom: 1px solid #fff; }
          @media (min-width: 768px) {
            header .header-right-menu #menuElem {
              padding-bottom: 30px; } }
          @media (min-width: 992px) {
            header .header-right-menu #menuElem {
              -moz-flex-flow: row nowrap;
              -ms-flex-direction: row;
              -ms-flex-wrap: nowrap;
              -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
              overflow: visible;
              display: flex;
              width: 100%;
              justify-content: space-between;
              height: 8.5rem;
              border: 0;
              padding: 0; } }
          header .header-right-menu #menuElem > li {
            height: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; }
            header .header-right-menu #menuElem > li > span,
            header .header-right-menu #menuElem > li > a {
              -moz-transition: all ease-in-out 200ms;
              -o-transition: all ease-in-out 200ms;
              -webkit-transition: all ease-in-out 200ms;
              transition: all ease-in-out 200ms;
              font-size: 24px;
              line-height: 40px;
              font-weight: 900;
              color: #fff;
              flex: 0 1 100%;
              max-width: 100%; }
              @media (min-width: 992px) {
                header .header-right-menu #menuElem > li > span,
                header .header-right-menu #menuElem > li > a {
                  font-size: 1.8rem;
                  height: 100%;
                  line-height: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  overflow: hidden; } }
              header .header-right-menu #menuElem > li > span:after,
              header .header-right-menu #menuElem > li > a:after {
                display: block;
                width: 100%;
                height: 4px;
                background-color: #f73c37;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateY(100%);
                -moz-transition: all ease-in-out 200ms;
                -o-transition: all ease-in-out 200ms;
                -webkit-transition: all ease-in-out 200ms;
                transition: all ease-in-out 200ms; }
                @media (min-width: 992px) {
                  header .header-right-menu #menuElem > li > span:after,
                  header .header-right-menu #menuElem > li > a:after {
                    content: ""; } }
              header .header-right-menu #menuElem > li > span:hover, header .header-right-menu #menuElem > li > span:focus,
              header .header-right-menu #menuElem > li > a:hover,
              header .header-right-menu #menuElem > li > a:focus {
                text-decoration: underline; }
                @media (min-width: 992px) {
                  header .header-right-menu #menuElem > li > span:hover, header .header-right-menu #menuElem > li > span:focus,
                  header .header-right-menu #menuElem > li > a:hover,
                  header .header-right-menu #menuElem > li > a:focus {
                    color: #f73c37;
                    text-decoration: none; } }
            header .header-right-menu #menuElem > li ul {
              display: none;
              list-style-type: none;
              padding-left: 9px;
              margin: 5px 0px; }
              @media (min-width: 768px) {
                header .header-right-menu #menuElem > li ul {
                  padding-left: 18px;
                  margin: 7px 0; } }
              @media (min-width: 992px) {
                header .header-right-menu #menuElem > li ul {
                  background-color: rgba(247, 60, 55, 0.93);
                  padding: 10px 25px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  margin: 0;
                  opacity: 0;
                  transform: translate(0px, 100%);
                  color: #fff;
                  display: inline-block;
                  visibility: hidden;
                  transition: all 200ms ease-in-out; } }
              header .header-right-menu #menuElem > li ul li {
                padding: 0;
                margin: 0; }
                header .header-right-menu #menuElem > li ul li a {
                  color: #fff;
                  font-weight: 900;
                  font-size: 18px;
                  line-height: 30px;
                  position: relative;
                  display: inline-block;
                  -moz-transition: all ease-in-out 200ms;
                  -o-transition: all ease-in-out 200ms;
                  -webkit-transition: all ease-in-out 200ms;
                  transition: all ease-in-out 200ms; }
                  @media (min-width: 992px) {
                    header .header-right-menu #menuElem > li ul li a {
                      padding-bottom: 3px;
                      margin-bottom: 7px;
                      font-size: 16px;
                      line-height: 3rem;
                      white-space: nowrap; } }
                  @media (min-width: 1400px) {
                    header .header-right-menu #menuElem > li ul li a {
                      font-size: 1.6rem; } }
                  @media (min-width: 992px) {
                    header .header-right-menu #menuElem > li ul li a:after {
                      content: "";
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      width: 100%;
                      height: 2px;
                      background-color: #ffffff;
                      -moz-transition: all ease-in-out 200ms;
                      -o-transition: all ease-in-out 200ms;
                      -webkit-transition: all ease-in-out 200ms;
                      transition: all ease-in-out 200ms;
                      transition-timing-function: ease-out;
                      opacity: 0; } }
                  @media (min-width: 320px) and (max-width: 991px) {
                    header .header-right-menu #menuElem > li ul li a:hover, header .header-right-menu #menuElem > li ul li a:focus {
                      text-decoration: underline; } }
                  header .header-right-menu #menuElem > li ul li a:hover:after, header .header-right-menu #menuElem > li ul li a:focus:after {
                    opacity: 1; }
            header .header-right-menu #menuElem > li:hover a:after, header .header-right-menu #menuElem > li:focus a:after {
              transform: translateY(0%); }
            @media (min-width: 992px) {
              header .header-right-menu #menuElem > li:hover ul, header .header-right-menu #menuElem > li:focus ul {
                visibility: visible;
                opacity: 1; } }
            header .header-right-menu #menuElem > li.Highlighted a:after {
              transform: translateY(0%); }
    header .header-right-mobile-menu {
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 992px) {
        header .header-right-mobile-menu {
          display: none; } }
      header .header-right-mobile-menu-inner {
        position: relative;
        cursor: pointer;
        width: 60px;
        height: 55px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end; }
        @media (min-width: 768px) {
          header .header-right-mobile-menu-inner {
            margin: 7 14px; } }
        @media (min-width: 992px) {
          header .header-right-mobile-menu-inner {
            margin: 0; } }
        header .header-right-mobile-menu-inner span {
          width: 21px;
          position: absolute;
          margin-top: -1px;
          background: #fff;
          height: 2px;
          margin-left: -11px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-right-mobile-menu-inner span:before, header .header-right-mobile-menu-inner span:after {
            content: "";
            width: 21px;
            height: 2px;
            position: absolute;
            display: block;
            background: #fff;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-right-mobile-menu-inner span:before {
            top: -8px; }
          header .header-right-mobile-menu-inner span:after {
            bottom: -8px; }
        @media (min-width: 992px) {
          header .header-right-mobile-menu-inner {
            display: none !important; } }
        header .header-right-mobile-menu-inner.open span {
          background: transparent !important; }
          header .header-right-mobile-menu-inner.open span:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0; }
          header .header-right-mobile-menu-inner.open span:after {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 0; }
  @media (min-width: 320px) and (max-width: 991px) {
    header.small-type {
      animation: small-header-mobile linear 250ms forwards; } }
  @media (min-width: 992px) {
    header.small-type {
      animation: small-header linear 500ms forwards; } }
  @media (min-width: 992px) {
    header.small-type .header {
      animation: small-header-inner linear 500ms forwards; } }
  @media (min-width: 992px) {
    header.small-type .header .header-left-logo img {
      height: 4rem !important;
      transition: all 0ms linear;
      transition-delay: 0.25s; } }
  @media (min-width: 992px) {
    header.small-type .header #menuElem {
      animation: small-header-inner linear 500ms forwards; } }
  @media (min-width: 320px) and (max-width: 991px) {
    header.normal-type {
      animation: small-header-mobile-reverse linear 250ms forwards; } }
  @media (min-width: 992px) {
    header.normal-type {
      opacity: 0;
      animation: show-header ease-in 350ms forwards; } }

@keyframes small-header {
  0% {
    opacity: 1;
    background-color: transparent; }
  25% {
    opacity: 0; }
  50% {
    opacity: 0;
    background-color: #000; }
  100% {
    opacity: 1;
    background-color: #000; } }

@keyframes small-header-mobile {
  0% {
    background-color: transparent; }
  100% {
    background-color: #000; } }

@keyframes small-header-mobile-reverse {
  0% {
    background-color: #000; }
  100% {
    background-color: transparent; } }

@keyframes small-header-inner {
  0% { }
  50% {
    height: 6.4rem;
    border: 0; }
  100% {
    opacity: 100%;
    height: 6.4rem;
    border: 0; } }

@keyframes show-header {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  background-color: #000; }

.footer-social {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px; }
  @media (min-width: 992px) {
    .footer-social {
      margin-top: 5rem; } }
  .footer-social-container {
    width: 100%; }
    @media (min-width: 545px) {
      .footer-social-container {
        max-width: 300px; } }
    @media (min-width: 992px) {
      .footer-social-container {
        max-width: 35rem; } }
  .footer-social-list {
    padding: 20px 0 30px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 992px) {
      .footer-social-list {
        padding: 3.6rem 0 5rem; } }
    .footer-social-list .ico-social {
      display: inline-block;
      border-radius: 50%;
      margin: 6px;
      height: 27px;
      width: 27px;
      text-decoration: none !important;
      position: relative;
      font-size: 0; }
      @media (min-width: 992px) {
        .footer-social-list .ico-social {
          margin: 0.75rem; } }
      @media (min-width: 1200px) {
        .footer-social-list .ico-social {
          margin: 0.66667rem; } }
      @media (min-width: 1400px) {
        .footer-social-list .ico-social {
          margin: 0.6rem; } }
      @media (min-width: 992px) {
        .footer-social-list .ico-social {
          width: 3.375rem; } }
      @media (min-width: 1200px) {
        .footer-social-list .ico-social {
          width: 3rem; } }
      @media (min-width: 1400px) {
        .footer-social-list .ico-social {
          width: 2.7rem; } }
      @media (min-width: 992px) {
        .footer-social-list .ico-social {
          height: 3.375rem; } }
      @media (min-width: 1200px) {
        .footer-social-list .ico-social {
          height: 3rem; } }
      @media (min-width: 1400px) {
        .footer-social-list .ico-social {
          height: 2.7rem; } }
      .footer-social-list .ico-social:before {
        color: #fff;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        font-size: 27px;
        position: absolute;
        top: 0;
        left: 0; }
        @media (min-width: 992px) {
          .footer-social-list .ico-social:before {
            font-size: 3.375rem; } }
        @media (min-width: 1200px) {
          .footer-social-list .ico-social:before {
            font-size: 3rem; } }
        @media (min-width: 1400px) {
          .footer-social-list .ico-social:before {
            font-size: 2.7rem; } }
      .footer-social-list .ico-social:focus:before, .footer-social-list .ico-social:hover:before {
        color: #f73c37; }
      .footer-social-list .ico-social:first-child {
        margin: 6px 6px 6px 0; }
        @media (min-width: 768px) {
          .footer-social-list .ico-social:first-child {
            margin: 6px; } }
        @media (min-width: 992px) {
          .footer-social-list .ico-social:first-child {
            margin: 0.75rem; } }
        @media (min-width: 1200px) {
          .footer-social-list .ico-social:first-child {
            margin: 0.66667rem; } }
        @media (min-width: 1400px) {
          .footer-social-list .ico-social:first-child {
            margin: 0.6rem; } }
      .footer-social-list .ico-social.s-google:before {
        content: ""; }
      .footer-social-list .ico-social.s-a4i:before {
        content: ""; }
      .footer-social-list .ico-social.s-instagram:before {
        content: ""; }
      .footer-social-list .ico-social.s-tumblr:before {
        content: ""; }
      .footer-social-list .ico-social.s-facebook:before {
        content: ""; }
      .footer-social-list .ico-social.s-mail:before {
        content: ""; }
      .footer-social-list .ico-social.s-twitter:before {
        content: ""; }
      .footer-social-list .ico-social.s-linkedin:before {
        content: ""; }
      .footer-social-list .ico-social.s-vine:before {
        content: ""; }
      .footer-social-list .ico-social.s-youtube:before {
        content: ""; }
    .footer-social-list:after {
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      position: absolute;
      background-color: #fff;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 992px) {
        .footer-social-list:after {
          width: 5rem; } }
      @media (min-width: 992px) {
        .footer-social-list:after {
          height: 0.125rem; } }
      @media (min-width: 1200px) {
        .footer-social-list:after {
          height: 0.11111rem; } }
      @media (min-width: 1400px) {
        .footer-social-list:after {
          height: 0.1rem; } }

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 10px 0 30px; }
  @media (min-width: 992px) {
    .footer-menu {
      padding: 2rem 0 5rem; } }
  .footer-menu:after {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 992px) {
      .footer-menu:after {
        width: 5rem; } }
    @media (min-width: 992px) {
      .footer-menu:after {
        height: 0.125rem; } }
    @media (min-width: 1200px) {
      .footer-menu:after {
        height: 0.11111rem; } }
    @media (min-width: 1400px) {
      .footer-menu:after {
        height: 0.1rem; } }
  .footer-menu-item {
    flex: 0 1 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 30px; }
    @media (min-width: 480px) {
      .footer-menu-item {
        flex: 0 1 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .footer-menu-item {
        flex: 0 1 20%;
        max-width: 20%;
        margin-top: 5rem; } }
    .footer-menu-item-title {
      font-weight: 900 !important;
      font-size: 20px;
      padding-bottom: 6px;
      display: inline-block; }
      @media (min-width: 992px) {
        .footer-menu-item-title {
          font-size: 2.5rem; } }
      @media (min-width: 1200px) {
        .footer-menu-item-title {
          font-size: 2.22222rem; } }
      @media (min-width: 1400px) {
        .footer-menu-item-title {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .footer-menu-item-title {
          padding-bottom: 1rem; } }
      @media (min-width: 1200px) {
        .footer-menu-item-title {
          padding-bottom: 0.88889rem; } }
      @media (min-width: 1400px) {
        .footer-menu-item-title {
          padding-bottom: 0.8rem; } }
    .footer-menu-item-list {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .footer-menu-item a {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      font-size: 16px;
      line-height: 24px; }
      @media (min-width: 992px) {
        .footer-menu-item a {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .footer-menu-item a {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .footer-menu-item a {
          font-size: 1.6rem; } }
      @media (min-width: 992px) {
        .footer-menu-item a {
          line-height: 3rem; } }
      @media (min-width: 1200px) {
        .footer-menu-item a {
          line-height: 2.66667rem; } }
      @media (min-width: 1400px) {
        .footer-menu-item a {
          line-height: 2.4rem; } }
      .footer-menu-item a:hover, .footer-menu-item a:focus {
        text-decoration: underline; }

.footer-text {
  width: 100%;
  text-align: center;
  padding: 30px 0; }
  @media (min-width: 768px) {
    .footer-text {
      padding: 4.4rem 0; } }

.is-sm {
  display: none; }
  @media (min-width: 545px) {
    .is-sm {
      display: block; } }

.is-md {
  display: none; }
  @media (min-width: 768px) {
    .is-md {
      display: block; } }

.is-lg {
  display: none; }
  @media (min-width: 992px) {
    .is-lg {
      display: block; } }

.is-xl {
  display: none; }
  @media (min-width: 1200px) {
    .is-xl {
      display: block; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.1s ease-out forwards; }

.menu-layer:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  opacity: 0;
  animation: menu-layer 150ms linear forwards;
  animation-delay: 100ms;
  z-index: 99; }
  @media (min-width: 992px) {
    .menu-layer:before {
      display: none !important; } }

@keyframes menu-layer {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.space-10 .row,
.space-10 .items-container {
  margin-left: -10px;
  margin-right: -10px; }
  @media (min-width: 992px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-left: -1.25rem; } }
  @media (min-width: 1200px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-left: -1.11111rem; } }
  @media (min-width: 1400px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-left: -1rem; } }
  @media (min-width: 992px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-right: -1.25rem; } }
  @media (min-width: 1200px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-right: -1.11111rem; } }
  @media (min-width: 1400px) {
    .space-10 .row,
    .space-10 .items-container {
      margin-right: -1rem; } }
  .space-10 .row [class*="col-"],
  .space-10 .items-container [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }
    @media (min-width: 992px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-left: -1.25rem; } }
    @media (min-width: 1200px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-left: -1.11111rem; } }
    @media (min-width: 1400px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-left: -1rem; } }
    @media (min-width: 992px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-right: -1.25rem; } }
    @media (min-width: 1200px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-right: -1.11111rem; } }
    @media (min-width: 1400px) {
      .space-10 .row [class*="col-"],
      .space-10 .items-container [class*="col-"] {
        padding-right: -1rem; } }

.fancybox-image-wrap {
  height: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 100vh; }
  @media (min-width: 992px) {
    .fancybox-image-wrap {
      border-width: 1.25rem; } }
  @media (min-width: 1200px) {
    .fancybox-image-wrap {
      border-width: 1.11111rem; } }
  @media (min-width: 1400px) {
    .fancybox-image-wrap {
      border-width: 1rem; } }
  .fancybox-image-wrap .fancybox-image {
    position: static !important; }
  .fancybox-image-wrap .fancy-box-closer-caption {
    background-color: #000;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 12px 30px 7px; }
    @media (min-width: 992px) {
      .fancybox-image-wrap .fancy-box-closer-caption {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .fancybox-image-wrap .fancy-box-closer-caption {
        font-size: 2rem; } }
    @media (min-width: 1400px) {
      .fancybox-image-wrap .fancy-box-closer-caption {
        font-size: 1.8rem; } }
    @media (min-width: 992px) {
      .fancybox-image-wrap .fancy-box-closer-caption {
        padding: 1.2rem 3rem 0.7rem; } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*
@include font-face-otf("Zirkel-Black", "/AFI/media/system/css/fonts/Zirkel-Black", 900);
@include font-face-otf("Zirkel-Bold", "/AFI/media/system/css/fonts/Zirkel-Bold", 700);
@include font-face-otf("Zirkel-Medium", "/AFI/media/system/css/fonts/Zirkel-Medium", 500);
@include font-face-otf("Zirkel-Regular", "/AFI/media/system/css/fonts/Zirkel-Regular", 400);

@include font-face-woff("Zirkel-Black", "/AFI/media/system/css/fonts/zirkel-black", 900);
@include font-face-woff("Zirkel-Bold", "/AFI/media/system/css/fonts/zirkel-bold", 700);
@include font-face-woff("Zirkel-Medium", "/AFI/media/system/css/fonts/Zirkel-Medium", 500);
@include font-face-woff("Zirkel-Regular", "/AFI/media/system/css/fonts/zirkel-regular", 400);
*/
@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-light.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-light.otf) format("otf");
  font-weight: 300; }

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-regular.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-Regular.otf) format("otf");
  font-weight: 400; }

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-Medium.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-Medium.otf) format("otf");
  font-weight: 500; }

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-bold.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-Black.otf) format("otf");
  font-weight: 700; }

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/zirkel-black.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-Black.otf) format("otf");
  font-weight: 900; }

@font-face {
  font-family: "Zirkel";
  src: url(/AFI/media/system/css/fonts/Zirkel-LightItalic.woff) format("woff"), url(/AFI/media/system/css/fonts/Zirkel-LightItalic.otf) format("otf");
  font-weight: 400;
  font-style: italic; }

.text-medium {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500; }
  @media (min-width: 992px) {
    .text-medium {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .text-medium {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .text-medium {
      font-size: 1.8rem; } }

.top-space-base {
  margin-top: 40px; }
  @media (min-width: 992px) {
    .top-space-base {
      margin-top: 4.5rem; } }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }
  @media (min-width: 1600px) {
    html {
      font-size: 11.1px; } }
  @media (min-width: 1920px) {
    html {
      font-size: 13.3px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 9.63px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 10.7px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1600px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1600px), only screen and (min-resolution: 120dpi) and (min-width: 1600px), only screen and (min-resolution: 1.25dppx) and (min-width: 1600px) {
    html {
      font-size: 11.877px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1920px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1920px), only screen and (min-resolution: 120dpi) and (min-width: 1920px), only screen and (min-resolution: 1.25dppx) and (min-width: 1920px) {
    html {
      font-size: 14.231px; } }

form,
html,
body {
  font-family: "Zirkel", sans-serif;
  height: 100%;
  color: #fff;
  background-color: #000; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.6rem; } }

body[white-theme] {
  color: #000;
  background-color: #fff; }
  body[white-theme] header {
    background-color: #fff;
    color: #000; }
    body[white-theme] header:after {
      display: none; }
    body[white-theme] header .header-right-mobile-menu-inner span {
      background: #000; }
      body[white-theme] header .header-right-mobile-menu-inner span:after, body[white-theme] header .header-right-mobile-menu-inner span:before {
        background: #000; }
    @media (min-width: 992px) {
      body[white-theme] header #menuElem > li > a {
        color: #000; } }
  body[white-theme] main,
  body[white-theme] footer {
    color: #000;
    background-color: #fff; }
    body[white-theme] main .footer-newsletter,
    body[white-theme] footer .footer-newsletter {
      padding-top: 10rem; }
    body[white-theme] main .ico-social:before,
    body[white-theme] footer .ico-social:before {
      color: #000; }

.bg-black {
  background-color: #000;
  color: #fff; }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  body .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 480px) {
  body .pt-5 {
    padding-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .pt-5 {
    padding-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .pt-5 {
    padding-top: 1.33333rem; } }

@media (min-width: 992px) {
  body .pt-5 {
    padding-top: 1.25rem; } }

@media (min-width: 1200px) {
  body .pt-5 {
    padding-top: 1.11111rem; } }

@media (min-width: 1400px) {
  body .pt-5 {
    padding-top: 1rem; } }

@media (min-width: 320px) {
  body .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 480px) {
  body .pb-5 {
    padding-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .pb-5 {
    padding-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .pb-5 {
    padding-bottom: 1.33333rem; } }

@media (min-width: 992px) {
  body .pb-5 {
    padding-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  body .pb-5 {
    padding-bottom: 1.11111rem; } }

@media (min-width: 1400px) {
  body .pb-5 {
    padding-bottom: 1rem; } }

@media (min-width: 320px) {
  body .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 480px) {
  body .mt-5 {
    margin-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .mt-5 {
    margin-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .mt-5 {
    margin-top: 1.33333rem; } }

@media (min-width: 992px) {
  body .mt-5 {
    margin-top: 1.25rem; } }

@media (min-width: 1200px) {
  body .mt-5 {
    margin-top: 1.11111rem; } }

@media (min-width: 1400px) {
  body .mt-5 {
    margin-top: 1rem; } }

@media (min-width: 320px) {
  body .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 480px) {
  body .mb-5 {
    margin-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .mb-5 {
    margin-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .mb-5 {
    margin-bottom: 1.33333rem; } }

@media (min-width: 992px) {
  body .mb-5 {
    margin-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  body .mb-5 {
    margin-bottom: 1.11111rem; } }

@media (min-width: 1400px) {
  body .mb-5 {
    margin-bottom: 1rem; } }

@media (min-width: 320px) {
  body .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 480px) {
  body .pt-10 {
    padding-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .pt-10 {
    padding-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .pt-10 {
    padding-top: 1.33333rem; } }

@media (min-width: 992px) {
  body .pt-10 {
    padding-top: 1.25rem; } }

@media (min-width: 1200px) {
  body .pt-10 {
    padding-top: 1.11111rem; } }

@media (min-width: 1400px) {
  body .pt-10 {
    padding-top: 1rem; } }

@media (min-width: 320px) {
  body .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 480px) {
  body .pb-10 {
    padding-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .pb-10 {
    padding-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .pb-10 {
    padding-bottom: 1.33333rem; } }

@media (min-width: 992px) {
  body .pb-10 {
    padding-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  body .pb-10 {
    padding-bottom: 1.11111rem; } }

@media (min-width: 1400px) {
  body .pb-10 {
    padding-bottom: 1rem; } }

@media (min-width: 320px) {
  body .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 480px) {
  body .mt-10 {
    margin-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .mt-10 {
    margin-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .mt-10 {
    margin-top: 1.33333rem; } }

@media (min-width: 992px) {
  body .mt-10 {
    margin-top: 1.25rem; } }

@media (min-width: 1200px) {
  body .mt-10 {
    margin-top: 1.11111rem; } }

@media (min-width: 1400px) {
  body .mt-10 {
    margin-top: 1rem; } }

@media (min-width: 320px) {
  body .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 480px) {
  body .mb-10 {
    margin-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .mb-10 {
    margin-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .mb-10 {
    margin-bottom: 1.33333rem; } }

@media (min-width: 992px) {
  body .mb-10 {
    margin-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  body .mb-10 {
    margin-bottom: 1.11111rem; } }

@media (min-width: 1400px) {
  body .mb-10 {
    margin-bottom: 1rem; } }

@media (min-width: 320px) {
  body .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 480px) {
  body .pt-15 {
    padding-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .pt-15 {
    padding-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .pt-15 {
    padding-top: 1.5rem; } }

@media (min-width: 320px) {
  body .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 480px) {
  body .pb-15 {
    padding-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .pb-15 {
    padding-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .pb-15 {
    padding-bottom: 1.5rem; } }

@media (min-width: 320px) {
  body .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 480px) {
  body .mt-15 {
    margin-top: 1.81818rem; } }

@media (min-width: 545px) {
  body .mt-15 {
    margin-top: 1.66667rem; } }

@media (min-width: 768px) {
  body .mt-15 {
    margin-top: 1.5rem; } }

@media (min-width: 320px) {
  body .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 480px) {
  body .mb-15 {
    margin-bottom: 1.81818rem; } }

@media (min-width: 545px) {
  body .mb-15 {
    margin-bottom: 1.66667rem; } }

@media (min-width: 768px) {
  body .mb-15 {
    margin-bottom: 1.5rem; } }

@media (min-width: 320px) {
  body .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  body .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  body .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  body .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  body .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  body .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  body .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  body .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  body .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  body .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  body .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  body .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  body .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  body .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  body .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  body .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  body .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  body .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  body .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  body .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  body .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  body .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  body .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  body .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  body .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  body .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  body .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  body .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  body .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  body .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  body .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  body .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  body .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  body .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  body .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  body .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  body .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  body .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  body .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  body .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  body .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  body .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  body .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  body .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  body .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  body .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  body .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  body .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  body .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  body .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  body .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  body .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  body .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  body .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  body .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  body .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  body .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  body .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  body .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  body .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  body .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  body .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  body .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  body .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  body .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  body .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  body .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  body .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  font-weight: 900; }
  @media (min-width: 320px) and (max-width: 479px) {
    h1,
    .h1 {
      font-size: 34px;
      line-height: 38px; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 7.2rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 7.6rem; } }

h2,
.h2 {
  font-weight: 900; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 6rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 5.45455rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 5.4rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 6.8rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 6.18182rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 5.8rem; } }

h3,
.h3 {
  font-weight: 900; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4.8rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 4.36364rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3.4rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 5.09091rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 4.66667rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 3.8rem; } }

h4,
.h4 {
  font-weight: 900; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.4rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      line-height: 4.4rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 4rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 3.66667rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 2.93333rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      line-height: 2.8rem; } }

h5,
.h5 {
  font-weight: 900; }
  @media (min-width: 320px) {
    h5,
    .h5 {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    h5,
    .h5 {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    h5,
    .h5 {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    h5,
    .h5 {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    h5,
    .h5 {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    h5,
    .h5 {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    h5,
    .h5 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h5,
    .h5 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h5,
    .h5 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h5,
    .h5 {
      line-height: 2.66667rem; } }
  @media (min-width: 992px) {
    h5,
    .h5 {
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    h5,
    .h5 {
      line-height: 2.22222rem; } }
  @media (min-width: 1400px) {
    h5,
    .h5 {
      line-height: 2.2rem; } }

h6,
.h6 {
  font-weight: 900; }
  @media (min-width: 320px) {
    h6,
    .h6 {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    h6,
    .h6 {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    h6,
    .h6 {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    h6,
    .h6 {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    h6,
    .h6 {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    h6,
    .h6 {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    h6,
    .h6 {
      font-size: 1.4rem; } }
  @media (min-width: 320px) {
    h6,
    .h6 {
      line-height: 3.6rem; } }
  @media (min-width: 480px) {
    h6,
    .h6 {
      line-height: 3.27273rem; } }
  @media (min-width: 545px) {
    h6,
    .h6 {
      line-height: 3rem; } }
  @media (min-width: 768px) {
    h6,
    .h6 {
      line-height: 2.4rem; } }
  @media (min-width: 992px) {
    h6,
    .h6 {
      line-height: 2.25rem; } }
  @media (min-width: 1200px) {
    h6,
    .h6 {
      line-height: 2rem; } }
  @media (min-width: 1400px) {
    h6,
    .h6 {
      line-height: 1.8rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2.2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 4.4rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 4rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 3.66667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 2.93333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 2.75rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 2.6rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 3rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.8rem; } }

a {
  color: #ffffff;
  cursor: pointer;
  font-weight: 400; }
  a:hover {
    color: #ffffff;
    font-weight: 500;
    outline: none;
    text-decoration: none; }

main a:active {
  outline: none;
  text-decoration: underline;
  font-weight: 500; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: 1px solid #fff;
  padding: 1.5rem 4rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 900;
  outline: none !important; }
  @media (min-width: 768px) {
    .btn {
      padding: 1.5rem 6rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .btn {
      font-size: 1.6rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.arrow-btn {
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 18px;
  position: relative;
  z-index: 2;
  text-decoration: none !important; }
  @media (min-width: 992px) {
    .arrow-btn {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .arrow-btn {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .arrow-btn {
      font-size: 1.8rem; } }
  .arrow-btn:before, .arrow-btn:after {
    content: "\e908";
    width: 30px;
    height: 30px;
    font-size: 29px;
    margin-left: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-weight: 900;
    text-decoration: none !important;
    padding-top: 0.24rem; }
    @media (min-width: 768px) {
      .arrow-btn:before, .arrow-btn:after {
        padding-top: 0; } }
    @media (min-width: 992px) {
      .arrow-btn:before, .arrow-btn:after {
        font-size: 3.625rem; } }
    @media (min-width: 1200px) {
      .arrow-btn:before, .arrow-btn:after {
        font-size: 3.22222rem; } }
    @media (min-width: 1400px) {
      .arrow-btn:before, .arrow-btn:after {
        font-size: 2.9rem; } }
    @media (min-width: 992px) {
      .arrow-btn:before, .arrow-btn:after {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .arrow-btn:before, .arrow-btn:after {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .arrow-btn:before, .arrow-btn:after {
        width: 3rem; } }
    @media (min-width: 992px) {
      .arrow-btn:before, .arrow-btn:after {
        height: 3.75rem; } }
    @media (min-width: 1200px) {
      .arrow-btn:before, .arrow-btn:after {
        height: 3.33333rem; } }
    @media (min-width: 1400px) {
      .arrow-btn:before, .arrow-btn:after {
        height: 3rem; } }
    @media (min-width: 992px) {
      .arrow-btn:before, .arrow-btn:after {
        margin-left: 1.25rem; } }
    @media (min-width: 1200px) {
      .arrow-btn:before, .arrow-btn:after {
        margin-left: 1.11111rem; } }
    @media (min-width: 1400px) {
      .arrow-btn:before, .arrow-btn:after {
        margin-left: 1rem; } }
  .arrow-btn:before {
    content: "\e90d";
    margin-left: 0;
    margin-right: 10px;
    display: none; }
    @media (min-width: 992px) {
      .arrow-btn:before {
        margin-right: 1.25rem; } }
    @media (min-width: 1200px) {
      .arrow-btn:before {
        margin-right: 1.11111rem; } }
    @media (min-width: 1400px) {
      .arrow-btn:before {
        margin-right: 1rem; } }
  .arrow-btn.back {
    padding-top: 2rem;
    transform: translateY(70px);
    position: relative;
    z-index: 8; }
    @media (min-width: 992px) {
      .arrow-btn.back {
        transform: translateY(8.5rem); } }
    .arrow-btn.back:after {
      display: none; }
    .arrow-btn.back:before {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
  .arrow-btn:hover {
    color: #fff; }
    .arrow-btn:hover:after, .arrow-btn:hover:before {
      background: #fff;
      color: #000; }
  .arrow-btn.back-black {
    position: relative;
    z-index: 8;
    color: #000; }
    .arrow-btn.back-black:after {
      display: none; }
    .arrow-btn.back-black:before {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      color: #000;
      border-color: #000; }
    .arrow-btn.back-black:hover, .arrow-btn.back-black:focus {
      color: #000; }
      .arrow-btn.back-black:hover:before, .arrow-btn.back-black:focus:before {
        background-color: #000;
        border-color: #000;
        color: #fff; }
  .arrow-btn:active {
    text-decoration: none; }
    .arrow-btn:active:after, .arrow-btn:active:before {
      color: #f73c37; }

.FormButton {
  outline: none !important;
  border-color: #fff !important; }

.btn-primary {
  background: #fff;
  color: #f73c37; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #efefef;
    color: #f73c37;
    font-weight: 900 !important; }
  .btn-primary:active {
    background: #f73c37;
    color: #fff; }

.btn-default {
  background: #b7b7b7;
  color: #000; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #aaaaaa;
    color: #000; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem;
  background-color: #000;
  position: relative; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #f73c37;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "Zirkel", sans-serif !important; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 1.5rem;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #f73c37;
        position: absolute;
        top: 6px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #cecece; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #cecece; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Zirkel", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.text-underline {
  text-decoration: underline; }

.btn-prize,
.prize-form-submit input[type=submit],
.prize-form-file label {
  padding: 0rem 9rem;
  min-height: 40px;
  font-size: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700 !important;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 768px) {
    .btn-prize,
    .prize-form-submit input[type=submit],
    .prize-form-file label {
      font-size: 2rem;
      min-height: 4rem; } }

.btn-pink {
  background-color: #f677ba; }
  .btn-pink:hover, .btn-pink:focus {
    background-color: #f45fae; }

.btn-orange {
  background-color: #ffa200; }
  .btn-orange:hover, .btn-orange:focus {
    background-color: #e69200; }

.btn-purple {
  background-color: #9a18e0; }
  .btn-purple:hover, .btn-purple:focus {
    background-color: #8a16c9; }

.prize-h2 {
  font-size: 5.4rem;
  font-weight: 400;
  font-style: italic;
  letter-spacing: normal;
  line-height: 1.08;
  text-transform: uppercase;
  margin: 0;
  padding: 0; }

.prize-h2-text h2 {
  font-size: 5.4rem;
  font-weight: 400;
  font-style: italic;
  letter-spacing: normal;
  line-height: 1.08;
  text-transform: uppercase;
  margin: 0;
  padding: 0; }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 14px;
  font-weight: 500; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #ffffff;
  background: transparent;
  color: #fff;
  font-weight: 900;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 10rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #fff; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #fff; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #fff; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #fff; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0 !important;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    color: #fff; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    border: 1px solid #fff; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #f73c37;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #f73c37;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #b7b7b7;
  color: #000; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 2px solid #fff !important;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: rgba(255, 255, 255, 0.1); }
  50% {
    background: #f73c37; }
  100% {
    background: rgba(255, 255, 255, 0.1); } }

@-moz-keyframes blink {
  0% {
    background: rgba(255, 255, 255, 0.1); }
  50% {
    background: #f73c37; }
  100% {
    background: rgba(255, 255, 255, 0.1); } }

@-ms-keyframes blink {
  0% {
    background: rgba(255, 255, 255, 0.1); }
  50% {
    background: #f73c37; }
  100% {
    background: rgba(255, 255, 255, 0.1); } }

@keyframes blink {
  0% {
    background: rgba(255, 255, 255, 0.1); }
  50% {
    background: #f73c37; }
  100% {
    background: rgba(255, 255, 255, 0.1); } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.EditingFormErrorLabel {
  display: none; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #f73c37;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #f73c37; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #f73c37;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #f73c37; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.form {
  padding-top: 5px; }
  @media (min-width: 1200px) {
    .form {
      padding-top: .5rem; } }

.form-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 -10px; }
  @media (min-width: 1200px) {
    .form-row {
      margin: 0 -1rem; } }
  .form-row.btn-row {
    padding-top: 25px;
    margin: 0 -5px;
    -webkit-box-pack: flex-end;
    -moz-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
    @media (min-width: 1200px) {
      .form-row.btn-row {
        padding-top: 2.5rem;
        margin: 0 -.5rem; } }

.row-form [class^="col"] {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 1200px) {
    .row-form [class^="col"] {
      padding-left: 1rem;
      padding-right: 1rem; } }

.form-50 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .form-50 {
      width: 50%; } }
  @media (min-width: 1200px) {
    .form-50 {
      padding-left: 1rem;
      padding-right: 1rem; } }

.form-100 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 1200px) {
    .form-100 {
      padding-left: 1rem;
      padding-right: 1rem; } }

.form-container {
  padding: 15px 20px 30px;
  background: #f73c37;
  max-width: 500px;
  color: #fff; }
  @media (min-width: 1200px) {
    .form-container {
      max-width: 50rem;
      padding: 1.5rem 2rem 3rem; } }
  .form-container.newsletter-box {
    padding: 30px 20px 35px;
    margin: auto;
    text-align: center; }
    @media (min-width: 1200px) {
      .form-container.newsletter-box {
        padding: 3rem 2rem 3.5rem; } }
    .form-container.newsletter-box h3 {
      margin-top: 0;
      margin-bottom: 0.5rem; }

.newsletter-row {
  padding: 2.5rem 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  flex-direction: column; }
  @media (min-width: 545px) {
    .newsletter-row {
      flex-direction: row; } }
  .newsletter-row .ErrorLabel {
    color: #000; }
  .newsletter-row input {
    height: 50px; }
    @media (min-width: 992px) {
      .newsletter-row input {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .newsletter-row input {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .newsletter-row input {
        height: 5rem; } }
  .newsletter-row-input {
    padding: 0 5px; }
    @media (min-width: 1200px) {
      .newsletter-row-input {
        padding: 0 0.5rem; } }
    .newsletter-row-input input {
      width: 220px;
      box-sizing: border-box; }
      @media (min-width: 1200px) {
        .newsletter-row-input input {
          width: 22rem; } }
  .newsletter-row-btn {
    max-width: 110px;
    padding: 0 5px;
    margin-top: 10px; }
    @media (min-width: 545px) {
      .newsletter-row-btn {
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .newsletter-row-btn {
        max-width: 11rem; } }
    .newsletter-row-btn .btn {
      font-family: 'Zirkel';
      padding: 1.5rem 20px; }
      @media (min-width: 1200px) {
        .newsletter-row-btn .btn {
          padding: 1.5rem 2rem; } }
  @media (min-width: 320px) and (max-width: 544px) {
    .newsletter-row * {
      width: 100% !important;
      max-width: 100% !important; } }

.iframe-newsletter {
  width: 100%;
  height: 350px;
  border: none; }
  @media (min-width: 545px) {
    .iframe-newsletter {
      height: 300px; } }

.nwl-box .ErrorLabel {
  color: #000;
  display: block;
  transform: translateY(10px);
  font-size: 14px; }
  @media (min-width: 992px) {
    .nwl-box .ErrorLabel {
      font-size: 1.8rem; } }

.nwl-title {
  color: #000;
  display: block;
  font-size: 14px;
  margin: 10px 0;
  font-weight: 900; }
  @media (min-width: 992px) {
    .nwl-title {
      margin: 1rem 0;
      font-size: 1.8rem; } }

.newsletter-text {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700; }
  @media (min-width: 992px) {
    .newsletter-text {
      max-width: 30rem; } }
  @media (min-width: 992px) {
    .newsletter-text {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .newsletter-text {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .newsletter-text {
      font-size: 1.4rem; } }
  .newsletter-text a {
    font-weight: 700 !important;
    text-decoration: underline; }

.editing-form-control-nested-control {
  position: relative; }
  .editing-form-control-nested-control:after {
    content: "\e921";
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    display: none;
    font-size: 8px;
    color: #f73c37;
    background: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-weight: 900;
    line-height: 1; }
  .editing-form-control-nested-control.Error:after {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }

.form-row .placeholder label {
  height: 10px; }
  @media (min-width: 1200px) {
    .form-row .placeholder label {
      height: 1rem; } }

@media (min-width: 768px) {
  .gallery-container {
    margin: 0 -2rem; } }

.gallery-photo {
  width: 100%; }
  .gallery-photo-item {
    position: relative;
    width: 100%;
    display: block; }
    .gallery-photo-item img {
      width: 100%; }

.gallery-preview {
  width: 100%;
  margin-top: 15px; }
  .gallery-preview .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    font-size: 0;
    background: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
    outline: 0;
    z-index: 22;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 320px) {
      .gallery-preview .slick-arrow {
        width: 8rem; } }
    @media (min-width: 480px) {
      .gallery-preview .slick-arrow {
        width: 7.27273rem; } }
    @media (min-width: 545px) {
      .gallery-preview .slick-arrow {
        width: 6.66667rem; } }
    @media (min-width: 768px) {
      .gallery-preview .slick-arrow {
        width: 5.33333rem; } }
    @media (min-width: 992px) {
      .gallery-preview .slick-arrow {
        width: 5rem; } }
    @media (min-width: 320px) {
      .gallery-preview .slick-arrow {
        height: 8rem; } }
    @media (min-width: 480px) {
      .gallery-preview .slick-arrow {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .gallery-preview .slick-arrow {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .gallery-preview .slick-arrow {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .gallery-preview .slick-arrow {
        height: 5rem; } }
    @media (min-width: 320px) {
      .gallery-preview .slick-arrow {
        border-width: 0.52rem; } }
    @media (min-width: 480px) {
      .gallery-preview .slick-arrow {
        border-width: 0.47273rem; } }
    @media (min-width: 545px) {
      .gallery-preview .slick-arrow {
        border-width: 0.43333rem; } }
    @media (min-width: 768px) {
      .gallery-preview .slick-arrow {
        border-width: 0.34667rem; } }
    @media (min-width: 992px) {
      .gallery-preview .slick-arrow {
        border-width: 0.325rem; } }
    @media (min-width: 1200px) {
      .gallery-preview .slick-arrow {
        border-width: 0.3rem; } }
    .gallery-preview .slick-arrow:before {
      color: #fff;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 700;
      transform: translate(-50%, -50%); }
      @media (min-width: 320px) {
        .gallery-preview .slick-arrow:before {
          font-size: 8rem; } }
      @media (min-width: 480px) {
        .gallery-preview .slick-arrow:before {
          font-size: 7.27273rem; } }
      @media (min-width: 545px) {
        .gallery-preview .slick-arrow:before {
          font-size: 6.66667rem; } }
      @media (min-width: 768px) {
        .gallery-preview .slick-arrow:before {
          font-size: 5.33333rem; } }
      @media (min-width: 992px) {
        .gallery-preview .slick-arrow:before {
          font-size: 5rem; } }
    .gallery-preview .slick-arrow.slick-prev {
      left: 2rem;
      transform: rotate(180deg); }
      .gallery-preview .slick-arrow.slick-prev:before {
        content: "\e908"; }
    .gallery-preview .slick-arrow.slick-next {
      right: 2rem; }
      .gallery-preview .slick-arrow.slick-next:before {
        content: "\e908"; }
    .gallery-preview .slick-arrow:hover {
      background: #fff; }
      .gallery-preview .slick-arrow:hover:before {
        color: #000; }
    .gallery-preview .slick-arrow:active {
      background: #fff; }
      .gallery-preview .slick-arrow:active:before {
        color: #f73c37; }
  .gallery-preview .slick-track {
    margin: 0 -7.5px; }
  .gallery-preview .slick-disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    background: transparent !important; }
    .gallery-preview .slick-disabled:before {
      color: #fff !important; }
  .gallery-preview-item {
    position: relative;
    max-width: 260px;
    height: 20rem;
    display: block;
    margin: 0 7.5px; }
    @media (min-width: 1200px) {
      .gallery-preview-item {
        max-width: 26rem; } }
    .gallery-preview-item-bgr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important; }

.fancybox-image-wrap {
  border: 10px solid #000 !important; }

.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  height: 50px !important;
  width: 50px !important;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.25s;
  outline: 0 !important; }

.fancybox-arrow::after {
  content: "";
  position: absolute;
  top: 28px;
  width: 44px;
  height: 44px;
  background-color: none !important;
  border: 4px solid #fff !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-size: 24px 24px; }

.card-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  margin: auto;
  position: relative; }
  .card-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: #ffd100; }
    @media (min-width: 992px) {
      .card-item:after {
        height: 1.25rem; } }
    @media (min-width: 1200px) {
      .card-item:after {
        height: 1.11111rem; } }
    @media (min-width: 1400px) {
      .card-item:after {
        height: 1rem; } }
  .card-item-bgr {
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; }
    .card-item-bgr:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }
  .card-item-content {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px 20px; }
    @media (min-width: 768px) {
      .card-item-content {
        padding: 20px 25px 20px; } }
    @media (min-width: 992px) {
      .card-item-content {
        padding: 2rem 2rem 2.3rem; } }
    .card-item-content-title {
      font-weight: 900;
      color: #fff; }
      @media (min-width: 320px) {
        .card-item-content-title {
          font-size: 4.8rem; } }
      @media (min-width: 480px) {
        .card-item-content-title {
          font-size: 4.36364rem; } }
      @media (min-width: 545px) {
        .card-item-content-title {
          font-size: 4rem; } }
      @media (min-width: 768px) {
        .card-item-content-title {
          font-size: 3.4rem; } }
      @media (min-width: 320px) {
        .card-item-content-title {
          line-height: 4.8rem; } }
      @media (min-width: 480px) {
        .card-item-content-title {
          line-height: 4.36364rem; } }
      @media (min-width: 545px) {
        .card-item-content-title {
          line-height: 4rem; } }
      @media (min-width: 768px) {
        .card-item-content-title {
          line-height: 3.4rem; } }
    .card-item-content-subtitle {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 5px; }
      @media (min-width: 992px) {
        .card-item-content-subtitle {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .card-item-content-subtitle {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .card-item-content-subtitle {
          font-size: 1.6rem; } }
      @media (min-width: 992px) {
        .card-item-content-subtitle {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .card-item-content-subtitle {
          line-height: 2.22222rem; } }
      @media (min-width: 1400px) {
        .card-item-content-subtitle {
          line-height: 2rem; } }
      @media (min-width: 992px) {
        .card-item-content-subtitle {
          margin-top: 0.7rem; } }
  .card-item:hover .card-item-bgr, .card-item:focus .card-item-bgr {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  .card-item.prolyte:after {
    background: #44a1d6; }
  .card-item.exe:after {
    background: #F73C37; }
  .card-item.afi:after {
    background: #f73c37; }
  .card-item.milos:after {
    background: #ffd100; }
  .card-item.litec:after {
    background: #fff; }
  .card-item.jte:after {
    background: #1F21AB; }
  .card-item.tomcat:after {
    background: #00853D; }
  .card-item.mobiltech:after {
    background: #004494; }
  .card-item.xstage:after {
    background: #191919; }
  .card-item.xtruss:after {
    background: #191919; }
  .card-item.stagedex:after {
    background: #ff4d00; }
  .card-item.prolyft:after {
    background: #8cbf30; }
  .card-item.theme-red:after {
    background: #f73c37; }
  .card-item.middle-card, .card-item.big-card {
    height: 46rem;
    padding-bottom: 25px; }
    @media (min-width: 1200px) {
      .card-item.middle-card, .card-item.big-card {
        padding-bottom: 2.5rem; } }
    @media (min-width: 320px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        font-size: 7.2rem; } }
    @media (min-width: 480px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        font-size: 6.54545rem; } }
    @media (min-width: 545px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        font-size: 6rem; } }
    @media (min-width: 768px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        font-size: 4.8rem; } }
    @media (min-width: 992px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        font-size: 4.5rem; } }
    @media (min-width: 320px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        line-height: 7.2rem; } }
    @media (min-width: 480px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        line-height: 6.54545rem; } }
    @media (min-width: 545px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        line-height: 6rem; } }
    @media (min-width: 768px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        line-height: 4.8rem; } }
    @media (min-width: 992px) {
      .card-item.middle-card .card-item-content-title, .card-item.big-card .card-item-content-title {
        line-height: 4.5rem; } }
    @media (min-width: 320px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 3.4rem; } }
    @media (min-width: 480px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 3.09091rem; } }
    @media (min-width: 545px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 2.83333rem; } }
    @media (min-width: 768px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 2.26667rem; } }
    @media (min-width: 992px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 2.125rem; } }
    @media (min-width: 1200px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        font-size: 2.1rem; } }
    @media (min-width: 320px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 2.66667rem; } }
    @media (min-width: 992px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 2.5rem; } }
    @media (min-width: 1200px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 2.22222rem; } }
    @media (min-width: 1400px) {
      .card-item.middle-card .card-item-content-subtitle, .card-item.big-card .card-item-content-subtitle {
        line-height: 2.2rem; } }
  .card-item.big-card .card-item-content {
    padding-bottom: 20px; }
    @media (min-width: 992px) {
      .card-item.big-card .card-item-content {
        padding-bottom: 2rem; } }

.big-card {
  min-height: 46rem; }

.big-card-wrapper {
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.big-card-content {
  padding: 20px;
  border: 1px solid #7f7f7f;
  background: #000;
  color: #fff;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .big-card-content {
      padding: 25px; } }
  @media (min-width: 992px) {
    .big-card-content {
      padding: 2.5rem; } }
  .big-card-content-btns {
    padding-top: 5rem; }
    .big-card-content-btns .arrow-btn {
      padding-top: 10px;
      margin-right: 3rem; }
      @media (min-width: 1200px) {
        .big-card-content-btns .arrow-btn {
          padding-top: 1rem; } }
  .big-card-content .arrow-btn:after {
    opacity: 0 !important; }

a.press-item {
  text-decoration: none !important;
  font-weight: normal !important; }
  a.press-item:hover, a.press-item:active {
    font-weight: normal !important; }
    a.press-item:hover .press-item-content, a.press-item:active .press-item-content {
      font-weight: 400 !important; }

.press-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 0 1 auto;
  text-decoration: none !important; }
  .press-item-image {
    width: 100%;
    padding-top: 73.3%;
    position: relative;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .press-item-image {
        margin-bottom: 3.75rem; } }
    @media (min-width: 1200px) {
      .press-item-image {
        margin-bottom: 3.33333rem; } }
    @media (min-width: 1400px) {
      .press-item-image {
        margin-bottom: 3rem; } }
    .press-item-image-bgr {
      mix-blend-mode: overlay;
      -webkit-filter: grayscale(100%) contrast(100%);
      filter: grayscale(100%) contrast(100%);
      transition: all ease-in-out 250ms;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important; }
    .press-item-image:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 10px; }
      @media (min-width: 992px) {
        .press-item-image:after {
          height: 1.25rem; } }
      @media (min-width: 1200px) {
        .press-item-image:after {
          height: 1.11111rem; } }
      @media (min-width: 1400px) {
        .press-item-image:after {
          height: 1rem; } }
      @media (min-width: 992px) {
        .press-item-image:after {
          bottom: -3.75rem; } }
      @media (min-width: 1200px) {
        .press-item-image:after {
          bottom: -3.33333rem; } }
      @media (min-width: 1400px) {
        .press-item-image:after {
          bottom: -3rem; } }
  .press-item-content {
    background: #000;
    padding: 10px 20px 40px;
    border: 1px solid #7f7f7f;
    height: 100%;
    color: #fff;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    flex: 1 1 auto; }
    @media (min-width: 992px) {
      .press-item-content {
        padding: 1.6rem 2rem 4rem; } }
    .press-item-content-title {
      font-weight: 900;
      color: #fff;
      padding-bottom: 15px;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 2px solid #7f7f7f; }
      @media (min-width: 320px) {
        .press-item-content-title {
          font-size: 3.4rem; } }
      @media (min-width: 480px) {
        .press-item-content-title {
          font-size: 3.09091rem; } }
      @media (min-width: 545px) {
        .press-item-content-title {
          font-size: 2.83333rem; } }
      @media (min-width: 768px) {
        .press-item-content-title {
          font-size: 2.26667rem; } }
      @media (min-width: 992px) {
        .press-item-content-title {
          font-size: 2.2rem; } }
      @media (min-width: 320px) {
        .press-item-content-title {
          line-height: 3.6rem; } }
      @media (min-width: 480px) {
        .press-item-content-title {
          line-height: 3.27273rem; } }
      @media (min-width: 545px) {
        .press-item-content-title {
          line-height: 3rem; } }
      @media (min-width: 768px) {
        .press-item-content-title {
          line-height: 2.4rem; } }
      @media (min-width: 1200px) {
        .press-item-content-title {
          padding-bottom: 1.5rem;
          margin-bottom: 1rem; } }
    .press-item-content-subtitle {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px; }
      @media (min-width: 992px) {
        .press-item-content-subtitle {
          padding-bottom: 0.4rem; } }
      @media (min-width: 992px) {
        .press-item-content-subtitle {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .press-item-content-subtitle {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .press-item-content-subtitle {
          font-size: 1.6rem; } }
      @media (min-width: 992px) {
        .press-item-content-subtitle {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .press-item-content-subtitle {
          line-height: 2.22222rem; } }
      @media (min-width: 1400px) {
        .press-item-content-subtitle {
          line-height: 2rem; } }
  .press-item:hover .press-item-image-bgr {
    mix-blend-mode: initial;
    -webkit-filter: none;
    filter: none; }
  .press-item.prolyte .press-item-image {
    background: #44a1d6; }
    .press-item.prolyte .press-item-image:after {
      background: #44a1d6; }
  .press-item.exe .press-item-image {
    background: #F73C37; }
    .press-item.exe .press-item-image:after {
      background: #F73C37; }
  .press-item.afi .press-item-image {
    background: #f73c37; }
    .press-item.afi .press-item-image:after {
      background: #f73c37; }
  .press-item.milos .press-item-image {
    background: #ffd100; }
    .press-item.milos .press-item-image:after {
      background: #ffd100; }
  .press-item.jte .press-item-image {
    background: #1F21AB; }
    .press-item.jte .press-item-image:after {
      background: #1F21AB; }
  .press-item.tomcat .press-item-image {
    background: #00853D; }
    .press-item.tomcat .press-item-image:after {
      background: #00853D; }
  .press-item.xstage .press-item-image {
    background: #191919; }
    .press-item.xstage .press-item-image:after {
      background: #191919; }
  .press-item.xtruss .press-item-image {
    background: #191919; }
    .press-item.xtruss .press-item-image:after {
      background: #191919; }
  .press-item.litec .press-item-image {
    background: #c5c5c5; }
    .press-item.litec .press-item-image:after {
      background: #fff; }
  .press-item.mobiltech .press-item-image {
    background: #004494; }
    .press-item.mobiltech .press-item-image:after {
      background: #004494; }
  .press-item.stagedex .press-item-image {
    background: #ff4d00; }
    .press-item.stagedex .press-item-image:after {
      background: #ff4d00; }
  .press-item.prolyft .press-item-image {
    background: #8cbf30; }
    .press-item.prolyft .press-item-image:after {
      background: #8cbf30; }
  .press-item.hp-press .press-item-image {
    height: auto;
    margin-bottom: 10px; }
    @media (min-width: 320px) and (max-width: 767px) {
      .press-item.hp-press .press-item-image {
        padding-top: 0; } }
    @media (min-width: 768px) {
      .press-item.hp-press .press-item-image {
        margin-bottom: 30px; } }
    @media (min-width: 992px) {
      .press-item.hp-press .press-item-image {
        margin-bottom: 3.75rem; } }
    @media (min-width: 1200px) {
      .press-item.hp-press .press-item-image {
        margin-bottom: 3.33333rem; } }
    @media (min-width: 1400px) {
      .press-item.hp-press .press-item-image {
        margin-bottom: 3rem; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .press-item.hp-press .press-item-image:after {
        bottom: auto; } }
    .press-item.hp-press .press-item-image .press-item-image-bgr {
      display: none; }
      @media (min-width: 768px) {
        .press-item.hp-press .press-item-image .press-item-image-bgr {
          width: 101%;
          display: block; } }
  .press-item.hp-press .press-item-content {
    background-color: transparent; }
    @media (min-width: 992px) {
      .press-item.hp-press .press-item-content {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .press-item.hp-press .press-item-content {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .press-item.hp-press .press-item-content {
        font-size: 1.4rem; } }
    @media (min-width: 992px) {
      .press-item.hp-press .press-item-content {
        padding-bottom: 2.5rem; } }
    @media (min-width: 1200px) {
      .press-item.hp-press .press-item-content {
        padding-bottom: 2.22222rem; } }
    @media (min-width: 1400px) {
      .press-item.hp-press .press-item-content {
        padding-bottom: 2rem; } }
    .press-item.hp-press .press-item-content-title {
      margin-top: 5px;
      font-size: 22px;
      line-height: 1.4; }
      @media (min-width: 992px) {
        .press-item.hp-press .press-item-content-title {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        .press-item.hp-press .press-item-content-title {
          font-size: 2.44444rem; } }
      @media (min-width: 1400px) {
        .press-item.hp-press .press-item-content-title {
          font-size: 2.2rem; } }
      @media (min-width: 992px) {
        .press-item.hp-press .press-item-content-title {
          margin-top: 0.625rem; } }
      @media (min-width: 1200px) {
        .press-item.hp-press .press-item-content-title {
          margin-top: 0.55556rem; } }
      @media (min-width: 1400px) {
        .press-item.hp-press .press-item-content-title {
          margin-top: 0.5rem; } }
    .press-item.hp-press .press-item-content-subtitle {
      display: none; }

.logo-item {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background: #fff;
  padding: 20px 20px 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .logo-item {
      padding: 2rem 2rem 2.5rem; } }
  .logo-item-content {
    color: #000; }
    .logo-item-content-title {
      padding-top: 10px;
      font-weight: 900;
      padding-bottom: 1.5rem;
      color: #000; }
      @media (min-width: 1200px) {
        .logo-item-content-title {
          padding-top: 1rem; } }
    .logo-item-content a {
      color: #000;
      display: block;
      text-decoration: underline; }
      .logo-item-content a.arrow-btn {
        display: inline-flex; }
      .logo-item-content a:after {
        border-color: #000; }
      .logo-item-content a:hover {
        color: #000; }
        .logo-item-content a:hover:after {
          background: #000;
          color: #fff; }
      .logo-item-content a:active:after {
        color: #f73c37; }
  .logo-item img {
    width: 200px; }
    @media (min-width: 1200px) {
      .logo-item img {
        width: 20rem; } }
  .logo-item.large-card {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .logo-item.large-card .logo-item-image {
      width: 100%;
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        .logo-item.large-card .logo-item-image {
          width: 35%;
          margin-bottom: 0px;
          padding-right: 4rem; } }
    .logo-item.large-card .logo-item-content {
      width: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
      @media (min-width: 768px) {
        .logo-item.large-card .logo-item-content {
          width: 65%; } }
      .logo-item.large-card .logo-item-content-ref {
        margin-top: 3rem; }
        @media (min-width: 768px) {
          .logo-item.large-card .logo-item-content-ref {
            text-align: right; } }
  .logo-item.normal-card .logo-item-image {
    margin-bottom: 20px; }
  .logo-item.normal-card .logo-item-content-ref {
    margin-top: 3rem; }
  .logo-item.exe {
    background: #F73C37; }
    .logo-item.exe .logo-item-content {
      color: #fff; }
      .logo-item.exe .logo-item-content-title {
        color: #fff; }
      .logo-item.exe .logo-item-content a {
        color: #fff; }
        .logo-item.exe .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.exe .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.exe .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.afi {
    background: #f73c37; }
    .logo-item.afi .logo-item-content {
      color: #fff; }
      .logo-item.afi .logo-item-content-title {
        color: #fff; }
      .logo-item.afi .logo-item-content a {
        color: #fff; }
        .logo-item.afi .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.afi .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.afi .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.prolyte {
    background: #44a1d6; }
    .logo-item.prolyte .logo-item-content {
      color: #fff; }
      .logo-item.prolyte .logo-item-content-title {
        color: #fff; }
      .logo-item.prolyte .logo-item-content a {
        color: #fff; }
        .logo-item.prolyte .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.prolyte .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.prolyte .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.milos {
    background: #ffd100; }
  .logo-item.jte {
    background: #1F21AB; }
    .logo-item.jte .logo-item-content {
      color: #fff; }
      .logo-item.jte .logo-item-content-title {
        color: #fff; }
      .logo-item.jte .logo-item-content a {
        color: #fff; }
        .logo-item.jte .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.jte .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.jte .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.tomcat, .logo-item.mobiltech {
    background: #004494; }
    .logo-item.tomcat .logo-item-content, .logo-item.mobiltech .logo-item-content {
      color: #fff; }
      .logo-item.tomcat .logo-item-content-title, .logo-item.mobiltech .logo-item-content-title {
        color: #fff; }
      .logo-item.tomcat .logo-item-content a, .logo-item.mobiltech .logo-item-content a {
        color: #fff; }
        .logo-item.tomcat .logo-item-content a:after, .logo-item.mobiltech .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.tomcat .logo-item-content a:hover:after, .logo-item.mobiltech .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.tomcat .logo-item-content a:active:after, .logo-item.mobiltech .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.tomcat {
    background: #00853D; }
  .logo-item.xstage, .logo-item.xtruss {
    background: #191919; }
    .logo-item.xstage .logo-item-content, .logo-item.xtruss .logo-item-content {
      color: #fff; }
      .logo-item.xstage .logo-item-content-title, .logo-item.xtruss .logo-item-content-title {
        color: #fff; }
      .logo-item.xstage .logo-item-content a, .logo-item.xtruss .logo-item-content a {
        color: #fff; }
        .logo-item.xstage .logo-item-content a:after, .logo-item.xtruss .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.xstage .logo-item-content a:hover:after, .logo-item.xtruss .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.xstage .logo-item-content a:active:after, .logo-item.xtruss .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.xstage {
    background: #191919; }
  .logo-item.litec {
    background: #fff; }
    .logo-item.litec .logo-item-content {
      color: #fff; }
      .logo-item.litec .logo-item-content-title {
        color: #fff; }
      .logo-item.litec .logo-item-content a {
        color: #fff; }
        .logo-item.litec .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.litec .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.litec .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.stagedex {
    background: #ff4d00; }
    .logo-item.stagedex .logo-item-content {
      color: #fff; }
      .logo-item.stagedex .logo-item-content-title {
        color: #fff; }
      .logo-item.stagedex .logo-item-content a {
        color: #fff; }
        .logo-item.stagedex .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.stagedex .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.stagedex .logo-item-content a:active:after {
          color: #f73c37; }
  .logo-item.prolyft {
    background: #8cbf30; }
    .logo-item.prolyft .logo-item-content {
      color: #fff; }
      .logo-item.prolyft .logo-item-content-title {
        color: #fff; }
      .logo-item.prolyft .logo-item-content a {
        color: #fff; }
        .logo-item.prolyft .logo-item-content a:after {
          border-color: #fff; }
        .logo-item.prolyft .logo-item-content a:hover:after {
          background: #fff;
          color: #000; }
        .logo-item.prolyft .logo-item-content a:active:after {
          color: #f73c37; }

.social-media-card {
  width: 100%;
  height: 100%;
  padding: 22px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background: #000; }
  @media (min-width: 992px) {
    .social-media-card {
      padding: 2.75rem; } }
  @media (min-width: 1200px) {
    .social-media-card {
      padding: 2.44444rem; } }
  @media (min-width: 1400px) {
    .social-media-card {
      padding: 2.2rem; } }
  .social-media-card-content {
    color: #fff;
    font-weight: 900; }
    .social-media-card-content h4 {
      padding-bottom: 3rem; }
  .social-media-card-join {
    padding-top: 2.5rem;
    text-align: right; }
    .social-media-card-join .arrow-btn {
      font-size: 18px; }
      @media (min-width: 992px) {
        .social-media-card-join .arrow-btn {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .social-media-card-join .arrow-btn {
          font-size: 2rem; } }
      @media (min-width: 1400px) {
        .social-media-card-join .arrow-btn {
          font-size: 1.8rem; } }
  .social-media-card.s-facebook {
    background: #4469b0; }
  .social-media-card.s-linkedin {
    background: #1178b3; }
  .social-media-card.s-twitter {
    background: #1da1f2; }
  .social-media-card.s-instagram {
    background: #8a3ab9; }
  .social-media-card.s-youtube {
    background: #fc0d1b; }
  .social-media-card.s-a4i {
    background: #f73c37; }

.row.row-contact {
  margin: 0 -10px; }
  @media (min-width: 1200px) {
    .row.row-contact {
      margin: 0 -1rem; } }
  .row.row-contact [class^="col-"] {
    padding: 10px; }
    @media (min-width: 1200px) {
      .row.row-contact [class^="col-"] {
        padding: 1rem; } }
  @media (min-width: 992px) {
    .row.row-contact .arrow-btn:after {
      margin-left: 1.5rem; } }
  @media (min-width: 1200px) {
    .row.row-contact .arrow-btn:after {
      margin-left: 1.33333rem; } }
  @media (min-width: 1400px) {
    .row.row-contact .arrow-btn:after {
      margin-left: 1.2rem; } }

.contact-card {
  padding: 20px 20px 20px;
  border: 1px solid #979797;
  background: #000;
  height: 100%;
  min-height: 27rem;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .contact-card {
      padding: 2rem 2.5rem 2.5rem; } }
  .contact-card-content {
    color: #fff; }
    @media (min-width: 320px) {
      .contact-card-content {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      .contact-card-content {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .contact-card-content {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .contact-card-content {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .contact-card-content {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .contact-card-content {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .contact-card-content {
        font-size: 1.4rem; } }
    @media (min-width: 320px) {
      .contact-card-content {
        line-height: 3.6rem; } }
    @media (min-width: 480px) {
      .contact-card-content {
        line-height: 3.27273rem; } }
    @media (min-width: 545px) {
      .contact-card-content {
        line-height: 3rem; } }
    @media (min-width: 768px) {
      .contact-card-content {
        line-height: 2.4rem; } }
    @media (min-width: 992px) {
      .contact-card-content {
        line-height: 2.25rem; } }
    @media (min-width: 1200px) {
      .contact-card-content {
        line-height: 2rem; } }
    @media (min-width: 1400px) {
      .contact-card-content {
        line-height: 1.8rem; } }
    .contact-card-content-row {
      padding: 2.5rem 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -10px; }
      .contact-card-content-row-column {
        width: 100%;
        padding: 0 10px; }
        @media (min-width: 992px) {
          .contact-card-content-row-column {
            width: 50%; } }
  .contact-card-refs {
    text-align: right; }
    .contact-card-refs .arrow-btn {
      padding-top: 10px;
      margin-left: 3rem; }
  .contact-card-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end; }
    @media (min-width: 1200px) {
      .contact-card-row {
        -webkit-flex-wrap: nowrap;
        -moz-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; } }
    .contact-card-row .contact-card-brands {
      color: #fff;
      margin: 0 -10px;
      max-width: 320px; }
      @media (min-width: 1200px) {
        .contact-card-row .contact-card-brands {
          max-width: 34rem; } }
      .contact-card-row .contact-card-brands-title {
        padding-left: 10px; }
      .contact-card-row .contact-card-brands .brand-logo {
        display: inline-block;
        padding: 10px;
        padding-top: 0 !important; }
        @media (min-width: 992px) {
          .contact-card-row .contact-card-brands .brand-logo {
            padding: 1.25rem; } }
        @media (min-width: 1200px) {
          .contact-card-row .contact-card-brands .brand-logo {
            padding: 1.11111rem; } }
        @media (min-width: 1400px) {
          .contact-card-row .contact-card-brands .brand-logo {
            padding: 1rem; } }
        .contact-card-row .contact-card-brands .brand-logo:before {
          font-size: 15px; }
          @media (min-width: 1200px) {
            .contact-card-row .contact-card-brands .brand-logo:before {
              font-size: 1.44444rem; } }
          @media (min-width: 1400px) {
            .contact-card-row .contact-card-brands .brand-logo:before {
              font-size: 1.3rem; } }
        .contact-card-row .contact-card-brands .brand-logo.ico-logo2:before {
          font-size: 27px; }
          @media (min-width: 1200px) {
            .contact-card-row .contact-card-brands .brand-logo.ico-logo2:before {
              font-size: 2.5rem; } }
        .contact-card-row .contact-card-brands .brand-logo.ico-prolyte:before {
          font-size: 42px;
          transform: translateY(22%);
          display: inline-block; }
          @media (min-width: 1200px) {
            .contact-card-row .contact-card-brands .brand-logo.ico-prolyte:before {
              font-size: 3.8rem;
              transform: translateY(25%); } }
        .contact-card-row .contact-card-brands .brand-logo.ico-xstage:before, .contact-card-row .contact-card-brands .brand-logo.ico-xtruss:before {
          font-size: 21px; }
          @media (min-width: 1200px) {
            .contact-card-row .contact-card-brands .brand-logo.ico-xstage:before, .contact-card-row .contact-card-brands .brand-logo.ico-xtruss:before {
              font-size: 1.9rem; } }
        .contact-card-row .contact-card-brands .brand-logo.ico-mobiltechlifts:before, .contact-card-row .contact-card-brands .brand-logo.ico-exe-technology:before {
          font-size: 23px; }
          @media (min-width: 1200px) {
            .contact-card-row .contact-card-brands .brand-logo.ico-mobiltechlifts:before, .contact-card-row .contact-card-brands .brand-logo.ico-exe-technology:before {
              font-size: 2.1rem; } }
    .contact-card-row .arrow-btn {
      padding-top: 10px; }
  .contact-card.wide-card {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
    .contact-card.wide-card .contact-card-content {
      width: 100%; }
      @media (min-width: 1200px) {
        .contact-card.wide-card .contact-card-content {
          width: 50%; } }
    .contact-card.wide-card .contact-card-row {
      width: 100%; }
      @media (min-width: 1200px) {
        .contact-card.wide-card .contact-card-row {
          width: 50%; } }

.breadcrumbs-separator {
  padding: 0 0.5rem;
  display: inline-block; }
  .breadcrumbs-separator:before {
    content: ">"; }
    @media (min-width: 320px) {
      .breadcrumbs-separator:before {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .breadcrumbs-separator:before {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .breadcrumbs-separator:before {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .breadcrumbs-separator:before {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .breadcrumbs-separator:before {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .breadcrumbs-separator:before {
        font-size: 1.8rem; } }
    @media (min-width: 320px) {
      .breadcrumbs-separator:before {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      .breadcrumbs-separator:before {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      .breadcrumbs-separator:before {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      .breadcrumbs-separator:before {
        line-height: 2.66667rem; } }
    @media (min-width: 992px) {
      .breadcrumbs-separator:before {
        line-height: 2.5rem; } }
    @media (min-width: 1200px) {
      .breadcrumbs-separator:before {
        line-height: 2.22222rem; } }
    @media (min-width: 1400px) {
      .breadcrumbs-separator:before {
        line-height: 2.2rem; } }

.breadcrumbs a {
  color: #fff; }
  @media (min-width: 320px) {
    .breadcrumbs a {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .breadcrumbs a {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .breadcrumbs a {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .breadcrumbs a {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .breadcrumbs a {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .breadcrumbs a {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    .breadcrumbs a {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    .breadcrumbs a {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    .breadcrumbs a {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    .breadcrumbs a {
      line-height: 2.66667rem; } }
  @media (min-width: 992px) {
    .breadcrumbs a {
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    .breadcrumbs a {
      line-height: 2.22222rem; } }
  @media (min-width: 1400px) {
    .breadcrumbs a {
      line-height: 2.2rem; } }
  .breadcrumbs a:hover, .breadcrumbs a:focus {
    color: #f73c37; }

.breadcrumbs span {
  color: #fff; }
  @media (min-width: 320px) {
    .breadcrumbs span {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .breadcrumbs span {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .breadcrumbs span {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .breadcrumbs span {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .breadcrumbs span {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .breadcrumbs span {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    .breadcrumbs span {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    .breadcrumbs span {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    .breadcrumbs span {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    .breadcrumbs span {
      line-height: 2.66667rem; } }
  @media (min-width: 992px) {
    .breadcrumbs span {
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    .breadcrumbs span {
      line-height: 2.22222rem; } }
  @media (min-width: 1400px) {
    .breadcrumbs span {
      line-height: 2.2rem; } }

.product-container {
  padding: 6rem 0 0;
  position: relative;
  z-index: 2; }
  @media (min-width: 768px) {
    .product-container {
      margin: 0 -20px; } }

.product-item {
  padding: 5rem 0; }
  @media (min-width: 1200px) {
    .product-item {
      padding: 0; } }
  .product-item-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex: 1 1 auto; }
    .product-item-row .product-item-column {
      width: 100%;
      height: 23rem;
      border-collapse: collapse;
      border: 1px solid #979797;
      margin: -0.5px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      @media (min-width: 545px) {
        .product-item-row .product-item-column {
          width: 50%; } }
      @media (min-width: 768px) {
        .product-item-row .product-item-column {
          width: 33.3333%; } }
      @media (min-width: 1200px) {
        .product-item-row .product-item-column {
          width: 15%; } }
      @media (min-width: 1200px) {
        .product-item-row .product-item-column:first-child, .product-item-row .product-item-column:nth-child(2) {
          width: 20%; } }
      .product-item-row .product-item-column:first-child {
        padding: 12px 20px; }
      .product-item-row .product-item-column:nth-child(2) {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center; }
      .product-item-row .product-item-column img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: center; }
      .product-item-row .product-item-column .product-ref {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding: 12px 12px 12px 20px;
        font-weight: 900;
        text-decoration: none !important; }
        @media (min-width: 320px) {
          .product-item-row .product-item-column .product-ref {
            font-size: 4.8rem; } }
        @media (min-width: 480px) {
          .product-item-row .product-item-column .product-ref {
            font-size: 4.36364rem; } }
        @media (min-width: 545px) {
          .product-item-row .product-item-column .product-ref {
            font-size: 4rem; } }
        @media (min-width: 768px) {
          .product-item-row .product-item-column .product-ref {
            font-size: 3.4rem; } }
        @media (min-width: 320px) {
          .product-item-row .product-item-column .product-ref {
            line-height: 5.6rem; } }
        @media (min-width: 480px) {
          .product-item-row .product-item-column .product-ref {
            line-height: 5.09091rem; } }
        @media (min-width: 545px) {
          .product-item-row .product-item-column .product-ref {
            line-height: 4.66667rem; } }
        @media (min-width: 768px) {
          .product-item-row .product-item-column .product-ref {
            line-height: 3.8rem; } }
        @media (min-width: 992px) {
          .product-item-row .product-item-column .product-ref {
            padding: 1.5rem; } }
        @media (min-width: 1200px) {
          .product-item-row .product-item-column .product-ref {
            padding: 1.33333rem; } }
        @media (min-width: 1400px) {
          .product-item-row .product-item-column .product-ref {
            padding: 1.2rem; } }
        @media (min-width: 992px) {
          .product-item-row .product-item-column .product-ref {
            padding-left: 2.5rem; } }
        @media (min-width: 1200px) {
          .product-item-row .product-item-column .product-ref {
            padding-left: 2.22222rem; } }
        @media (min-width: 1400px) {
          .product-item-row .product-item-column .product-ref {
            padding-left: 2rem; } }
        .product-item-row .product-item-column .product-ref span {
          display: block;
          -webkit-align-self: flex-end;
          -moz-align-self: flex-end;
          -ms-align-self: flex-end;
          -webkit-align-self: flex-end;
          align-self: flex-end; }
          .product-item-row .product-item-column .product-ref span:after {
            content: "\e908";
            width: 28px;
            height: 28px;
            font-size: 22px;
            margin-left: 15px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            border: 2px solid #fff;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            font-weight: 900;
            text-decoration: none !important; }
            @media (min-width: 992px) {
              .product-item-row .product-item-column .product-ref span:after {
                width: 3.5rem; } }
            @media (min-width: 1200px) {
              .product-item-row .product-item-column .product-ref span:after {
                width: 3.11111rem; } }
            @media (min-width: 1400px) {
              .product-item-row .product-item-column .product-ref span:after {
                width: 2.8rem; } }
            @media (min-width: 992px) {
              .product-item-row .product-item-column .product-ref span:after {
                height: 3.5rem; } }
            @media (min-width: 1200px) {
              .product-item-row .product-item-column .product-ref span:after {
                height: 3.11111rem; } }
            @media (min-width: 1400px) {
              .product-item-row .product-item-column .product-ref span:after {
                height: 2.8rem; } }
            @media (min-width: 1400px) {
              .product-item-row .product-item-column .product-ref span:after {
                font-size: 2.2rem; } }
        .product-item-row .product-item-column .product-ref:hover span:after {
          color: #000;
          background: #fff; }
        .product-item-row .product-item-column .product-ref:active span:after {
          color: #f73c37; }
        .product-item-row .product-item-column .product-ref.milos {
          background: #ffd100;
          color: #000; }
          .product-item-row .product-item-column .product-ref.milos span:after {
            border-color: #000; }
          .product-item-row .product-item-column .product-ref.milos:hover span:after {
            color: #fff;
            background: #000; }
          .product-item-row .product-item-column .product-ref.milos:active span:after {
            color: #f73c37; }
        .product-item-row .product-item-column .product-ref.tomcat {
          background: #00853D; }
        .product-item-row .product-item-column .product-ref.litec {
          background: #fff;
          color: #000; }
          .product-item-row .product-item-column .product-ref.litec span:after {
            border-color: #000; }
          .product-item-row .product-item-column .product-ref.litec:hover span:after {
            color: #fff;
            background: #000; }
          .product-item-row .product-item-column .product-ref.litec:active span:after {
            color: #f73c37; }
        .product-item-row .product-item-column .product-ref.jte {
          background: #1F21AB; }
          @media (min-width: 320px) {
            .product-item-row .product-item-column .product-ref.jte {
              font-size: 3.6rem; } }
          @media (min-width: 480px) {
            .product-item-row .product-item-column .product-ref.jte {
              font-size: 3.27273rem; } }
          @media (min-width: 545px) {
            .product-item-row .product-item-column .product-ref.jte {
              font-size: 3rem; } }
          @media (min-width: 768px) {
            .product-item-row .product-item-column .product-ref.jte {
              font-size: 2.4rem; } }
          @media (min-width: 320px) {
            .product-item-row .product-item-column .product-ref.jte {
              line-height: 4.4rem; } }
          @media (min-width: 480px) {
            .product-item-row .product-item-column .product-ref.jte {
              line-height: 4rem; } }
          @media (min-width: 545px) {
            .product-item-row .product-item-column .product-ref.jte {
              line-height: 3.66667rem; } }
          @media (min-width: 768px) {
            .product-item-row .product-item-column .product-ref.jte {
              line-height: 2.93333rem; } }
          @media (min-width: 992px) {
            .product-item-row .product-item-column .product-ref.jte {
              line-height: 2.8rem; } }
      .product-item-row .product-item-column.empty {
        display: none; }
        @media (min-width: 545px) {
          .product-item-row .product-item-column.empty {
            display: block; } }

.brand-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  max-width: 900px;
  flex: 1 1 auto;
  padding: 5rem 0; }
  @media (min-width: 545px) {
    .brand-item {
      padding: 0; } }
  .brand-item .brand-item-name,
  .brand-item .brand-item-products {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #979797;
    margin: -0.5px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 545px) {
      .brand-item .brand-item-name,
      .brand-item .brand-item-products {
        width: 66.6666%; } }
    @media (min-width: 545px) {
      .brand-item .brand-item-name:first-child,
      .brand-item .brand-item-products:first-child {
        width: 33.3333%; } }
  .brand-item .brand-item-name .milos-bgr {
    width: 100%;
    background: #ffd100;
    padding: 12px 20px; }
    .brand-item .brand-item-name .milos-bgr h3 {
      color: #000; }
  .brand-item .brand-item-name .tomcat-bgr {
    width: 100%;
    background: #00853D;
    padding: 12px 20px; }
  .brand-item .brand-item-name .litec-bgr {
    width: 100%;
    background: #fff;
    padding: 12px 20px; }
    .brand-item .brand-item-name .litec-bgr h3 {
      color: #000; }
  .brand-item .brand-item-name .jte-bgr {
    width: 100%;
    background: #1F21AB;
    padding: 12px 20px; }
  .brand-item .brand-item-products {
    display: block;
    border: none;
    margin: 0; }
    .brand-item .brand-item-products .brand-item-product {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      margin: -0.5px 0;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .brand-item .brand-item-products .brand-item-product-image {
        width: 100%;
        height: 23rem;
        border: 1px solid #979797;
        margin: -0.5px;
        overflow: hidden;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center; }
        @media (min-width: 545px) {
          .brand-item .brand-item-products .brand-item-product-image {
            width: 50%; } }
        .brand-item .brand-item-products .brand-item-product-image img {
          width: 100%;
          height: 100% !important;
          object-fit: cover; }
      .brand-item .brand-item-products .brand-item-product-name {
        width: 100%;
        height: 23rem;
        border: 1px solid #979797;
        margin: -0.5px; }
        @media (min-width: 545px) {
          .brand-item .brand-item-products .brand-item-product-name {
            width: 50%; } }
        .brand-item .brand-item-products .brand-item-product-name a {
          width: 100%;
          height: 100%;
          padding: 12px 20px;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: space-between;
          -moz-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between; }
          .brand-item .brand-item-products .brand-item-product-name a span {
            display: block;
            -webkit-align-self: flex-end;
            -moz-align-self: flex-end;
            -ms-align-self: flex-end;
            -webkit-align-self: flex-end;
            align-self: flex-end; }
            .brand-item .brand-item-products .brand-item-product-name a span:after {
              content: "\e908";
              width: 28px;
              height: 28px;
              font-size: 22px;
              margin-left: 15px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              border-radius: 50%;
              border: 2px solid #fff;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              -webkit-box-pack: center;
              -moz-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              font-weight: 900;
              text-decoration: none !important; }
              @media (min-width: 992px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  width: 3.5rem; } }
              @media (min-width: 1200px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  width: 3.11111rem; } }
              @media (min-width: 1400px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  width: 2.8rem; } }
              @media (min-width: 992px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  height: 3.5rem; } }
              @media (min-width: 1200px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  height: 3.11111rem; } }
              @media (min-width: 1400px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  height: 2.8rem; } }
              @media (min-width: 1400px) {
                .brand-item .brand-item-products .brand-item-product-name a span:after {
                  font-size: 2.2rem; } }
          .brand-item .brand-item-products .brand-item-product-name a:hover span:after {
            color: #000;
            background: #fff; }
          .brand-item .brand-item-products .brand-item-product-name a:active {
            text-decoration: none; }
            .brand-item .brand-item-products .brand-item-product-name a:active span:after {
              color: #f73c37; }

.products-wrapper,
.brands-wrapper {
  display: none; }
  .products-wrapper.active,
  .brands-wrapper.active {
    display: block; }

@media (min-width: 992px) {
  .media-space .row,
  .media-space .items-container {
    margin-right: -1.625rem; } }

@media (min-width: 1200px) {
  .media-space .row,
  .media-space .items-container {
    margin-right: -1.44444rem; } }

@media (min-width: 1400px) {
  .media-space .row,
  .media-space .items-container {
    margin-right: -1.3rem; } }

@media (min-width: 992px) {
  .media-space .row,
  .media-space .items-container {
    margin-left: -1.625rem; } }

@media (min-width: 1200px) {
  .media-space .row,
  .media-space .items-container {
    margin-left: -1.44444rem; } }

@media (min-width: 1400px) {
  .media-space .row,
  .media-space .items-container {
    margin-left: -1.3rem; } }

@media (min-width: 992px) {
  .media-space .row [class*="col-"],
  .media-space .items-container [class*="col-"] {
    padding: 1.625rem; } }

@media (min-width: 1200px) {
  .media-space .row [class*="col-"],
  .media-space .items-container [class*="col-"] {
    padding: 1.44444rem; } }

@media (min-width: 1400px) {
  .media-space .row [class*="col-"],
  .media-space .items-container [class*="col-"] {
    padding: 1.3rem; } }

.filter-row {
  padding-top: 6rem;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 18px; }
  @media (min-width: 992px) {
    .filter-row {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .filter-row {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .filter-row {
      font-size: 1.8rem; } }
  .filter-row .product-switch {
    display: inline-block;
    padding: 0 1rem;
    cursor: pointer;
    font-weight: 900;
    text-decoration: underline; }
    .filter-row .product-switch:hover, .filter-row .product-switch.active {
      color: #f73c37; }

.text-bold {
  font-weight: 700; }

.text-narrow {
  max-width: 720px;
  position: relative;
  z-index: 2; }
  @media (min-width: 1400px) {
    .text-narrow {
      max-width: 72rem; } }

.person-item {
  width: 100%; }
  .person-item-photo {
    position: relative;
    width: 100%;
    padding-bottom: 100%; }
    .person-item-photo-bgr {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important; }
  .person-item-name {
    padding-top: 1.5rem; }

.about-us-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-bottom: 5.5rem; }

.about-us-right {
  display: none;
  position: relative;
  z-index: 2; }
  @media (min-width: 992px) {
    .about-us-right {
      display: block;
      width: 30rem;
      margin-left: 2rem; } }
  @media (min-width: 992px) {
    .about-us-right .newsletter-box {
      margin-bottom: 3.75rem; } }
  @media (min-width: 1200px) {
    .about-us-right .newsletter-box {
      margin-bottom: 3.33333rem; } }
  @media (min-width: 1400px) {
    .about-us-right .newsletter-box {
      margin-bottom: 3rem; } }
  .about-us-right .newsletter-row {
    display: block; }
    .about-us-right .newsletter-row-btn, .about-us-right .newsletter-row-input {
      width: 100%;
      max-width: none; }
      .about-us-right .newsletter-row-btn input,
      .about-us-right .newsletter-row-btn .btn, .about-us-right .newsletter-row-input input,
      .about-us-right .newsletter-row-input .btn {
        width: 100%;
        text-align: center; }
    .about-us-right .newsletter-row-btn {
      padding: 1.2rem 0.5rem; }
  .about-us-right .press-item {
    margin-top: 10px;
    margin-bottom: 30px;
    height: auto;
    overflow: initial;
    position: relative; }
    @media (min-width: 992px) {
      .about-us-right .press-item {
        margin-top: 1.25rem; } }
    @media (min-width: 1200px) {
      .about-us-right .press-item {
        margin-top: 1.11111rem; } }
    @media (min-width: 1400px) {
      .about-us-right .press-item {
        margin-top: 1rem; } }
    @media (min-width: 992px) {
      .about-us-right .press-item {
        margin-bottom: 3.75rem; } }
    @media (min-width: 1200px) {
      .about-us-right .press-item {
        margin-bottom: 3.33333rem; } }
    @media (min-width: 1400px) {
      .about-us-right .press-item {
        margin-bottom: 3rem; } }
    .about-us-right .press-item:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px; }
      @media (min-width: 992px) {
        .about-us-right .press-item:before {
          height: 1.25rem; } }
      @media (min-width: 1200px) {
        .about-us-right .press-item:before {
          height: 1.11111rem; } }
      @media (min-width: 1400px) {
        .about-us-right .press-item:before {
          height: 1rem; } }
      @media (min-width: 992px) {
        .about-us-right .press-item:before {
          top: -1.25rem; } }
      @media (min-width: 1200px) {
        .about-us-right .press-item:before {
          top: -1.11111rem; } }
      @media (min-width: 1400px) {
        .about-us-right .press-item:before {
          top: -1rem; } }
    .about-us-right .press-item.exe:before {
      background: #F73C37; }
    .about-us-right .press-item.afi:before {
      background: #f73c37; }
    .about-us-right .press-item.prolyte:before {
      background: #44a1d6; }
    .about-us-right .press-item.milos:before {
      background: #ffd100; }
    .about-us-right .press-item.jte:before {
      background: #1F21AB; }
    .about-us-right .press-item.tomcat:before {
      background: #00853D; }
    .about-us-right .press-item.mobiltech:before {
      background: #004494; }
    .about-us-right .press-item.stagedex:before {
      background: #ff4d00; }
    .about-us-right .press-item.prolyft:before {
      background: #8cbf30; }
    .about-us-right .press-item:hover .press-item-content-title {
      text-decoration: underline; }

.big-photo-container {
  position: relative;
  z-index: 2;
  width: 100%; }
  .big-photo-container img {
    width: 100%;
    margin-bottom: 7rem; }

@media (min-width: 320px) and (max-width: 991px) {
  [data-aos="fade"] {
    opacity: 1 !important; } }

@media (min-width: 992px) {
  [data-aos="fade"] {
    opacity: 0;
    transition-property: opacity; } }

@media (min-width: 992px) {
  [data-aos="fade"].aos-animate {
    opacity: 1; } }

@media (min-width: 768px) {
  .big-photo-wrapper {
    margin: 0 -2rem; } }

.top-content {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  z-index: 2; }
  .top-content-right {
    width: 100%; }
    @media (min-width: 768px) {
      .top-content-right {
        width: auto; } }
    .top-content-right .active {
      display: none; }
  .top-content-left {
    padding-bottom: 6rem; }
    .top-content-left .event-text {
      display: none; }
      .top-content-left .event-text.active {
        display: block; }

.items-container {
  min-height: 62rem; }

.event-filter {
  font-weight: 500; }
  .event-filter:hover, .event-filter:active {
    color: #f73c37; }

.brand-filter-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 6rem; }
  .brand-filter-row span {
    font-weight: 500;
    padding-right: 10px; }
    @media (min-width: 320px) {
      .brand-filter-row span {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .brand-filter-row span {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .brand-filter-row span {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .brand-filter-row span {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .brand-filter-row span {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .brand-filter-row span {
        font-size: 1.8rem; } }
    @media (min-width: 1400px) {
      .brand-filter-row span {
        padding-right: 1rem; } }
  .brand-filter-row .brand-category-filter {
    display: block;
    padding: 9px; }
    @media (min-width: 1400px) {
      .brand-filter-row .brand-category-filter {
        padding: 1rem; } }
    .brand-filter-row .brand-category-filter:before {
      color: #fff;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }
      @media (min-width: 320px) {
        .brand-filter-row .brand-category-filter:before {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .brand-filter-row .brand-category-filter:before {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .brand-filter-row .brand-category-filter:before {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .brand-filter-row .brand-category-filter:before {
          font-size: 2.5rem; } }
    .brand-filter-row .brand-category-filter.Milos:before {
      content: "\e91b"; }
      @media (min-width: 320px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 3rem; } }
      @media (min-width: 480px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 1.875rem; } }
      @media (min-width: 1200px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 1.66667rem; } }
      @media (min-width: 1400px) {
        .brand-filter-row .brand-category-filter.Milos:before {
          font-size: 1.5rem; } }
    .brand-filter-row .brand-category-filter.Litec:before {
      content: "\e91c"; }
    .brand-filter-row .brand-category-filter.Tomcat:before {
      content: "\e901"; }
    .brand-filter-row .brand-category-filter.JTE:before {
      content: "\e923"; }
    .brand-filter-row .brand-category-filter.Prolyte:before {
      content: "\e90b"; }
      @media (min-width: 320px) {
        .brand-filter-row .brand-category-filter.Prolyte:before {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .brand-filter-row .brand-category-filter.Prolyte:before {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .brand-filter-row .brand-category-filter.Prolyte:before {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .brand-filter-row .brand-category-filter.Prolyte:before {
          font-size: 2.2rem; } }
    .brand-filter-row .brand-category-filter.EXE:before {
      content: "\e924"; }
    .brand-filter-row .brand-category-filter.Xstage:before {
      content: "\e927"; }
    .brand-filter-row .brand-category-filter.Xtruss:before {
      content: "\e926"; }
    .brand-filter-row .brand-category-filter.Mobiltechlifts:before {
      content: "\e925"; }
      @media (min-width: 320px) {
        .brand-filter-row .brand-category-filter.Mobiltechlifts:before {
          font-size: 5.2rem; } }
      @media (min-width: 480px) {
        .brand-filter-row .brand-category-filter.Mobiltechlifts:before {
          font-size: 4.72727rem; } }
      @media (min-width: 545px) {
        .brand-filter-row .brand-category-filter.Mobiltechlifts:before {
          font-size: 4.33333rem; } }
      @media (min-width: 768px) {
        .brand-filter-row .brand-category-filter.Mobiltechlifts:before {
          font-size: 4rem; } }
    .brand-filter-row .brand-category-filter:hover:before {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
    .brand-filter-row .brand-category-filter:active, .brand-filter-row .brand-category-filter.active {
      text-decoration: none; }
      .brand-filter-row .brand-category-filter:active:before, .brand-filter-row .brand-category-filter.active:before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        color: #f73c37; }

.detail-image {
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .detail-image {
      margin-bottom: 1.25rem; } }
  @media (min-width: 1200px) {
    .detail-image {
      margin-bottom: 1.11111rem; } }
  @media (min-width: 1400px) {
    .detail-image {
      margin-bottom: 1rem; } }

.map-filter {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500; }
  @media (min-width: 992px) {
    .map-filter {
      margin-top: 1rem; } }
  @media (min-width: 992px) {
    .map-filter {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .map-filter {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .map-filter {
      font-size: 1.8rem; } }
  .map-filter-title {
    flex: 0 0 auto;
    padding-right: 5px; }
  .map-filter-list {
    position: relative;
    overflow: hidden; }
    .map-filter-list-inner {
      display: flex;
      justify-content: flex-start;
      overflow: auto; }
    .map-filter-list .slick-prev {
      display: none !important; }
  .map-filter-item {
    cursor: pointer;
    margin-left: 20px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    user-select: none;
    outline: 0;
    white-space: nowrap;
    flex: 0 0 auto; }
    @media (min-width: 992px) {
      .map-filter-item {
        margin-left: 2rem; } }
    .map-filter-item:hover, .map-filter-item.active {
      color: #f73c37;
      font-weight: 700; }
    .map-filter-item:last-of-type {
      padding-right: 150px; }

.map-preview {
  width: 100%;
  padding-top: 59%;
  border: 1px solid #979797;
  position: relative;
  margin-top: 20px;
  background-color: #000; }
  @media (min-width: 992px) {
    .map-preview {
      border-width: 0.125rem; } }
  @media (min-width: 1200px) {
    .map-preview {
      border-width: 0.11111rem; } }
  @media (min-width: 1400px) {
    .map-preview {
      border-width: 0.1rem; } }
  @media (min-width: 992px) {
    .map-preview {
      margin-top: 2.5rem; } }
  .map-preview-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .map-preview-inner.active {
      opacity: 1;
      transition: opacity 400ms linear;
      z-index: 9; }

.IE11 .career-card-title {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem; }

@media (min-width: 992px) {
  .IE11 #menuElem > li {
    flex: 1 1 auto; } }

.IE11 .watch-video,
.edge .watch-video {
  height: auto !important; }

.headquarters .filter-item {
  padding-top: 20px;
  padding-bottom: 0 !important; }
  @media (min-width: 992px) {
    .headquarters .filter-item {
      padding-top: 2.5rem; } }
  @media (min-width: 1200px) {
    .headquarters .filter-item {
      padding-top: 2.22222rem; } }
  @media (min-width: 1400px) {
    .headquarters .filter-item {
      padding-top: 2rem; } }
  .headquarters .filter-item .contact-head-card {
    min-height: 0; }
    @media (min-width: 768px) {
      .headquarters .filter-item .contact-head-card {
        padding-bottom: 5rem; } }
    .headquarters .filter-item .contact-head-card-row {
      display: flex;
      flex-wrap: wrap; }
    .headquarters .filter-item .contact-head-card-item {
      padding-top: 25px;
      flex: 0 1 100%;
      max-width: 100%;
      padding-right: 1rem; }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item {
          padding-top: 3.125rem; } }
      @media (min-width: 1200px) {
        .headquarters .filter-item .contact-head-card-item {
          padding-top: 2.77778rem; } }
      @media (min-width: 1400px) {
        .headquarters .filter-item .contact-head-card-item {
          padding-top: 2.5rem; } }
      @media (min-width: 545px) {
        .headquarters .filter-item .contact-head-card-item {
          flex: 0 1 50%;
          max-width: 50%; } }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item {
          flex: 0 0 25%;
          max-width: 25%; } }
      @media (min-width: 320px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .headquarters .filter-item .contact-head-card-item {
          font-size: 1.6rem; } }
      @media (min-width: 320px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 2.22222rem; } }
      @media (min-width: 1400px) {
        .headquarters .filter-item .contact-head-card-item {
          line-height: 2rem; } }
      @media (min-width: 545px) {
        .headquarters .filter-item .contact-head-card-item.last-item {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding-right: 0; } }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item.last-item {
          flex: 0 1 20%;
          max-width: 20%; } }
      @media (min-width: 992px) {
        .headquarters .filter-item .contact-head-card-item.brands-item {
          flex: 1 0 30%;
          max-width: 30%; } }
      .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
        display: inline-block;
        padding: 20px;
        padding-bottom: 10px;
        padding-top: 0 !important;
        padding-left: 0 !important; }
        @media (min-width: 992px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding: 2.5rem; } }
        @media (min-width: 1200px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding: 2.22222rem; } }
        @media (min-width: 1400px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding: 2rem; } }
        @media (min-width: 992px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding-bottom: 1.25rem; } }
        @media (min-width: 1200px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding-bottom: 1.11111rem; } }
        @media (min-width: 1400px) {
          .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo {
            padding-bottom: 1rem; } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo:before {
          font-size: 15px; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo:before {
              font-size: 1.44444rem; } }
          @media (min-width: 1400px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo:before {
              font-size: 1.3rem; } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-logo2:before {
          font-size: 27px; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-logo2:before {
              font-size: 2.5rem; } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyte:before {
          font-size: 42px;
          transform: translateY(22%);
          display: inline-block; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyte:before {
              font-size: 3.8rem;
              transform: translateY(25%); } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xstage:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xtruss:before {
          font-size: 21px; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xstage:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xtruss:before {
              font-size: 1.9rem; } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-mobiltechlifts:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-exe-technology:before {
          font-size: 23px; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-mobiltechlifts:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-exe-technology:before {
              font-size: 2.1rem; } }
        .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyft-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-stagedex-logo:before {
          font-size: 42px;
          transform: translateY(22%);
          display: inline-block; }
          @media (min-width: 1200px) {
            .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyft-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-stagedex-logo:before {
              font-size: 5rem;
              transform: translateY(25%); } }

.small-title-padding {
  padding-bottom: 10px; }
  @media (min-width: 992px) {
    .small-title-padding {
      padding-bottom: 2.5rem; } }
  @media (min-width: 1200px) {
    .small-title-padding {
      padding-bottom: 2.22222rem; } }
  @media (min-width: 1400px) {
    .small-title-padding {
      padding-bottom: 2rem; } }

.afi-gallery-item {
  padding-left: 5px;
  padding-bottom: 5px;
  flex: 0 1 100%;
  max-width: 100%; }
  @media (min-width: 480px) {
    .afi-gallery-item {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .afi-gallery-item {
      flex: 0 1 33.333%;
      max-width: 33.333%; } }
  .afi-gallery-item-inner {
    position: relative;
    overflow: hidden;
    display: block; }
    .afi-gallery-item-inner:before {
      content: "";
      display: block;
      padding-top: 80%; }
    .afi-gallery-item-inner img {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: top; }

.afi-gallery.large-space .afi-gallery-item {
  padding-bottom: 40px; }

.fancybox-show-nav .fancybox-arrow {
  opacity: 1; }

.modal .modal-dialog {
  border: 0;
  max-width: 90vw; }
  @media (min-width: 992px) {
    .modal .modal-dialog {
      max-width: 112.5rem; } }
  @media (min-width: 1200px) {
    .modal .modal-dialog {
      max-width: 100rem; } }
  @media (min-width: 1400px) {
    .modal .modal-dialog {
      max-width: 90rem; } }
  .modal .modal-dialog .modal-content {
    border: 0;
    padding: 3rem;
    background-color: #fff;
    width: 100%;
    border-radius: 0; }

.modal .close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 18px;
  background-color: #f73c37;
  font-size: 0;
  border: 0;
  z-index: 9;
  cursor: pointer;
  opacity: 1 !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  @media (min-width: 992px) {
    .modal .close {
      padding: 2.25rem; } }
  @media (min-width: 1200px) {
    .modal .close {
      padding: 2rem; } }
  @media (min-width: 1400px) {
    .modal .close {
      padding: 1.8rem; } }
  .modal .close:before {
    content: "\e921";
    display: block;
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    text-shadow: none;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .modal .close:before {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .modal .close:before {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .modal .close:before {
        font-size: 1.4rem; } }
    @media (min-width: 992px) {
      .modal .close:before {
        left: 0.125rem; } }
    @media (min-width: 1200px) {
      .modal .close:before {
        left: 0.11111rem; } }
    @media (min-width: 1400px) {
      .modal .close:before {
        left: 0.1rem; } }
  .modal .close:hover, .modal .close:focus {
    outline: 0;
    border: 0; }
    .modal .close:hover:before, .modal .close:focus:before {
      transform: rotate(90deg); }

.new-newsletter-iframe {
  width: 100%;
  border: none;
  overflow: visible;
  height: 660px; }

.prize-banner {
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 5rem; }
  @media (min-width: 768px) {
    .prize-banner {
      padding-top: 5rem; } }
  .prize-banner-subtitle {
    color: #e3e57a;
    font-family: Zirkel;
    font-weight: 700;
    font-size: 20px; }
    @media (min-width: 992px) {
      .prize-banner-subtitle {
        font-size: 25px; } }
  .prize-banner-text {
    display: none; }
    @media (min-width: 545px) {
      .prize-banner-text {
        display: flex; } }
    .prize-banner-text-left {
      font-size: 25.5rem;
      letter-spacing: normal;
      line-height: 0.82;
      font-weight: 900;
      background-image: url("/AFI/media/system/img/Ebene_13.png");
      background-attachment: fixed;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-position: 0% 0%;
      background-size: 80% 80%;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .prize-banner-text-left {
          background-position: 10% 24%;
          background-size: 75% 75%; } }
      @media (min-width: 1200px) {
        .prize-banner-text-left {
          background-position: 18% 75%;
          background-size: 63% 64%; } }
    .prize-banner-text.line-1 {
      margin-top: 2.2rem; }
    .prize-banner-text-btn {
      text-align: center;
      padding-top: 6rem; }
      .prize-banner-text-btn .btn-prize {
        text-transform: uppercase;
        padding: 1rem 6.7rem;
        height: auto; }
  @media (min-width: 545px) {
    .prize-banner-mobile {
      display: none; } }
  .prize-banner-mobile-title {
    background-image: url("/AFI/media/system/img/Ebene_13.png");
    background-attachment: fixed;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-position: 0% 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 15px; }
    .prize-banner-mobile-title h1 {
      font-size: 20rem;
      letter-spacing: normal;
      line-height: 0.82;
      font-weight: 900; }
  .prize-banner .text-1 {
    color: #000000;
    font-size: 4.8rem;
    font-weight: 400;
    font-style: italic;
    letter-spacing: normal;
    line-height: 1.08;
    text-transform: uppercase;
    padding-top: 15px; }
    @media (min-width: 545px) {
      .prize-banner .text-1 {
        padding-top: 1rem;
        padding-left: 10px; } }
  .prize-banner .text-2 {
    color: #e61c4a;
    font-family: Zirkel;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.08;
    padding-top: 15px; }
    @media (min-width: 545px) {
      .prize-banner .text-2 {
        font-size: 2.4rem;
        padding-top: 1rem;
        padding-left: 15px; } }

.prize-font {
  font-size: 2.8rem;
  line-height: 1.35;
  font-weight: 300;
  padding: 0;
  margin: 0; }
  .prize-font a {
    color: #000; }
    .prize-font a:hover, .prize-font a:focus {
      text-decoration: underline; }

.prize-text-image {
  display: flex;
  flex-wrap: wrap;
  padding: 5.5rem 0;
  border-bottom: 1px solid #2d2d2d;
  text-align: center; }
  @media (min-width: 768px) {
    .prize-text-image {
      flex-wrap: nowrap;
      text-align: left; } }
  .prize-text-image-left {
    margin-top: 7rem;
    flex-grow: 1; }
    @media (min-width: 768px) {
      .prize-text-image-left {
        padding-right: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; } }
    .prize-text-image-left .btn-prize {
      margin-top: 30px; }
      @media (min-width: 768px) {
        .prize-text-image-left .btn-prize {
          align-self: flex-end; } }
  .prize-text-image-right {
    margin-top: 7rem;
    flex: 0 1 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .prize-text-image-right {
        flex: 0 0 35rem;
        max-width: 35rem; } }

.prize-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  border-bottom: 1px solid #2d2d2d; }
  .prize-columns-title {
    flex: 0 1 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .prize-columns-title {
        max-width: 70%; } }
  .prize-columns-item {
    flex: 0 1 100%;
    max-width: 100%;
    padding-top: 5rem; }
    @media (min-width: 545px) {
      .prize-columns-item {
        flex: 0 1 50%;
        max-width: 50%;
        padding-right: 3rem; } }
    @media (min-width: 992px) {
      .prize-columns-item {
        flex: 0 1 33.333%;
        max-width: 33.333%; } }
    @media (min-width: 768px) {
      .prize-columns-item h4 {
        font-size: 2.8rem; } }
  .prize-columns.columns-3 .prize-columns-item:nth-child(2) h4 {
    color: #ff1727; }
  .prize-columns.columns-3 .prize-columns-item:nth-child(3) h4 {
    color: #ee82b3; }
  .prize-columns.columns-3 .prize-columns-item:nth-child(4) h4 {
    color: #60c6ed; }
  .prize-columns.columns-2 .prize-columns-item:nth-child(2) h4 {
    color: #6f539e; }
  @media (min-width: 545px) {
    .prize-columns.columns-2 .prize-columns-item:nth-child(2) {
      flex: 0 1 40%;
      max-width: 40%; } }
  .prize-columns.columns-2 .prize-columns-item:nth-child(3) h4 {
    color: #f7bdc1; }
  @media (min-width: 545px) {
    .prize-columns.columns-2 .prize-columns-item:nth-child(3) {
      flex: 0 1 60%;
      max-width: 60%; } }

.prize-comitte,
.prize-registration {
  padding: 5rem 0;
  border-bottom: 1px solid #9f9f9f; }
  .prize-comitte-content,
  .prize-registration-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

.prize-registration {
  border: 0; }
  .prize-registration .ErrorLabel {
    font-weight: 900;
    margin-top: 2rem;
    font-size: 18px;
    display: block; }
    @media (min-width: 992px) {
      .prize-registration .ErrorLabel {
        font-size: 2rem; } }
  .prize-registration .InfoLabel {
    font-weight: 900;
    margin-top: 3rem;
    font-size: 20px;
    display: block; }
    @media (min-width: 992px) {
      .prize-registration .InfoLabel {
        font-size: 2.6rem; } }

.comitte-item {
  flex: 0 1 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 3rem 3rem 0; }
  @media (min-width: 545px) {
    .comitte-item {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .comitte-item {
      flex: 0 1 33.333%;
      max-width: 33.333%; } }
  @media (min-width: 992px) {
    .comitte-item {
      flex: 0 1 25%;
      max-width: 25%; } }
  .comitte-item-photo {
    padding: 3rem 2rem 0; }
    .comitte-item-photo-inner {
      position: relative;
      overflow: hidden;
      border: 1px solid #9f9f9f;
      border-radius: 50%; }
      @media (min-width: 320px) and (max-width: 479px) {
        .comitte-item-photo-inner {
          max-width: 280px;
          margin: 0 auto; } }
      .comitte-item-photo-inner:before {
        content: "";
        display: block;
        padding-top: 100%; }
      .comitte-item-photo-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100% !important;
        object-fit: cover; }
  .comitte-item-name h4 {
    font-size: 17px;
    font-weight: 700;
    padding-top: 2rem;
    line-height: 1.1; }
    @media (min-width: 768px) {
      .comitte-item-name h4 {
        font-size: 2rem; } }
  .comitte-item-position {
    font-size: 15px;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .comitte-item-position {
        font-size: 1.6rem; } }

.prize-bottom {
  padding: 5.5rem 0;
  border-top: 1px solid #2d2d2d;
  text-align: center; }
  @media (min-width: 545px) {
    .prize-bottom {
      text-align: left; } }
  .prize-bottom h2 {
    margin-bottom: 2rem; }
  .prize-bottom h2:not(:first-child) {
    margin-top: 5rem; }

.prize-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .prize-form-left {
    flex: 0 1 100%;
    max-width: 100%;
    margin-top: 30px; }
    @media (min-width: 768px) {
      .prize-form-left {
        flex: 0 1 48%;
        max-width: 48%;
        margin-top: 4rem; } }
    @media (min-width: 1200px) {
      .prize-form-left {
        flex: 0 1 39%;
        max-width: 39%; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .prize-form-left.last-type {
        order: 6; } }
  .prize-form-right {
    flex: 0 1 100%;
    max-width: 100%;
    margin-top: 30px; }
    @media (min-width: 768px) {
      .prize-form-right {
        flex: 0 1 48%;
        max-width: 48%;
        margin-top: 4rem; } }
    @media (min-width: 1200px) {
      .prize-form-right {
        flex: 0 1 52%;
        max-width: 52%; } }
  .prize-form-item:not(.type-area) {
    border-bottom: 1px solid #2d2d2d; }
  .prize-form-item textarea,
  .prize-form-item input,
  .prize-form-item .selectric .label {
    color: #000 !important;
    padding: 0;
    font-size: 18px;
    line-height: 24px;
    padding: 15px 0 5px;
    height: auto;
    border: 0 !important; }
    @media (min-width: 768px) {
      .prize-form-item textarea,
      .prize-form-item input,
      .prize-form-item .selectric .label {
        font-size: 2.8rem;
        line-height: 3.4rem;
        padding: 1.6rem 0 0.6rem; } }
    .prize-form-item textarea::placeholder,
    .prize-form-item input::placeholder,
    .prize-form-item .selectric .label::placeholder {
      color: #000 !important;
      font-weight: 300;
      line-height: 1.5; }
    .prize-form-item textarea:-ms-input-placeholder,
    .prize-form-item input:-ms-input-placeholder,
    .prize-form-item .selectric .label:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000 !important;
      font-weight: 300;
      line-height: 1.5; }
    .prize-form-item textarea::-ms-input-placeholder,
    .prize-form-item input::-ms-input-placeholder,
    .prize-form-item .selectric .label::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #000 !important;
      font-weight: 300;
      line-height: 1.5; }
  .prize-form-item textarea {
    border-bottom: 0;
    background-image: linear-gradient(#000 1px, transparent 1px) !important;
    background-size: 100% 44px !important;
    background-position: 0 43px !important;
    line-height: 44px;
    height: calc(44px * 6);
    resize: none;
    padding: 2px 0 0; }
    @media (min-width: 768px) {
      .prize-form-item textarea {
        background-image: linear-gradient(#000 0.1rem, transparent 0.1rem) !important;
        background-size: 100% 5.9rem !important;
        background-position: 0 5.7rem !important;
        line-height: 5.8rem;
        height: calc(5.8rem * 6);
        padding: 0.6rem 0 0; } }
    @media (min-width: 1400px) {
      .prize-form-item textarea {
        background-size: 100% 5.8rem !important; } }
  .prize-form-item .selectric .label {
    color: #000;
    font-weight: 300; }
  .prize-form-item .selectric-items .selectric-scroll {
    background-color: #000 !important; }
  .prize-form-files {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap; }
  .prize-form-file {
    display: none;
    flex: 0 1 100%;
    max-width: 100%; }
    .prize-form-file:first-of-type {
      display: block; }
    .prize-form-file input[type="file"] {
      display: none; }
    .prize-form-file label {
      background-color: #ffa200;
      width: 100%;
      padding: 0 15px; }
      .prize-form-file label:hover, .prize-form-file label:focus {
        background-color: #e69200; }
    .prize-form-file .Error:after {
      display: none !important; }
    .prize-form-file.filled {
      order: -1; }
  .prize-form-submit {
    margin-top: 10px;
    margin-top: Max(1rem, 10px); }
    .prize-form-submit input[type="submit"] {
      width: 100%;
      background-color: #9a18e0; }
      .prize-form-submit input[type="submit"]:hover, .prize-form-submit input[type="submit"]:focus {
        background-color: #8a16c9; }
  .prize-form-text {
    font-size: 17px;
    margin-top: 1.8rem; }
    @media (min-width: 1200px) {
      .prize-form-text {
        font-size: 1.8rem; } }

@media (min-width: 1200px) {
  .Gecko .prize-banner-text-left {
    background-position: 18% 85%;
    background-size: 63% 85%; } }

.headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-tomcat-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-header-logo:before {
  font-size: 20px !important; }

.headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-exe-technology:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xstage:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-xtruss:before {
  font-size: 30px !important; }

.headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyte:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-prolyft-logo:before, .headquarters .filter-item .contact-head-card-item.brands-item .brand-logo.ico-stagedex-logo:before {
  font-size: 35px !important; }

.page-background-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  max-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  opacity: 0.3; }
  .page-background-inner:after {
    content: "";
    background: linear-gradient(transparent, #000);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 20vh;
    height: 250px; }

.page-content {
  position: relative;
  z-index: 2; }
  .page-content h3,
  .page-content h4,
  .page-content h5 {
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .page-content h3,
      .page-content h4,
      .page-content h5 {
        margin-bottom: 1.25rem; } }
    @media (min-width: 1200px) {
      .page-content h3,
      .page-content h4,
      .page-content h5 {
        margin-bottom: 1.11111rem; } }
    @media (min-width: 1400px) {
      .page-content h3,
      .page-content h4,
      .page-content h5 {
        margin-bottom: 1rem; } }

.page-header {
  padding-top: 165px;
  padding-bottom: 60px;
  color: #fff;
  position: relative;
  z-index: 2; }
  @media (min-width: 992px) {
    .page-header {
      padding-top: calc(11.5rem + 8.5rem);
      padding-bottom: 6rem; } }
  .page-header h1 {
    margin-bottom: 0; }
    @media (min-width: 320px) {
      .page-header h1 {
        line-height: 7.6rem; } }
  .page-header-menu {
    position: relative;
    overflow: hidden; }
    .page-header-menu-inner {
      display: flex;
      justify-content: flex-start;
      width: calc(100% - 15px);
      overflow: auto; }
      @media (min-width: 992px) {
        .page-header-menu-inner {
          width: 100%;
          overflow: visible; } }
    .page-header-menu-item {
      flex: 0 0 auto;
      font-weight: 500;
      font-size: 18px;
      padding-right: 30px;
      white-space: nowrap; }
      @media (min-width: 992px) {
        .page-header-menu-item {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .page-header-menu-item {
          font-size: 2rem; } }
      @media (min-width: 1400px) {
        .page-header-menu-item {
          font-size: 1.8rem; } }
      @media (min-width: 992px) {
        .page-header-menu-item {
          padding-right: 3.75rem; } }
      @media (min-width: 1200px) {
        .page-header-menu-item {
          padding-right: 3.33333rem; } }
      @media (min-width: 1400px) {
        .page-header-menu-item {
          padding-right: 3rem; } }
      .page-header-menu-item.active, .page-header-menu-item:hover, .page-header-menu-item:focus {
        color: #fff;
        font-weight: 900;
        outline: 0; }
      .page-header-menu-item.active {
        text-decoration: underline; }
      .page-header-menu-item:last-of-type {
        padding-right: 200px; }
    .page-header-menu .slick-prev {
      display: none !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .small-page-header .page-header {
    padding-bottom: 20px !important; } }

@media (min-width: 992px) {
  .text-page-header .page-header {
    padding-top: calc(5.5rem + 8.5rem);
    padding-bottom: 3rem; } }

.arrow-next {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  transform: translateX(50px);
  opacity: 0; }
  .arrow-next:after {
    content: "\e904";
    color: #fff;
    font-size: 17px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .arrow-next:after {
        font-size: 2.125rem; } }
    @media (min-width: 1200px) {
      .arrow-next:after {
        font-size: 1.88889rem; } }
    @media (min-width: 1400px) {
      .arrow-next:after {
        font-size: 1.7rem; } }
  .arrow-next.black-mode {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 100%);
    width: 25%;
    max-width: 150px; }
  .arrow-next.white-mode {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
    width: 25%;
    max-width: 150px; }
    .arrow-next.white-mode:after {
      color: #000; }
  .arrow-next.active {
    transform: translateX(0px);
    opacity: 1; }

.bread-container {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .bread-container .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    overflow: auto; }
    .bread-container .breadcrumbs * {
      flex: 0 0 auto;
      white-space: nowrap; }
      .bread-container .breadcrumbs *.CMSBreadCrumbsCurrentItem {
        display: inline-block;
        padding-right: 150px; }

.submenu-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: -5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer; }
  @media (min-width: 768px) {
    .submenu-btn {
      top: 10px; } }
  @media (min-width: 992px) {
    .submenu-btn {
      display: none !important; } }
  .submenu-btn:before {
    content: "\e91f";
    color: #fff;
    font-size: 24px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-weight: 800; }
  .submenu-btn.open:before {
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }

.links-table {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  @media (min-width: 992px) {
    .links-table {
      display: block; } }
  .links-table .row {
    width: 100%; }
  .links-table-item {
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .links-table-item {
        height: 70px;
        flex: none;
        margin-top: 0; } }
  .links-table.menu-links {
    border-top: 0;
    margin-top: 5px; }
    @media (min-width: 768px) {
      .links-table.menu-links {
        margin-top: 10px; } }
    @media (min-width: 992px) {
      .links-table.menu-links {
        display: none; } }
    .links-table.menu-links .links-table-item {
      height: 30px;
      display: flex;
      align-items: center; }
      .links-table.menu-links .links-table-item a:before {
        font-size: 23px;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a:before {
            font-size: 36px; } }
      .links-table.menu-links .links-table-item a:hover:before, .links-table.menu-links .links-table-item a:focus:before {
        color: #000; }
      .links-table.menu-links .links-table-item a.ico-logo:before, .links-table.menu-links .links-table-item a.ico-header-logo:before {
        font-size: 20px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-logo:before, .links-table.menu-links .links-table-item a.ico-header-logo:before {
            font-size: 30px; } }
      .links-table.menu-links .links-table-item a.ico-logo2:before {
        font-size: 26px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-logo2:before {
            font-size: 40px; } }
      .links-table.menu-links .links-table-item a.ico-exe-technology:before {
        font-size: 28px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-exe-technology:before {
            font-size: 40px; } }
      .links-table.menu-links .links-table-item a.ico-tomcat-logo:before {
        font-size: 20px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-tomcat-logo:before {
            font-size: 30px; } }
      .links-table.menu-links .links-table-item a.ico-flexa-logo:before {
        font-size: 20px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-flexa-logo:before {
            font-size: 30px; } }
      .links-table.menu-links .links-table-item a.ico-mobiltechlifts:before {
        font-size: 32px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-mobiltechlifts:before {
            font-size: 44px; } }
      .links-table.menu-links .links-table-item a.ico-xstage:before {
        font-size: 26px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-xstage:before {
            font-size: 40px; } }
      .links-table.menu-links .links-table-item a.ico-prolyte:before {
        font-size: 36px; }
        @media (min-width: 545px) {
          .links-table.menu-links .links-table-item a.ico-prolyte:before {
            font-size: 50px; } }
        @media (min-width: 768px) {
          .links-table.menu-links .links-table-item a.ico-prolyte:before {
            font-size: 58px; } }

.hp-banner {
  position: relative;
  width: 100%;
  z-index: 5; }
  @media (min-width: 992px) {
    .hp-banner {
      padding-top: 8.5rem; } }
  .hp-banner-video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden; }
    .hp-banner-video:after {
      content: "";
      background: linear-gradient(transparent, #000);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 15vh;
      height: 150px; }
    .hp-banner-video video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%; }
  .hp-banner-content {
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: 100vh; }
    @media (min-width: 992px) {
      .hp-banner-content {
        flex-direction: row;
        display: flex;
        align-items: stretch;
        min-height: 0;
        min-height: calc(100vh - 8.5rem); } }
    .hp-banner-content-left {
      width: 100%; }
      @media (min-width: 992px) {
        .hp-banner-content-left {
          width: 71rem;
          display: flex;
          flex-direction: column;
          min-height: calc(100vh - 8.5rem); } }
      .hp-banner-content-left-title {
        color: #fff;
        opacity: 0;
        animation: show linear 500ms forwards;
        animation-delay: 0.5s;
        display: flex;
        align-items: center;
        height: 76vh;
        padding-top: 60px; }
        @media (min-width: 992px) {
          .hp-banner-content-left-title {
            flex: 1;
            padding: 0;
            margin: 0;
            height: auto;
            display: flex;
            align-items: center;
            margin: 3rem 0;
            transform: translateY(4.3rem); } }
    @media (min-width: 992px) {
      .hp-banner-content-right {
        display: flex;
        align-items: flex-end;
        min-height: calc(100vh - 8.5rem);
        flex: 0 0 47rem;
        width: 47rem; } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.redbox {
  padding: 40px 20px 20px;
  background-color: #f73c37;
  position: relative;
  width: 100%;
  margin-top: 10px;
  opacity: 0;
  animation: show linear 1000ms forwards;
  animation-delay: 1.3s; }
  @media (min-width: 768px) {
    .redbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 15px; } }
  @media (min-width: 992px) {
    .redbox {
      margin: 0;
      padding: 3rem 2.8rem 3rem 4.5rem;
      transform: translate(-4rem, 8.6rem); } }
  .redbox-inner {
    width: 100%; }
    @media (min-width: 768px) {
      .redbox-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px; } }
  .redbox-content {
    font-size: 14px;
    color: #fff;
    font-weight: 500; }
    @media (min-width: 768px) {
      .redbox-content {
        flex: 1;
        max-width: calc(100% - 150px); } }
    @media (min-width: 992px) {
      .redbox-content {
        max-width: calc(100% - 15rem - 4rem); } }
    @media (min-width: 992px) {
      .redbox-content {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .redbox-content {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .redbox-content {
        font-size: 1.4rem; } }
    @media (min-width: 1200px) {
      .redbox-content {
        max-width: calc(100% - 13.33333rem - 4rem); } }
    @media (min-width: 1400px) {
      .redbox-content {
        max-width: calc(100% - 12rem - 4rem); } }
    .redbox-content-list {
      display: grid;
      justify-items: center;
      grid-template-columns: 1fr 1fr; }
      @media (min-width: 480px) {
        .redbox-content-list {
          grid-template-columns: 1fr 1fr 1fr; } }
      @media (min-width: 545px) {
        .redbox-content-list {
          grid-template-columns: 1fr 1fr 1fr 1fr; } }
      @media (min-width: 768px) {
        .redbox-content-list {
          grid-template-columns: 1fr 1fr 1fr; } }
      @media (min-width: 992px) {
        .redbox-content-list {
          grid-template-columns: 1fr 1fr 1fr 1fr; } }
      @media (min-width: 1200px) {
        .redbox-content-list {
          grid-template-columns: 1fr 1.5fr 1.5fr 1fr 1fr; } }
      .redbox-content-list .link-item {
        margin-right: 10px;
        margin-top: 20px;
        display: inline-flex;
        align-items: flex-end; }
        @media (min-width: 768px) {
          .redbox-content-list .link-item {
            margin-right: 40px; } }
        @media (min-width: 992px) {
          .redbox-content-list .link-item {
            margin-top: 2.6rem;
            margin-right: 1rem; } }
        .redbox-content-list .link-item a:before {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          font-size: 25px; }
          @media (min-width: 992px) {
            .redbox-content-list .link-item a:before {
              font-size: 2.5rem; } }
        .redbox-content-list .link-item a:hover:before, .redbox-content-list .link-item a:focus:before {
          color: #000; }
        .redbox-content-list .link-item a.ico-logo:before, .redbox-content-list .link-item a.ico-header-logo:before {
          font-size: 22px; }
          @media (min-width: 992px) {
            .redbox-content-list .link-item a.ico-logo:before, .redbox-content-list .link-item a.ico-header-logo:before {
              font-size: 2.2rem; } }
        .redbox-content-list .link-item a.ico-logo2:before {
          font-size: 26px; }
          @media (min-width: 992px) {
            .redbox-content-list .link-item a.ico-logo2:before {
              font-size: 2.6rem; } }
        .redbox-content-list .link-item a.ico-mobiltechlifts:before {
          font-size: 33px; }
          @media (min-width: 992px) {
            .redbox-content-list .link-item a.ico-mobiltechlifts:before {
              font-size: 3.3rem; } }
        .redbox-content-list .link-item a.ico-xstage:before {
          font-size: 25px; }
          @media (min-width: 992px) {
            .redbox-content-list .link-item a.ico-xstage:before {
              font-size: 2.5rem; } }
        .redbox-content-list .link-item a.ico-prolyte:before {
          font-size: 38px;
          transform: translateY(16%);
          display: inline-block; }
          @media (min-width: 1400px) {
            .redbox-content-list .link-item a.ico-prolyte:before {
              font-size: 3.8rem;
              transform: translateY(21%); } }
        .redbox-content-list .link-item a.ico-prolyft-logo:before, .redbox-content-list .link-item a.ico-stagedex-logo:before {
          font-size: 38px;
          transform: translateY(16%);
          display: inline-block; }
          @media (min-width: 1400px) {
            .redbox-content-list .link-item a.ico-prolyft-logo:before, .redbox-content-list .link-item a.ico-stagedex-logo:before {
              font-size: 3.8rem;
              transform: translateY(21%); } }
    .redbox-content h3 {
      font-size: 6.8rem;
      line-height: 7.6rem; }
      @media (min-width: 480px) {
        .redbox-content h3 {
          font-size: 6.18182rem; } }
      @media (min-width: 545px) {
        .redbox-content h3 {
          font-size: 5.66667rem; } }
      @media (min-width: 768px) {
        .redbox-content h3 {
          font-size: 4.53333rem; } }
      @media (min-width: 992px) {
        .redbox-content h3 {
          font-size: 4.25rem; } }
      @media (min-width: 1200px) {
        .redbox-content h3 {
          font-size: 3.77778rem; } }
      @media (min-width: 1400px) {
        .redbox-content h3 {
          font-size: 3.4rem; } }
      @media (min-width: 480px) {
        .redbox-content h3 {
          line-height: 6.90909rem; } }
      @media (min-width: 545px) {
        .redbox-content h3 {
          line-height: 6.33333rem; } }
      @media (min-width: 768px) {
        .redbox-content h3 {
          line-height: 5.06667rem; } }
      @media (min-width: 992px) {
        .redbox-content h3 {
          line-height: 4.75rem; } }
      @media (min-width: 1200px) {
        .redbox-content h3 {
          line-height: 4.22222rem; } }
      @media (min-width: 1400px) {
        .redbox-content h3 {
          line-height: 3.8rem; } }
  .redbox-link {
    margin-top: 40px; }
    @media (min-width: 768px) {
      .redbox-link {
        order: 3; } }
    @media (min-width: 768px) {
      .redbox-link {
        flex: 0 0 calc(120px + 4rem);
        max-width: calc(120px + 4rem);
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top: 0; } }
    @media (min-width: 992px) {
      .redbox-link {
        flex: 0 0 16rem;
        max-width: 16rem; } }
    .redbox-link .arrow-btn {
      margin-top: 0.4rem; }
      @media (min-width: 320px) {
        .redbox-link .arrow-btn {
          line-height: 5.6rem; } }
      @media (min-width: 480px) {
        .redbox-link .arrow-btn {
          line-height: 5.09091rem; } }
      @media (min-width: 545px) {
        .redbox-link .arrow-btn {
          line-height: 4.66667rem; } }
      @media (min-width: 768px) {
        .redbox-link .arrow-btn {
          line-height: 3.8rem; } }
  @media (min-width: 768px) {
    .redbox-logos {
      flex: 0 1 100%;
      max-width: 100%; } }

.hp-youtube {
  color: #fff;
  opacity: 0;
  animation: show linear 500ms forwards;
  animation-delay: 1.8s; }
  @media (min-width: 320px) and (max-width: 991px) {
    .hp-youtube {
      position: static !important;
      animation-delay: 0.8s; } }
  @media (min-width: 992px) {
    .hp-youtube {
      transform: translate3d(5rem, 0, 0);
      position: relative;
      pointer-events: none;
      pointer-events: auto;
      will-change: scroll-position;
      transform-origin: center center;
      bottom: 0; } }
  @media (min-width: 992px) {
    .hp-youtube.fixed {
      position: fixed;
      transform: translate3d(5rem, 0, 0) !important; } }
  @media (min-width: 992px) {
    .hp-youtube.fixed.transition-on {
      transition: transform 200ms linear; } }
  @media (min-width: 992px) {
    .hp-youtube.small-size {
      transform-origin: center center;
      transform: translate(25%, 25%) scale(0.5) !important;
      padding-bottom: 2rem;
      padding-right: 3rem;
      right: 0;
      bottom: 0;
      transition: all 200ms linear; } }
  @media (min-width: 992px) {
    .hp-youtube.small-size:hover, .hp-youtube.small-size:focus {
      transform: translate(0, 0rem) scale(1) !important; } }
  @media (min-width: 992px) {
    .hp-youtube.small-size.fix-size {
      transition-duration: 200ms !important;
      transform: translate(0, 0rem) scale(1) !important; } }
  .hp-youtube-title {
    font-size: 24px;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .hp-youtube-title {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    @media (min-width: 992px) {
      .hp-youtube-title {
        font-size: 2.4rem;
        margin-bottom: 1rem; } }
    .hp-youtube-title .youtube-title {
      font-weight: 900;
      font-size: 4.8rem; }
      @media (min-width: 480px) {
        .hp-youtube-title .youtube-title {
          font-size: 4.36364rem; } }
      @media (min-width: 545px) {
        .hp-youtube-title .youtube-title {
          font-size: 4rem; } }
      @media (min-width: 768px) {
        .hp-youtube-title .youtube-title {
          font-size: 3.2rem; } }
      @media (min-width: 992px) {
        .hp-youtube-title .youtube-title {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .hp-youtube-title .youtube-title {
          font-size: 2.66667rem; } }
      @media (min-width: 1400px) {
        .hp-youtube-title .youtube-title {
          font-size: 2.4rem; } }
    .hp-youtube-title .see-more {
      display: none;
      font-size: 3.6rem; }
      @media (min-width: 768px) {
        .hp-youtube-title .see-more {
          display: flex; } }
      @media (min-width: 480px) {
        .hp-youtube-title .see-more {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .hp-youtube-title .see-more {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .hp-youtube-title .see-more {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .hp-youtube-title .see-more {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .hp-youtube-title .see-more {
          font-size: 2rem; } }
      @media (min-width: 1400px) {
        .hp-youtube-title .see-more {
          font-size: 1.8rem; } }
  .hp-youtube-content {
    position: relative;
    overflow: hidden;
    background-color: #000; }
    @media (min-width: 992px) {
      .hp-youtube-content {
        width: 47rem; } }
    .hp-youtube-content:before {
      content: "";
      display: block;
      padding-top: 67%; }
    .hp-youtube-content-play {
      width: 75px;
      height: 75px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      cursor: pointer;
      opacity: 0;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 1200px) {
        .hp-youtube-content-play {
          width: 9rem;
          height: 9rem; } }
      .hp-youtube-content-play:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 31px solid #000;
        transform: translateX(12%);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 1200px) {
          .hp-youtube-content-play:after {
            width: 0;
            height: 0;
            border-top: 1.7rem solid transparent;
            border-bottom: 1.7rem solid transparent;
            border-left: 3.1rem solid #000; } }
      .hp-youtube-content-play:hover, .hp-youtube-content-play:focus {
        background-color: #f73c37; }
        .hp-youtube-content-play:hover:after, .hp-youtube-content-play:focus:after {
          border-right-color: #fff; }
      .hp-youtube-content-play.visible {
        opacity: 1; }
    .hp-youtube-content #hp-youtube-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }

@media (min-width: 992px) {
  .edge .hp-youtube.small-size:hover, .edge .hp-youtube.small-size:focus,
  .IE11 .hp-youtube.small-size:hover,
  .IE11 .hp-youtube.small-size:focus {
    transform: translate(25%, 25%) scale(0.5); } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list {
    width: 100%; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item {
    text-align: center; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item:nth-of-type(1), .IE11 .redbox-content-list .link-item:nth-of-type(6) {
    max-width: 13%;
    width: 13%; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item:nth-of-type(2), .IE11 .redbox-content-list .link-item:nth-of-type(7) {
    max-width: 20%;
    width: 20%; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item:nth-of-type(3), .IE11 .redbox-content-list .link-item:nth-of-type(8) {
    max-width: 20%;
    width: 20%; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item:nth-of-type(4), .IE11 .redbox-content-list .link-item:nth-of-type(9) {
    max-width: 15%;
    width: 15%; } }

@media (min-width: 1400px) {
  .IE11 .redbox-content-list .link-item:nth-of-type(5), .IE11 .redbox-content-list .link-item:nth-of-type(10) {
    max-width: 18%;
    width: 18%; } }

@media (min-width: 992px) {
  .mobile-show {
    display: none; } }

@media (min-width: 320px) and (max-width: 991px) {
  .desktop-show {
    display: none; } }

.desktop-show .arrow-btn {
  z-index: auto; }

.hp-education {
  margin-top: 50px;
  max-width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .hp-education {
      margin-top: 17.5rem; } }
  @media (min-width: 768px) {
    .hp-education-top {
      display: flex;
      width: 100%;
      justify-content: space-between; } }
  @media (min-width: 768px) {
    .hp-education-top-content {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .hp-education-top-link {
      flex: 0 0 150px;
      max-width: 150px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end; } }
  @media (min-width: 1200px) {
    .hp-education-top-link {
      flex: 0 0 15rem;
      max-width: 15rem; } }
  .hp-education-list {
    margin-top: 30px; }
    @media (min-width: 992px) {
      .hp-education-list {
        margin-top: 4rem; } }
  .hp-education .slick-list {
    width: 80vw;
    overflow: visible; }
    .hp-education .slick-list * {
      outline: 0 !important; }

@media (min-width: 992px) {
  .space-big .row {
    margin-right: -2.5rem; } }

@media (min-width: 1200px) {
  .space-big .row {
    margin-right: -2.22222rem; } }

@media (min-width: 1400px) {
  .space-big .row {
    margin-right: -2rem; } }

@media (min-width: 992px) {
  .space-big .row {
    margin-left: -2.5rem; } }

@media (min-width: 1200px) {
  .space-big .row {
    margin-left: -2.22222rem; } }

@media (min-width: 1400px) {
  .space-big .row {
    margin-left: -2rem; } }

@media (min-width: 992px) {
  .space-big .row [class*=col-] {
    padding-right: 2.5rem; } }

@media (min-width: 1200px) {
  .space-big .row [class*=col-] {
    padding-right: 2.22222rem; } }

@media (min-width: 1400px) {
  .space-big .row [class*=col-] {
    padding-right: 2rem; } }

@media (min-width: 992px) {
  .space-big .row [class*=col-] {
    padding-left: 2.5rem; } }

@media (min-width: 1200px) {
  .space-big .row [class*=col-] {
    padding-left: 2.22222rem; } }

@media (min-width: 1400px) {
  .space-big .row [class*=col-] {
    padding-left: 2rem; } }

@media (min-width: 992px) {
  .space-medium .row {
    margin-right: -1.625rem; } }

@media (min-width: 1200px) {
  .space-medium .row {
    margin-right: -1.44444rem; } }

@media (min-width: 1400px) {
  .space-medium .row {
    margin-right: -1.3rem; } }

@media (min-width: 992px) {
  .space-medium .row {
    margin-left: -1.625rem; } }

@media (min-width: 1200px) {
  .space-medium .row {
    margin-left: -1.44444rem; } }

@media (min-width: 1400px) {
  .space-medium .row {
    margin-left: -1.3rem; } }

@media (min-width: 992px) {
  .space-medium .row [class*=col-] {
    padding-right: 1.625rem; } }

@media (min-width: 1200px) {
  .space-medium .row [class*=col-] {
    padding-right: 1.44444rem; } }

@media (min-width: 1400px) {
  .space-medium .row [class*=col-] {
    padding-right: 1.3rem; } }

@media (min-width: 992px) {
  .space-medium .row [class*=col-] {
    padding-left: 1.625rem; } }

@media (min-width: 1200px) {
  .space-medium .row [class*=col-] {
    padding-left: 1.44444rem; } }

@media (min-width: 1400px) {
  .space-medium .row [class*=col-] {
    padding-left: 1.3rem; } }

@media (min-width: 992px) {
  .space-30 .row {
    margin-right: -1.875rem; } }

@media (min-width: 1200px) {
  .space-30 .row {
    margin-right: -1.66667rem; } }

@media (min-width: 1400px) {
  .space-30 .row {
    margin-right: -1.5rem; } }

@media (min-width: 992px) {
  .space-30 .row {
    margin-left: -1.875rem; } }

@media (min-width: 1200px) {
  .space-30 .row {
    margin-left: -1.66667rem; } }

@media (min-width: 1400px) {
  .space-30 .row {
    margin-left: -1.5rem; } }

@media (min-width: 992px) {
  .space-30 .row [class*=col-] {
    padding-right: 1.875rem; } }

@media (min-width: 1200px) {
  .space-30 .row [class*=col-] {
    padding-right: 1.66667rem; } }

@media (min-width: 1400px) {
  .space-30 .row [class*=col-] {
    padding-right: 1.5rem; } }

@media (min-width: 992px) {
  .space-30 .row [class*=col-] {
    padding-left: 1.875rem; } }

@media (min-width: 1200px) {
  .space-30 .row [class*=col-] {
    padding-left: 1.66667rem; } }

@media (min-width: 1400px) {
  .space-30 .row [class*=col-] {
    padding-left: 1.5rem; } }

.space-small .row {
  margin: 0 -10px; }
  @media (min-width: 992px) {
    .space-small .row {
      margin-right: -1.25rem; } }
  @media (min-width: 1200px) {
    .space-small .row {
      margin-right: -1.11111rem; } }
  @media (min-width: 1400px) {
    .space-small .row {
      margin-right: -1rem; } }
  @media (min-width: 992px) {
    .space-small .row {
      margin-left: -1.25rem; } }
  @media (min-width: 1200px) {
    .space-small .row {
      margin-left: -1.11111rem; } }
  @media (min-width: 1400px) {
    .space-small .row {
      margin-left: -1rem; } }
  .space-small .row [class*=col-] {
    padding: 10px; }
    @media (min-width: 992px) {
      .space-small .row [class*=col-] {
        padding: 1.25rem; } }
    @media (min-width: 1200px) {
      .space-small .row [class*=col-] {
        padding: 1.11111rem; } }
    @media (min-width: 1400px) {
      .space-small .row [class*=col-] {
        padding: 1rem; } }

.space-card .row {
  margin: 0 -10px; }
  @media (min-width: 992px) {
    .space-card .row {
      margin-right: -1.875rem; } }
  @media (min-width: 1200px) {
    .space-card .row {
      margin-right: -1.66667rem; } }
  @media (min-width: 1400px) {
    .space-card .row {
      margin-right: -1.5rem; } }
  @media (min-width: 992px) {
    .space-card .row {
      margin-left: -1.875rem; } }
  @media (min-width: 1200px) {
    .space-card .row {
      margin-left: -1.66667rem; } }
  @media (min-width: 1400px) {
    .space-card .row {
      margin-left: -1.5rem; } }
  .space-card .row [class*=col-] {
    padding-top: 0;
    padding-bottom: 22px; }
    @media (min-width: 992px) {
      .space-card .row [class*=col-] {
        padding-left: 1.875rem; } }
    @media (min-width: 1200px) {
      .space-card .row [class*=col-] {
        padding-left: 1.66667rem; } }
    @media (min-width: 1400px) {
      .space-card .row [class*=col-] {
        padding-left: 1.5rem; } }
    @media (min-width: 992px) {
      .space-card .row [class*=col-] {
        padding-right: 1.875rem; } }
    @media (min-width: 1200px) {
      .space-card .row [class*=col-] {
        padding-right: 1.66667rem; } }
    @media (min-width: 1400px) {
      .space-card .row [class*=col-] {
        padding-right: 1.5rem; } }
    @media (min-width: 992px) {
      .space-card .row [class*=col-] {
        padding-bottom: 2.75rem; } }
    @media (min-width: 1200px) {
      .space-card .row [class*=col-] {
        padding-bottom: 2.44444rem; } }
    @media (min-width: 1400px) {
      .space-card .row [class*=col-] {
        padding-bottom: 2.2rem; } }

@media (min-width: 320px) and (max-width: 544px) {
  .thin-vertical-space [class*=col-] {
    padding-top: 0 !important;
    padding-bottom: 10px !important; } }

.hp-news {
  position: relative;
  padding-bottom: 30px; }
  .hp-news-bg {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.1;
    width: 100%;
    height: 100%; }
    .hp-news-bg-container {
      position: absolute;
      top: 0%;
      right: 0;
      width: 60%;
      height: 100%;
      display: none; }
      @media (min-width: 992px) {
        .hp-news-bg-container {
          display: flex; } }
  .hp-news-content {
    position: relative;
    z-index: 2;
    margin-top: 85px; }
    @media (min-width: 992px) {
      .hp-news-content {
        margin-top: 10.5rem; } }
    @media (min-width: 768px) {
      .hp-news-content-text {
        padding-right: 20%; } }
    @media (min-width: 992px) {
      .hp-news-content-text {
        padding-right: 40%; } }
    .hp-news-content-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 85px; }
      @media (min-width: 992px) {
        .hp-news-content-bar {
          margin-top: 12.5rem; } }
    .hp-news-content-list {
      padding-bottom: 20px; }
      @media (min-width: 768px) {
        .hp-news-content-list {
          padding-bottom: 0; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .hp-news-content-list .row {
          margin: 0 -10px; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .hp-news-content-list [class^="col-"] {
          padding: 20px 10px 0; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .hp-news-content-list [class^="col-"]:first-of-type {
          padding-top: 10px; } }

.top-projects {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .top-projects-bg {
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.1;
    width: 100%;
    height: 100%; }
    .top-projects-bg-container {
      position: absolute;
      top: 0%;
      left: 0;
      width: 60%;
      height: 100%;
      display: none; }
      @media (min-width: 992px) {
        .top-projects-bg-container {
          display: flex; } }
  .top-projects-content {
    position: relative;
    z-index: 2;
    width: 100%; }
    .top-projects-content-text {
      padding-top: 20px; }
      @media (min-width: 768px) {
        .top-projects-content-text {
          padding-top: 13rem;
          max-width: 50rem; } }
    .top-projects-content-list {
      padding-top: 30px; }
      @media (min-width: 992px) {
        .top-projects-content-list {
          padding-top: 5rem; } }
      .top-projects-content-list .slick-list {
        width: 80vw;
        overflow: visible; }
        .top-projects-content-list .slick-list * {
          outline: 0 !important; }
      @media (min-width: 320px) and (max-width: 767px) {
        .top-projects-content-list .row {
          margin: 0 -10px; } }
      .top-projects-content-list [class^="col-"] {
        padding-top: 0;
        padding-bottom: 0; }
    .top-projects-content-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 60px; }
      @media (min-width: 992px) {
        .top-projects-content-bottom {
          margin-top: 16rem; } }
      .top-projects-content-bottom-text {
        max-width: 60rem; }
      .top-projects-content-bottom a {
        margin-top: 35px; }
        @media (min-width: 992px) {
          .top-projects-content-bottom a {
            margin-top: 2.5rem; } }
        @media (min-width: 1200px) {
          .top-projects-content-bottom a {
            margin-top: 2.22222rem; } }
        @media (min-width: 1400px) {
          .top-projects-content-bottom a {
            margin-top: 2rem; } }

.footer-newsletter-wrapper {
  padding-top: 17rem; }

.hp-top-image {
  position: relative;
  min-height: 350px; }
  @media (min-width: 1400px) {
    .hp-top-image {
      height: 100dvh; } }

.hp-top-image-bgc {
  position: relative; }
  @media (min-width: 1400px) {
    .hp-top-image-bgc {
      height: 100dvh; } }
  .hp-top-image-bgc img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover; }

.hp-top-image-text {
  text-align: center;
  z-index: 33;
  padding-bottom: 3rem;
  position: relative; }
  .hp-top-image-text:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14rem;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 22; }
  .hp-top-image-text .pux-container {
    position: relative;
    z-index: 33; }
  .hp-top-image-text h1 {
    font-weight: 900;
    text-shadow: rgba(0, 0, 0, 0.6) 1px 0 10px; }
    @media (min-width: 320px) {
      .hp-top-image-text h1 {
        font-size: 5.6rem; } }
    @media (min-width: 480px) {
      .hp-top-image-text h1 {
        font-size: 5.09091rem; } }
    @media (min-width: 545px) {
      .hp-top-image-text h1 {
        font-size: 4.66667rem; } }
    @media (min-width: 768px) {
      .hp-top-image-text h1 {
        font-size: 4.6rem; } }
    @media (min-width: 320px) {
      .hp-top-image-text h1 {
        line-height: 6.4rem; } }
    @media (min-width: 480px) {
      .hp-top-image-text h1 {
        line-height: 5.81818rem; } }
    @media (min-width: 545px) {
      .hp-top-image-text h1 {
        line-height: 5.33333rem; } }
    @media (min-width: 768px) {
      .hp-top-image-text h1 {
        line-height: 5rem; } }

.hp-top-image-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.hp-brand-slider {
  background: #f73c37;
  padding: 15px 0; }
  @media (min-width: 768px) {
    .hp-brand-slider {
      padding: 25px 0; } }
  .hp-brand-slider a:before {
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    font-size: 25px; }
    @media (min-width: 992px) {
      .hp-brand-slider a:before {
        font-size: 2.5rem; } }
  .hp-brand-slider a:hover:before, .hp-brand-slider a:focus:before {
    color: #000; }
  .hp-brand-slider a.ico-logo:before, .hp-brand-slider a.ico-header-logo:before {
    font-size: 22px; }
    @media (min-width: 992px) {
      .hp-brand-slider a.ico-logo:before, .hp-brand-slider a.ico-header-logo:before {
        font-size: 2.2rem; } }
  .hp-brand-slider a.ico-logo2:before {
    font-size: 26px; }
    @media (min-width: 992px) {
      .hp-brand-slider a.ico-logo2:before {
        font-size: 2.6rem; } }
  .hp-brand-slider a.ico-mobiltechlifts:before {
    font-size: 33px; }
    @media (min-width: 992px) {
      .hp-brand-slider a.ico-mobiltechlifts:before {
        font-size: 3.3rem; } }
  .hp-brand-slider a.ico-xstage:before {
    font-size: 25px; }
    @media (min-width: 992px) {
      .hp-brand-slider a.ico-xstage:before {
        font-size: 2.5rem; } }
  .hp-brand-slider a.ico-prolyte:before {
    font-size: 38px;
    display: inline-block; }
    @media (min-width: 1400px) {
      .hp-brand-slider a.ico-prolyte:before {
        font-size: 3.8rem; } }
  .hp-brand-slider a.ico-prolyft-logo:before, .hp-brand-slider a.ico-stagedex-logo:before {
    font-size: 38px;
    display: inline-block; }
    @media (min-width: 1400px) {
      .hp-brand-slider a.ico-prolyft-logo:before, .hp-brand-slider a.ico-stagedex-logo:before {
        font-size: 3.8rem; } }

.hp-brand-slider-inner {
  display: flex; }
  .hp-brand-slider-inner .link-item {
    flex-grow: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; }
  .hp-brand-slider-inner.slick-initialized {
    display: block; }

.hp-top-image + .hp-education {
  margin-top: 4rem; }

@media (min-width: 992px) {
  .career-top {
    display: flex;
    border-bottom: 1px solid #e6e6e6; } }

@media (min-width: 992px) {
  .career-top-content {
    flex: 0 1 60%;
    max-width: 60%;
    padding-bottom: 4.6rem;
    padding-top: 6rem; } }

.career-top-content .btn-red,
.career-top-content .btn-blue {
  margin-top: 10px;
  margin-right: 4px; }
  @media (min-width: 480px) {
    .career-top-content .btn-red,
    .career-top-content .btn-blue {
      margin-top: 4rem; } }

@media (min-width: 320px) and (max-width: 479px) {
  .career-top-content .btn-red {
    margin-top: 25px; } }

.career-top-image {
  border-bottom: 1px solid #e6e6e6; }
  @media (min-width: 992px) {
    .career-top-image {
      flex: 0 1 40%;
      max-width: 40%;
      border-bottom: 0px; } }

.career-top-font h1 {
  font-weight: 900;
  margin-bottom: 2rem; }
  @media (min-width: 320px) {
    .career-top-font h1 {
      font-size: 6rem; } }
  @media (min-width: 480px) {
    .career-top-font h1 {
      font-size: 5.45455rem; } }
  @media (min-width: 545px) {
    .career-top-font h1 {
      font-size: 5.4rem; } }
  @media (min-width: 320px) {
    .career-top-font h1 {
      line-height: 6.8rem; } }
  @media (min-width: 480px) {
    .career-top-font h1 {
      line-height: 6.18182rem; } }
  @media (min-width: 545px) {
    .career-top-font h1 {
      line-height: 5.8rem; } }

.career-top-font h2 {
  font-weight: 900; }
  @media (min-width: 320px) {
    .career-top-font h2 {
      font-size: 4.8rem; } }
  @media (min-width: 480px) {
    .career-top-font h2 {
      font-size: 4.36364rem; } }
  @media (min-width: 545px) {
    .career-top-font h2 {
      font-size: 4rem; } }
  @media (min-width: 768px) {
    .career-top-font h2 {
      font-size: 3.4rem; } }
  @media (min-width: 320px) {
    .career-top-font h2 {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    .career-top-font h2 {
      line-height: 5.09091rem; } }
  @media (min-width: 545px) {
    .career-top-font h2 {
      line-height: 4.66667rem; } }
  @media (min-width: 768px) {
    .career-top-font h2 {
      line-height: 3.8rem; } }

.career-top-font h3 {
  font-weight: 900; }
  @media (min-width: 320px) {
    .career-top-font h3 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .career-top-font h3 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .career-top-font h3 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .career-top-font h3 {
      font-size: 2.4rem; } }
  @media (min-width: 320px) {
    .career-top-font h3 {
      line-height: 4.4rem; } }
  @media (min-width: 480px) {
    .career-top-font h3 {
      line-height: 4rem; } }
  @media (min-width: 545px) {
    .career-top-font h3 {
      line-height: 3.66667rem; } }
  @media (min-width: 768px) {
    .career-top-font h3 {
      line-height: 2.93333rem; } }
  @media (min-width: 992px) {
    .career-top-font h3 {
      line-height: 2.8rem; } }

.career-top-font h4 {
  font-weight: 900; }
  @media (min-width: 320px) {
    .career-top-font h4 {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .career-top-font h4 {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .career-top-font h4 {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .career-top-font h4 {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .career-top-font h4 {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .career-top-font h4 {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    .career-top-font h4 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    .career-top-font h4 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    .career-top-font h4 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    .career-top-font h4 {
      line-height: 2.66667rem; } }
  @media (min-width: 992px) {
    .career-top-font h4 {
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    .career-top-font h4 {
      line-height: 2.22222rem; } }
  @media (min-width: 1400px) {
    .career-top-font h4 {
      line-height: 2.2rem; } }

.career-top-font ul li {
  padding-left: 35px; }
  .career-top-font ul li:before {
    border-radius: 0;
    width: 15px;
    height: 1px;
    top: 8px; }
    @media (min-width: 768px) {
      .career-top-font ul li:before {
        top: 9px; } }
    @media (min-width: 1200px) {
      .career-top-font ul li:before {
        width: 1.5rem;
        top: 1rem; } }

.btn-red,
.btn-blue,
.btn-base {
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: inherit;
  padding: 1rem 2.7rem;
  line-height: 1;
  font-weight: 900 !important; }
  @media (min-width: 1200px) {
    .btn-red,
    .btn-blue,
    .btn-base {
      height: 5rem; } }

.btn-red {
  background-color: #f73c37; }
  .btn-red:hover, .btn-red:focus {
    background-color: #f6241e; }

.btn-blue {
  background-color: #2977c9; }
  .btn-blue:hover, .btn-blue:focus {
    background-color: #256ab4; }

.top-base-padding {
  padding-top: 90px; }
  @media (min-width: 768px) {
    .top-base-padding {
      padding-top: 120px; } }
  @media (min-width: 992px) {
    .top-base-padding {
      padding-top: 12rem; } }

.career-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 5rem;
  position: relative;
  max-width: 600px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .career-image {
      padding-top: 0;
      height: 100%;
      margin: 0;
      max-width: none;
      align-items: flex-end; } }
  .career-image-tooltip {
    position: relative;
    align-self: flex-end;
    max-width: 310px;
    padding: 1.6rem 5rem 1.6rem 2.0rem;
    background-color: #000;
    color: #fff;
    font-size: 15px; }
    @media (min-width: 768px) {
      .career-image-tooltip {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .career-image-tooltip {
        max-width: 27rem;
        font-size: 1.4rem; } }
    .career-image-tooltip strong {
      display: inline-block;
      padding-bottom: 0.2rem; }
    .career-image-tooltip:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 100px;
      transform: translateY(100%);
      display: block;
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 25px solid #000; }
      @media (min-width: 480px) {
        .career-image-tooltip:after {
          left: 4rem; } }
      @media (min-width: 768px) {
        .career-image-tooltip:after {
          left: 2rem; } }
  .career-image img {
    margin-top: 1.4rem;
    max-width: 92%;
    max-height: 75vh; }
    @media (min-width: 992px) {
      .career-image img {
        max-height: none; } }

.career-card {
  display: block;
  color: #000;
  background-color: #f5f5f5;
  margin-top: 10px; }
  .career-card-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    min-height: 50px;
    display: flex;
    align-items: center;
    font-weight: 900;
    line-height: 1;
    padding: 4px 20px; }
    @media (min-width: 992px) {
      .career-card-title {
        min-height: 4rem;
        font-size: 1.6rem;
        padding: 0.4rem 2.2rem; } }
  .career-card-content {
    padding: 25px 20px 0;
    font-size: 15px;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .career-card-content {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .career-card-content {
        padding: 2rem 2.2rem 0;
        font-size: 1.5rem; } }
  .career-card-bottom {
    padding: 0 20px 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .career-card-bottom {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .career-card-bottom {
        padding: 0 2.2rem 2rem;
        font-size: 1.5rem; } }
    .career-card-bottom-place, .career-card-bottom-btn {
      margin-top: 20px; }
      @media (min-width: 992px) {
        .career-card-bottom-place, .career-card-bottom-btn {
          margin-top: 1.5rem; } }
    .career-card-bottom-place:before {
      content: "\e90f";
      font-size: 24px;
      vertical-align: sub; }
      @media (min-width: 992px) {
        .career-card-bottom-place:before {
          font-size: 2.2rem; } }
    .career-card-bottom-btn {
      color: #fff; }
  .career-card:hover, .career-card:focus {
    font-weight: 400;
    color: #000;
    text-decoration: none;
    background-color: #ebebeb; }
  .career-card.wide-cart {
    margin-top: 10px; }
    @media (min-width: 992px) {
      .career-card.wide-cart {
        margin-top: 2.8rem; } }
  .career-card.card-areafourindustries .career-card-title {
    background-color: #f73c37; }
  .career-card.card-areafourindustries .btn.btn-base {
    background-color: #f73c37; }
    .career-card.card-areafourindustries .btn.btn-base:hover, .career-card.card-areafourindustries .btn.btn-base:focus {
      background-color: #f51610; }
  .career-card.card-areafourindustries .circle-link:after {
    background-color: #f73c37;
    border-color: #f73c37;
    color: #fff; }
  .career-card.card-areafourindustries .circle-link:hover:after, .career-card.card-areafourindustries .circle-link:focus:after {
    background-color: #f51610;
    border-color: #f51610;
    color: #fff; }
  .career-card.card-milos .career-card-title {
    background-color: #ffd100; }
  .career-card.card-milos .btn.btn-base {
    background-color: #ffd100; }
    .career-card.card-milos .btn.btn-base:hover, .career-card.card-milos .btn.btn-base:focus {
      background-color: #d6b000; }
  .career-card.card-milos .circle-link:after {
    background-color: #ffd100;
    border-color: #ffd100;
    color: #fff; }
  .career-card.card-milos .circle-link:hover:after, .career-card.card-milos .circle-link:focus:after {
    background-color: #d6b000;
    border-color: #d6b000;
    color: #fff; }
  .career-card.card-jte .career-card-title {
    background-color: #1F21AB; }
  .career-card.card-jte .btn.btn-base {
    background-color: #1F21AB; }
    .career-card.card-jte .btn.btn-base:hover, .career-card.card-jte .btn.btn-base:focus {
      background-color: #191a88; }
  .career-card.card-jte .circle-link:after {
    background-color: #1F21AB;
    border-color: #1F21AB;
    color: #fff; }
  .career-card.card-jte .circle-link:hover:after, .career-card.card-jte .circle-link:focus:after {
    background-color: #191a88;
    border-color: #191a88;
    color: #fff; }
  .career-card.card-tomcat .career-card-title {
    background-color: #00853D; }
  .career-card.card-tomcat .btn.btn-base {
    background-color: #00853D; }
    .career-card.card-tomcat .btn.btn-base:hover, .career-card.card-tomcat .btn.btn-base:focus {
      background-color: #005c2a; }
  .career-card.card-tomcat .circle-link:after {
    background-color: #00853D;
    border-color: #00853D;
    color: #fff; }
  .career-card.card-tomcat .circle-link:hover:after, .career-card.card-tomcat .circle-link:focus:after {
    background-color: #005c2a;
    border-color: #005c2a;
    color: #fff; }
  .career-card.card-exe .career-card-title {
    background-color: #F73C37; }
  .career-card.card-exe .btn.btn-base {
    background-color: #F73C37; }
    .career-card.card-exe .btn.btn-base:hover, .career-card.card-exe .btn.btn-base:focus {
      background-color: #f51610; }
  .career-card.card-exe .circle-link:after {
    background-color: #F73C37;
    border-color: #F73C37;
    color: #fff; }
  .career-card.card-exe .circle-link:hover:after, .career-card.card-exe .circle-link:focus:after {
    background-color: #f51610;
    border-color: #f51610;
    color: #fff; }
  .career-card.card-mobiletechlifts .career-card-title {
    background-color: #004494; }
  .career-card.card-mobiletechlifts .btn.btn-base {
    background-color: #004494; }
    .career-card.card-mobiletechlifts .btn.btn-base:hover, .career-card.card-mobiletechlifts .btn.btn-base:focus {
      background-color: #00316b; }
  .career-card.card-mobiletechlifts .circle-link:after {
    background-color: #004494;
    border-color: #004494;
    color: #fff; }
  .career-card.card-mobiletechlifts .circle-link:hover:after, .career-card.card-mobiletechlifts .circle-link:focus:after {
    background-color: #00316b;
    border-color: #00316b;
    color: #fff; }
  .career-card.card-litec .career-card-title {
    background-color: #043a63; }
  .career-card.card-litec .btn.btn-base {
    background-color: #043a63; }
    .career-card.card-litec .btn.btn-base:hover, .career-card.card-litec .btn.btn-base:focus {
      background-color: #02233c; }
  .career-card.card-litec .circle-link:after {
    background-color: #043a63;
    border-color: #043a63;
    color: #fff; }
  .career-card.card-litec .circle-link:hover:after, .career-card.card-litec .circle-link:focus:after {
    background-color: #02233c;
    border-color: #02233c;
    color: #fff; }
  .career-card.card-xstage .career-card-title {
    background-color: #191919; }
  .career-card.card-xstage .btn.btn-base {
    background-color: #191919; }
    .career-card.card-xstage .btn.btn-base:hover, .career-card.card-xstage .btn.btn-base:focus {
      background-color: #050505; }
  .career-card.card-xstage .circle-link:after {
    background-color: #191919;
    border-color: #191919;
    color: #fff; }
  .career-card.card-xstage .circle-link:hover:after, .career-card.card-xstage .circle-link:focus:after {
    background-color: #050505;
    border-color: #050505;
    color: #fff; }
  .career-card.card-xtruss .career-card-title {
    background-color: #191919; }
  .career-card.card-xtruss .btn.btn-base {
    background-color: #191919; }
    .career-card.card-xtruss .btn.btn-base:hover, .career-card.card-xtruss .btn.btn-base:focus {
      background-color: #050505; }
  .career-card.card-xtruss .circle-link:after {
    background-color: #191919;
    border-color: #191919;
    color: #fff; }
  .career-card.card-xtruss .circle-link:hover:after, .career-card.card-xtruss .circle-link:focus:after {
    background-color: #050505;
    border-color: #050505;
    color: #fff; }
  .career-card.card-prolyte .career-card-title {
    background-color: #44a1d6; }
  .career-card.card-prolyte .btn.btn-base {
    background-color: #44a1d6; }
    .career-card.card-prolyte .btn.btn-base:hover, .career-card.card-prolyte .btn.btn-base:focus {
      background-color: #2b8ec6; }
  .career-card.card-prolyte .circle-link:after {
    background-color: #44a1d6;
    border-color: #44a1d6;
    color: #fff; }
  .career-card.card-prolyte .circle-link:hover:after, .career-card.card-prolyte .circle-link:focus:after {
    background-color: #2b8ec6;
    border-color: #2b8ec6;
    color: #fff; }
  .career-card.card-stagedex .career-card-title {
    background-color: #ff4d00; }
  .career-card.card-stagedex .btn.btn-base {
    background-color: #ff4d00; }
    .career-card.card-stagedex .btn.btn-base:hover, .career-card.card-stagedex .btn.btn-base:focus {
      background-color: #d64100; }
  .career-card.card-stagedex .circle-link:after {
    background-color: #ff4d00;
    border-color: #ff4d00;
    color: #fff; }
  .career-card.card-stagedex .circle-link:hover:after, .career-card.card-stagedex .circle-link:focus:after {
    background-color: #d64100;
    border-color: #d64100;
    color: #fff; }
  .career-card.card-prolyft .career-card-title {
    background-color: #8cbf30; }
  .career-card.card-prolyft .btn.btn-base {
    background-color: #8cbf30; }
    .career-card.card-prolyft .btn.btn-base:hover, .career-card.card-prolyft .btn.btn-base:focus {
      background-color: #749e28; }
  .career-card.card-prolyft .circle-link:after {
    background-color: #8cbf30;
    border-color: #8cbf30;
    color: #fff; }
  .career-card.card-prolyft .circle-link:hover:after, .career-card.card-prolyft .circle-link:focus:after {
    background-color: #749e28;
    border-color: #749e28;
    color: #fff; }
  .career-card.card-afi .career-card-title {
    background-color: #f73c37; }
  .career-card.card-afi .btn.btn-base {
    background-color: #f73c37; }
    .career-card.card-afi .btn.btn-base:hover, .career-card.card-afi .btn.btn-base:focus {
      background-color: #f51610; }
  .career-card.card-afi .circle-link:after {
    background-color: #f73c37;
    border-color: #f73c37;
    color: #fff; }
  .career-card.card-afi .circle-link:hover:after, .career-card.card-afi .circle-link:focus:after {
    background-color: #f51610;
    border-color: #f51610;
    color: #fff; }

.career-list {
  padding: 2rem 0 4rem; }
  @media (min-width: 992px) {
    .career-list {
      padding: 1rem 0 3rem; } }
  @media (min-width: 992px) {
    .career-list-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; } }
  .career-list-filter-title, .career-list-filter-content {
    margin-top: 3rem; }

.career-filter {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  opacity: 0; }
  @media (min-width: 992px) {
    .career-filter {
      margin-top: 1rem; } }
  @media (min-width: 992px) {
    .career-filter {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .career-filter {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .career-filter {
      font-size: 1.8rem; } }
  .career-filter.active {
    opacity: 1; }
  .career-filter-title {
    flex: 0 0 auto;
    padding-right: 10px;
    font-size: 15px;
    transform: translateY(4%); }
    @media (min-width: 768px) {
      .career-filter-title {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .career-filter-title {
        font-size: 1.4rem; } }
  .career-filter-list {
    position: relative;
    overflow: hidden; }
    .career-filter-list-inner {
      display: flex;
      justify-content: flex-start;
      overflow: auto; }
    .career-filter-list .slick-prev {
      display: none !important; }
    .career-filter-list.active .career-filter-item:last-of-type {
      padding-right: 150px; }
    .career-filter-list .arrow-next {
      display: none; }
      .career-filter-list .arrow-next.active {
        display: flex; }
  .career-filter-item {
    cursor: pointer;
    margin-left: 16px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    user-select: none;
    outline: 0;
    white-space: nowrap;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 700; }
    @media (min-width: 768px) {
      .career-filter-item {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .career-filter-item {
        margin-left: 1.6rem;
        font-size: 1.6rem; } }
    .career-filter-item:hover, .career-filter-item:focus, .career-filter-item.active {
      text-decoration: underline; }
    .career-filter-item[data-filter="areafourindustries"]:hover, .career-filter-item[data-filter="areafourindustries"]:focus, .career-filter-item[data-filter="areafourindustries"].active {
      color: #f73c37; }
    .career-filter-item[data-filter="milos"]:hover, .career-filter-item[data-filter="milos"]:focus, .career-filter-item[data-filter="milos"].active {
      color: #ffd100; }
    .career-filter-item[data-filter="jte"]:hover, .career-filter-item[data-filter="jte"]:focus, .career-filter-item[data-filter="jte"].active {
      color: #1F21AB; }
    .career-filter-item[data-filter="tomcat"]:hover, .career-filter-item[data-filter="tomcat"]:focus, .career-filter-item[data-filter="tomcat"].active {
      color: #00853D; }
    .career-filter-item[data-filter="exe"]:hover, .career-filter-item[data-filter="exe"]:focus, .career-filter-item[data-filter="exe"].active {
      color: #F73C37; }
    .career-filter-item[data-filter="mobiletechlifts"]:hover, .career-filter-item[data-filter="mobiletechlifts"]:focus, .career-filter-item[data-filter="mobiletechlifts"].active {
      color: #004494; }
    .career-filter-item[data-filter="litec"]:hover, .career-filter-item[data-filter="litec"]:focus, .career-filter-item[data-filter="litec"].active {
      color: #043a63; }
    .career-filter-item[data-filter="xstage"]:hover, .career-filter-item[data-filter="xstage"]:focus, .career-filter-item[data-filter="xstage"].active {
      color: #191919; }
    .career-filter-item[data-filter="xtruss"]:hover, .career-filter-item[data-filter="xtruss"]:focus, .career-filter-item[data-filter="xtruss"].active {
      color: #191919; }
    .career-filter-item[data-filter="prolyte"]:hover, .career-filter-item[data-filter="prolyte"]:focus, .career-filter-item[data-filter="prolyte"].active {
      color: #44a1d6; }
    .career-filter-item[data-filter="stagedex"]:hover, .career-filter-item[data-filter="stagedex"]:focus, .career-filter-item[data-filter="stagedex"].active {
      color: #ff4d00; }
    .career-filter-item[data-filter="prolyft"]:hover, .career-filter-item[data-filter="prolyft"]:focus, .career-filter-item[data-filter="prolyft"].active {
      color: #8cbf30; }
    .career-filter-item[data-filter="afi"]:hover, .career-filter-item[data-filter="afi"]:focus, .career-filter-item[data-filter="afi"].active {
      color: #f73c37; }

.career-reference {
  border-top: 1px solid #e6e6e6; }
  .career-reference-item {
    text-align: center;
    margin-top: 25px;
    padding: 0 10vw; }
    @media (min-width: 545px) {
      .career-reference-item {
        padding: 0 5vw; } }
    @media (min-width: 768px) {
      .career-reference-item {
        font-size: 16px;
        margin-top: 30px;
        padding: 0; } }
    @media (min-width: 992px) {
      .career-reference-item {
        font-size: 1.5rem;
        margin-top: 3rem; } }
    .career-reference-item-image {
      position: relative;
      overflow: hidden; }
      .career-reference-item-image:before {
        content: "";
        display: block;
        padding-top: 45%; }
      .career-reference-item-image img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        overflow: hidden; }
    .career-reference-item-title {
      margin-top: 20px; }
      @media (min-width: 1400px) {
        .career-reference-item-title {
          margin-top: 2rem; } }
    .career-reference-item-position, .career-reference-item-text {
      margin-top: 8px; }
      @media (min-width: 1400px) {
        .career-reference-item-position, .career-reference-item-text {
          margin-top: 0.8rem; } }

.bottom-text {
  text-align: center;
  max-width: 60rem;
  padding: 50px 0 30px;
  position: relative;
  margin: auto; }
  @media (min-width: 768px) {
    .bottom-text {
      padding: 8rem 0 3rem; } }

.brand-location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px; }
  @media (min-width: 1200px) {
    .brand-location {
      font-size: 1.5rem; } }
  .brand-location-company, .brand-location-state {
    margin-top: 25px;
    margin-right: 2rem; }
    @media (min-width: 1200px) {
      .brand-location-company, .brand-location-state {
        margin-top: 2.5rem; } }
  .brand-location-company {
    text-transform: uppercase;
    font-weight: 900; }
  .brand-location-state:before {
    content: "\e90f";
    font-size: 24px;
    vertical-align: sub;
    line-height: inherit;
    display: inline-block;
    margin-right: 4px; }
    @media (min-width: 992px) {
      .brand-location-state:before {
        font-size: 2.2rem; } }

.career-back .btn {
  position: relative;
  color: #000;
  transform: none; }

.career-detail {
  border: 0;
  font-size: 14px;
  line-height: 1.45; }
  @media (min-width: 768px) {
    .career-detail {
      font-size: 15px; } }
  @media (min-width: 992px) {
    .career-detail {
      font-size: 1.45rem; } }
  .career-detail .career-top-content {
    padding-top: 0;
    padding-bottom: 0; }
  .career-detail .career-top-image {
    border: 0; }
  .career-detail .career-image {
    justify-content: flex-start;
    padding-top: 6rem; }
  .career-detail big {
    font-size: 16px;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .career-detail big {
        font-size: 17px; } }
    @media (min-width: 992px) {
      .career-detail big {
        font-size: 1.8rem; } }

.black-form .row {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }
  .black-form .row [class*="col-"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }

.black-form .ErrorLabel {
  padding-bottom: 2rem; }

.black-form input,
.black-form textarea {
  border-color: #000;
  color: #000;
  font-weight: 400;
  padding-left: 2rem;
  padding-right: 50px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 320px) {
    .black-form input,
    .black-form textarea {
      height: 9rem; } }
  @media (min-width: 480px) {
    .black-form input,
    .black-form textarea {
      height: 8.18182rem; } }
  @media (min-width: 545px) {
    .black-form input,
    .black-form textarea {
      height: 7.5rem; } }
  @media (min-width: 768px) {
    .black-form input,
    .black-form textarea {
      height: 6rem; } }
  @media (min-width: 992px) {
    .black-form input,
    .black-form textarea {
      height: 5.625rem; } }
  @media (min-width: 1200px) {
    .black-form input,
    .black-form textarea {
      height: 5rem; } }
  @media (min-width: 1400px) {
    .black-form input,
    .black-form textarea {
      height: 4.5rem; } }
  .black-form input::-webkit-input-placeholder,
  .black-form textarea::-webkit-input-placeholder {
    color: #000; }
  .black-form input:-moz-placeholder,
  .black-form textarea:-moz-placeholder {
    color: #000; }
  .black-form input::-moz-placeholder,
  .black-form textarea::-moz-placeholder {
    color: #000; }
  .black-form input:-ms-input-placeholder,
  .black-form textarea:-ms-input-placeholder {
    color: #000; }
  .black-form input:focus,
  .black-form textarea:focus {
    border: 1px solid #f73c37;
    color: #000; }

.black-form textarea {
  height: 120px;
  resize: none; }

.black-form .InfoLabel {
  font-size: 16px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .black-form .InfoLabel {
      font-size: 1.8rem; } }

.black-form .Error input[type=text],
.black-form .Error textarea,
.black-form .form-validation-error input[type=text],
.black-form .form-validation-error textarea {
  border: 1px solid #f73c37 !important; }

.black-form .Error:after,
.black-form .form-validation-error:after {
  background-color: #f73c37;
  color: #fff; }

.black-form [class^="col-"],
.black-form .form-bottom > * {
  padding-top: 0;
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .black-form [class^="col-"],
    .black-form .form-bottom > * {
      padding-bottom: 3rem; } }

.black-form .file label,
.black-form input[type=submit] {
  font-weight: 900 !important;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: inherit;
  padding: 1rem 2.7rem;
  line-height: 1;
  white-space: nowrap;
  max-width: none; }
  @media (min-width: 1200px) {
    .black-form .file label,
    .black-form input[type=submit] {
      height: 5rem; } }

.black-form .file label {
  background-color: #000;
  text-transform: none;
  width: auto; }

.black-form .file-name {
  display: inline-block;
  word-break: break-all;
  word-wrap: break-word; }

.black-form input[type=submit] {
  color: #fff;
  background-color: #f73c37; }
  .black-form input[type=submit]:hover, .black-form input[type=submit]:focus {
    background-color: #f6241e; }

.black-form .form-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.black-form h1,
.black-form h2,
.black-form h3,
.black-form h4 {
  margin-bottom: 0; }

.color-areafourindustries {
  color: #f73c37; }

.color-milos {
  color: #ffd100; }

.color-jte {
  color: #1F21AB; }

.color-tomcat {
  color: #00853D; }

.color-exe {
  color: #F73C37; }

.color-mobiletechlifts {
  color: #004494; }

.color-litec {
  color: #043a63; }

.color-xstage {
  color: #191919; }

.color-xtruss {
  color: #191919; }

.color-prolyte {
  color: #44a1d6; }

.color-stagedex {
  color: #ff4d00; }

.color-prolyft {
  color: #8cbf30; }

.color-afi {
  color: #f73c37; }

.form-page-title {
  display: none; }

.talents-page {
  background: #fff; }
  .talents-page .btn-primary {
    background-color: #f73c37;
    color: #fff;
    border: none;
    padding: 1.4rem 3.5rem;
    border-radius: 5px; }
    .talents-page .btn-primary:focus, .talents-page .btn-primary:hover {
      background-color: #f1100a; }
  .talents-page h2,
  .talents-page h3 {
    color: #000; }
  .talents-page .h1 {
    color: #fff; }
  .talents-page .marker {
    color: #f73c37; }
  .talents-page .scroll-btn {
    width: 40px;
    height: 40px;
    background-color: #f73c37;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: 5rem;
    transform: translateX(-50%); }
    .talents-page .scroll-btn:before {
      content: "\e903";
      font-size: clamp(25px, 2.5rem, 2.5rem);
      font-weight: 700; }
    .talents-page .scroll-btn span {
      display: none; }
    .talents-page .scroll-btn:focus, .talents-page .scroll-btn:hover {
      text-decoration: none; }
    .talents-page .scroll-btn:hover {
      background-color: #f1100a; }
  .talents-page .selectric {
    max-width: 200px;
    min-width: 200px;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    border: 1px solid #A5A5A5;
    text-align: left; }
    @media (min-width: 768px) {
      .talents-page .selectric {
        max-width: 280px;
        min-width: 280px; } }
  .talents-page .selectric .button:after,
  .talents-page .selectric .label {
    color: #000;
    font-weight: 500; }
  .talents-page .selectric-items {
    border-radius: 5px;
    font-weight: 500;
    padding: 1rem 0;
    background-color: #fff;
    border: 1px solid #A5A5A5; }
  .talents-page .selectric-items .selectric-scroll {
    background-color: #fff; }
  .talents-page .selectric-items li:nth-child(odd) {
    background-color: #fff; }
  .talents-page .selectric-items li {
    color: #000; }
  .talents-page .selectric-items li:hover,
  .talents-page .selectric-items li:nth-child(odd):hover {
    background-color: #fff; }
  .talents-page .selectric-items li.highlighted {
    background-color: #fff;
    color: #A5A5A5; }
  .talents-page .selectric-open .selectric {
    border: 1px solid #A5A5A5; }
  .talents-page .selectric-open .selectric-items {
    border: 1px solid #A5A5A5; }
  .talents-page .selectric .button:after {
    content: "\e903";
    font-weight: 700;
    transform: rotate(0deg);
    font-size: 35px; }
  .talents-page .talents-top-drop {
    display: flex;
    justify-content: flex-end; }
  .talents-page .bread-container {
    display: none; }
    @media (min-width: 992px) {
      .talents-page .bread-container {
        display: block; } }
  .talents-page .bread-container .arrow-next.black-mode {
    display: none; }
  .talents-page .talents-bottom-text {
    text-align: center;
    padding: 50px 0; }
    @media (min-width: 992px) {
      .talents-page .talents-bottom-text {
        padding: 80px 0; } }

.job-top {
  width: 100%;
  height: 66.5rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-position: top center !important;
  background-size: cover !important;
  position: relative; }
  .job-top .job-top-inner {
    width: 100%; }
  .job-top .job-top-btn {
    margin-top: 3rem; }
  .job-top .job-top-absolute {
    position: absolute;
    left: 0;
    top: 13rem;
    width: 100%; }
  .job-top .job-top-setting {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 992px) {
      .job-top .job-top-setting {
        justify-content: space-between; } }

.talents-top {
  width: 100%;
  height: 70vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-position: top center !important;
  background-size: cover !important;
  position: relative; }
  @media (min-width: 992px) {
    .talents-top {
      height: 100vh; } }
  .talents-top .talents-top-inner {
    width: 100%;
    text-align: center; }
  .talents-top .talents-top-btn {
    margin-top: 3rem; }
  .talents-top .talents-top-absolute {
    position: absolute;
    left: 0;
    top: 13rem;
    width: 100%; }
  .talents-top .talents-top-setting {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 992px) {
      .talents-top .talents-top-setting {
        justify-content: space-between; } }

.talents-numbers {
  padding: 9rem 0;
  text-align: center; }

.talents-numbers-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem; }
  .talents-numbers-inner .talents-number-item {
    width: 100%;
    background-color: #f5f5f5;
    padding: 30px 5px; }
    @media (min-width: 545px) {
      .talents-numbers-inner .talents-number-item {
        width: 50%; } }
    @media (min-width: 768px) {
      .talents-numbers-inner .talents-number-item {
        width: 33.33%; } }
    .talents-numbers-inner .talents-number-item:nth-child(even) {
      background-color: #ededed; }
    @media (min-width: 545px) and (max-width: 767px) {
      .talents-numbers-inner .talents-number-item:nth-child(3) {
        background-color: #ededed; }
      .talents-numbers-inner .talents-number-item:nth-child(4) {
        background-color: #f5f5f5; } }
  .talents-numbers-inner .talents-number-item-ico {
    margin-bottom: 1rem; }
  .talents-numbers-inner .talents-number-item-number {
    color: #f73c37;
    font-weight: 900; }
    @media (min-width: 320px) and (max-width: 479px) {
      .talents-numbers-inner .talents-number-item-number {
        font-size: 34px;
        line-height: 38px; } }
    @media (min-width: 320px) {
      .talents-numbers-inner .talents-number-item-number {
        font-size: 7.2rem; } }
    @media (min-width: 320px) {
      .talents-numbers-inner .talents-number-item-number {
        line-height: 7.6rem; } }
  .talents-numbers-inner .talents-number-item-title {
    color: #000;
    font-weight: 900;
    margin-top: 1rem; }
    @media (min-width: 320px) {
      .talents-numbers-inner .talents-number-item-title {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .talents-numbers-inner .talents-number-item-title {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .talents-numbers-inner .talents-number-item-title {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .talents-numbers-inner .talents-number-item-title {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .talents-numbers-inner .talents-number-item-title {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .talents-numbers-inner .talents-number-item-title {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      .talents-numbers-inner .talents-number-item-title {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      .talents-numbers-inner .talents-number-item-title {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      .talents-numbers-inner .talents-number-item-title {
        line-height: 2.66667rem; } }
    @media (min-width: 992px) {
      .talents-numbers-inner .talents-number-item-title {
        line-height: 2.6rem; } }

.talents-logos {
  background-color: #F5F5F5;
  padding: 9rem 0 12rem 0;
  position: relative; }
  .talents-logos h2 br {
    display: none; }
  @media (min-width: 768px) {
    .talents-logos h2 {
      white-space: nowrap; }
      .talents-logos h2 br {
        display: block; } }

.talents-logos-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem; }
  @media (min-width: 768px) {
    .talents-logos-inner {
      flex-wrap: nowrap;
      justify-content: space-between; } }

.talents-logos-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem 5rem;
  margin-bottom: 3rem; }
  .talents-logos-images img {
    filter: grayscale(100%);
    max-height: 40px; }
  @media (min-width: 768px) {
    .talents-logos-images {
      margin-bottom: 0;
      justify-content: flex-start; } }

.talents-image {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center !important;
  background-size: cover !important;
  background-color: #A5A5A5;
  position: relative; }
  @media (min-width: 545px) {
    .talents-image {
      height: 70vh; } }
  @media (min-width: 992px) {
    .talents-image {
      height: 100vh; } }
  @media (min-width: 545px) {
    .talents-image.talents-image-small {
      height: 70vh;
      max-height: 610px; } }
  @media (min-width: 992px) {
    .talents-image.talents-image-small {
      height: 100vh;
      max-height: 810px; } }
  .talents-image .talents-image-inner {
    width: 100%; }
  .talents-image .talents-image-desc {
    font-weight: 900;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
    margin-top: 2rem; }
    @media (min-width: 320px) {
      .talents-image .talents-image-desc {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .talents-image .talents-image-desc {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .talents-image .talents-image-desc {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .talents-image .talents-image-desc {
        font-size: 2.4rem; } }
    @media (min-width: 320px) {
      .talents-image .talents-image-desc {
        line-height: 4.4rem; } }
    @media (min-width: 480px) {
      .talents-image .talents-image-desc {
        line-height: 4rem; } }
    @media (min-width: 545px) {
      .talents-image .talents-image-desc {
        line-height: 3.66667rem; } }
    @media (min-width: 768px) {
      .talents-image .talents-image-desc {
        line-height: 2.93333rem; } }
    @media (min-width: 992px) {
      .talents-image .talents-image-desc {
        line-height: 2.8rem; } }
  .talents-image h2 {
    color: #fff; }

.talents-testimonial {
  padding: 8rem 0; }

.talents-testimonial-item {
  max-width: 1055px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  @media (min-width: 545px) {
    .talents-testimonial-item {
      flex-wrap: nowrap; } }
  .talents-testimonial-item .talents-testimonial-item-image {
    min-width: 165px;
    width: 165px;
    margin: 0 auto 6rem auto; }
    @media (min-width: 545px) {
      .talents-testimonial-item .talents-testimonial-item-image {
        margin: 0 4rem 0 0; } }
  .talents-testimonial-item .talents-testimonial-item-desc {
    background-color: #A5A5A5;
    padding: 2.7rem 2.7rem 1.5rem 2.7rem;
    border-radius: 10px;
    position: relative;
    width: 100%; }
    @media (min-width: 545px) {
      .talents-testimonial-item .talents-testimonial-item-desc {
        width: auto;
        border-radius: 0px 10px 10px 10px; } }
    .talents-testimonial-item .talents-testimonial-item-desc:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 20px 20px;
      border-color: transparent transparent #A5A5A5 transparent;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 545px) {
        .talents-testimonial-item .talents-testimonial-item-desc:before {
          top: 0;
          left: -20px;
          transform: translateX(0);
          border-width: 0 20px 20px 0;
          border-color: transparent #A5A5A5 transparent transparent; } }
  .talents-testimonial-item .talents-testimonial-item-bottom {
    margin-top: 10px; }
  .talents-testimonial-item .talents-testimonial-item-name {
    display: inline-block;
    color: #000;
    font-weight: 600; }

.jobs-positions {
  padding: 10rem 0 3rem 0;
  text-align: center; }
  .jobs-positions .jobs-title {
    text-align: left;
    color: #000;
    font-weight: 900;
    margin-bottom: 3rem; }
    @media (min-width: 320px) {
      .jobs-positions .jobs-title {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .jobs-positions .jobs-title {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .jobs-positions .jobs-title {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .jobs-positions .jobs-title {
        font-size: 2.4rem; } }
    @media (min-width: 320px) {
      .jobs-positions .jobs-title {
        line-height: 4.4rem; } }
    @media (min-width: 480px) {
      .jobs-positions .jobs-title {
        line-height: 4rem; } }
    @media (min-width: 545px) {
      .jobs-positions .jobs-title {
        line-height: 3.66667rem; } }
    @media (min-width: 768px) {
      .jobs-positions .jobs-title {
        line-height: 2.93333rem; } }
    @media (min-width: 992px) {
      .jobs-positions .jobs-title {
        line-height: 2.8rem; } }
  .jobs-positions .jobs-position-container {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 4rem;
    margin-bottom: 5rem;
    grid-template-columns: 1fr; }
    @media (min-width: 545px) {
      .jobs-positions .jobs-position-container {
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 992px) {
      .jobs-positions .jobs-position-container {
        grid-template-columns: 1fr 1fr 1fr; } }
  .jobs-positions .job-item {
    width: 100%;
    color: #fff;
    text-align: left; }
    .jobs-positions .job-item .job-item-image {
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      position: relative; }
      .jobs-positions .job-item .job-item-image img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/10;
        object-fit: cover; }
      .jobs-positions .job-item .job-item-image:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(247, 60, 55, 0.8);
        opacity: 0;
        transition: 250ms; }
    .jobs-positions .job-item .job-item-bottom {
      background-color: #f73c37;
      border-radius: 0px 0px 10px 10px;
      padding: 2.2rem; }
    .jobs-positions .job-item .job-item-name {
      font-weight: 900;
      margin-bottom: 1rem; }
      @media (min-width: 320px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .jobs-positions .job-item .job-item-name {
          font-size: 1.8rem; } }
      @media (min-width: 320px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 2.22222rem; } }
      @media (min-width: 1400px) {
        .jobs-positions .job-item .job-item-name {
          line-height: 2.2rem; } }
    .jobs-positions .job-item:focus, .jobs-positions .job-item:hover {
      text-decoration: none;
      font-weight: 400; }
      .jobs-positions .job-item:focus .job-item-image:before, .jobs-positions .job-item:hover .job-item-image:before {
        opacity: 1; }

.jobs-cta {
  padding: 2rem 0;
  text-align: center; }
  .jobs-cta .jobs-cta-btn {
    margin-top: 2rem; }

.jobs-testimonial {
  padding: 4rem 0 0 0; }

.jobs-slider {
  position: relative; }
  .jobs-slider .slick-arrow {
    background: none;
    position: absolute;
    z-index: 88;
    font-size: 0;
    bottom: 5rem;
    color: #fff;
    padding: 0;
    border: none;
    cursor: pointer; }
    .jobs-slider .slick-arrow:hover {
      color: #f73c37; }
    .jobs-slider .slick-arrow:focus {
      outline: 0; }
    @media (min-width: 768px) {
      .jobs-slider .slick-arrow {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%); } }
  .jobs-slider .slick-prev {
    left: 7rem; }
    .jobs-slider .slick-prev:before {
      content: "\e90e";
      font-size: clamp(40px, 4.2rem, 4.2rem); }
  .jobs-slider .slick-next {
    right: 7rem; }
    .jobs-slider .slick-next:before {
      content: "\e907";
      font-size: clamp(40px, 4.2rem, 4.2rem); }

.job-layout {
  color: #000;
  padding-bottom: 1px;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .job-layout {
      flex-wrap: nowrap; } }
  .job-layout h3 {
    margin: 5.5rem 0 2rem 0; }
  .job-layout .job-layout-content {
    width: 100%;
    max-width: 920px; }
  .job-layout .job-layout-sidebar {
    max-width: 540px;
    margin: 40px auto 0 auto; }
    @media (min-width: 768px) {
      .job-layout .job-layout-sidebar {
        margin: 0 0 0 11rem; } }

main .job-layout ul li {
  margin: 0 0 0.5rem 0; }

main .job-layout ul li:before {
  top: 1.3rem; }

.job-interviews {
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 4rem;
  margin-bottom: 6rem;
  position: relative;
  z-index: 88; }
  @media (min-width: 768px) {
    .job-interviews {
      margin-top: -20rem; } }
  @media (min-width: 1200px) {
    .job-interviews {
      margin-top: -30rem; } }
  .job-interviews h4 {
    margin: 0; }
  .job-interviews .job-interview-item {
    border-bottom: 1px solid #a5a5a5;
    font-weight: 500;
    padding: 2rem 0; }
    .job-interviews .job-interview-item span {
      display: block;
      color: #f73c37;
      font-weight: 900; }

.job-personal {
  margin-bottom: 6rem; }
  .job-personal img {
    border-radius: 10px; }

.talents-top .breadcrumbs a,
.job-top .breadcrumbs a {
  color: #f73c37;
  font-weight: 900;
  font-size: 18px; }
  .talents-top .breadcrumbs a:focus, .talents-top .breadcrumbs a:hover,
  .job-top .breadcrumbs a:focus,
  .job-top .breadcrumbs a:hover {
    text-decoration: underline; }

.talents-top .breadcrumbs-separator:before,
.job-top .breadcrumbs-separator:before {
  content: "/";
  font-size: 18px; }

.talents-top .CMSBreadCrumbsCurrentItem,
.job-top .CMSBreadCrumbsCurrentItem {
  font-size: 18px; }

.job-form {
  background-color: #f73c37;
  padding: 4rem;
  border-radius: 10px; }
  .job-form .row {
    display: block; }
  .job-form .col-12,
  .job-form .col-sm-6 {
    padding: 0 2.5rem 20px 2.5rem;
    width: 100% !important;
    max-width: 100% !important; }
  .job-form .form-control,
  .job-form input[type="text"] {
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    border: 1px solid #fff;
    font-weight: 500; }
    .job-form .form-control::-webkit-input-placeholder,
    .job-form input[type="text"]::-webkit-input-placeholder {
      color: #000; }
    .job-form .form-control:-moz-placeholder,
    .job-form input[type="text"]:-moz-placeholder {
      color: #000; }
    .job-form .form-control::-moz-placeholder,
    .job-form input[type="text"]::-moz-placeholder {
      color: #000; }
    .job-form .form-control:-ms-input-placeholder,
    .job-form input[type="text"]:-ms-input-placeholder {
      color: #000; }
    .job-form .form-control:focus,
    .job-form input[type="text"]:focus {
      outline: 0 !important;
      background: #fff;
      color: #000;
      border-color: #000; }
  .job-form .file label {
    width: 100%;
    background-color: #A5A5A5;
    color: #fff;
    text-transform: none;
    font-weight: 900;
    border-radius: 5px;
    max-width: 100%;
    padding: 1.4rem; }
    .job-form .file label:hover {
      background-color: #8c8c8c; }
  .job-form .file .file-name {
    background-color: #fff;
    width: 100%;
    display: block;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center; }
  .job-form input[type="submit"] {
    background-color: #fff;
    color: #f73c37;
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    padding: 1.4rem; }
    .job-form input[type="submit"]:hover {
      background-color: #e6e6e6; }

.solution-top {
  min-height: 160px;
  background-position: top center !important;
  background-size: cover !important;
  position: relative; }
  .solution-top .pux-container {
    width: 100%; }
  .solution-top .solution-bgc {
    position: relative;
    width: 100%; }
    .solution-top .solution-bgc img {
      width: 100%;
      height: auto !important; }
    .solution-top .solution-bgc:after {
      content: "";
      width: 100%;
      height: 100px;
      background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      bottom: 0;
      left: 0; }
  .solution-top .solution-absolute {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
    @media (min-width: 768px) {
      .solution-top .solution-absolute {
        position: absolute; } }
    .solution-top .solution-absolute .pux-container {
      width: 100%; }
  .solution-top .solution-top-inner {
    max-width: 1220px;
    margin: 0 auto;
    font-size: 20px;
    line-height: normal;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center; }
    @media (min-width: 768px) {
      .solution-top .solution-top-inner {
        font-size: 25px; } }
    @media (min-width: 992px) {
      .solution-top .solution-top-inner {
        font-size: 31px; } }
    .solution-top .solution-top-inner h1 {
      font-size: 25px;
      line-height: normal;
      font-weight: 900;
      margin: 0 0 20px 0; }
      @media (min-width: 768px) {
        .solution-top .solution-top-inner h1 {
          font-size: 40px;
          letter-spacing: 1.2px;
          margin: 0 0 30px 0; } }
      @media (min-width: 992px) {
        .solution-top .solution-top-inner h1 {
          font-size: 74px;
          letter-spacing: 1.5px;
          margin: 0 0 50px 0; } }
      @media (min-width: 1200px) {
        .solution-top .solution-top-inner h1 {
          margin: 0 0 70px 0; } }
  .solution-top .solution-breacrumbs {
    position: absolute;
    left: 0;
    top: 8.5rem;
    width: 100%;
    padding-top: 35px;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    display: none; }
    @media (min-width: 768px) {
      .solution-top .solution-breacrumbs {
        display: block; } }
    .solution-top .solution-breacrumbs .breadcrumbs-separator {
      margin: 0 5px; }
    .solution-top .solution-breacrumbs .CMSBreadCrumbsCurrentItem {
      color: #f73c37;
      font-weight: 500; }
    .solution-top .solution-breacrumbs a {
      font-weight: 500; }
      .solution-top .solution-breacrumbs a:hover {
        font-weight: 500; }

.anchor-menu {
  display: flex;
  margin: 10px 0 25px 0;
  flex-wrap: wrap;
  gap: 3rem; }
  @media (min-width: 768px) {
    .anchor-menu {
      flex-wrap: nowrap;
      margin: 10px 0 50px 0; } }
  .anchor-menu .anchor-item {
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .anchor-menu .anchor-item {
        font-size: 18px; } }
    .anchor-menu .anchor-item:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      opacity: 0;
      transition: 250ms; }
    .anchor-menu .anchor-item.milos:before {
      background-color: #ffd100; }
    .anchor-menu .anchor-item.prolyte:before {
      background-color: #44a1d6; }
    .anchor-menu .anchor-item.litec:before {
      background-color: #fff; }
    .anchor-menu .anchor-item.tomcat:before {
      background-color: #00853D; }
    .anchor-menu .anchor-item.jte:before {
      background-color: #3700A9; }
    .anchor-menu .anchor-item.exe:before {
      background-color: #F73C37; }
    .anchor-menu .anchor-item:focus {
      text-decoration: none; }
    .anchor-menu .anchor-item:hover::before {
      opacity: 1; }

.solution-item {
  margin-bottom: 50px; }
  @media (min-width: 1200px) {
    .solution-item {
      margin-bottom: 100px; } }
  .solution-item .solution-item-top {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    @media (min-width: 545px) {
      .solution-item .solution-item-top {
        padding: 20px 30px;
        flex-wrap: nowrap; } }
    @media (min-width: 768px) {
      .solution-item .solution-item-top {
        padding: 30px 40px; } }
    @media (min-width: 1400px) {
      .solution-item .solution-item-top {
        padding: 50px 60px; } }
  .solution-item .solution-item-top-title {
    font-size: 28px;
    line-height: 37.6px;
    font-weight: 400;
    width: 100%; }
    @media (min-width: 545px) {
      .solution-item .solution-item-top-title {
        width: auto; } }
    @media (min-width: 992px) {
      .solution-item .solution-item-top-title {
        font-size: 38px;
        line-height: 47.6px; } }
    @media (min-width: 1400px) {
      .solution-item .solution-item-top-title {
        font-size: 48px;
        line-height: 57.6px; } }
  .solution-item .solution-item-top-logo img {
    max-width: 160px; }
    @media (min-width: 992px) {
      .solution-item .solution-item-top-logo img {
        max-width: 200px; } }
    @media (min-width: 1400px) {
      .solution-item .solution-item-top-logo img {
        max-width: 260px; } }
  .solution-item .solution-item-description {
    max-width: 970px;
    margin: 40px auto 20px auto;
    font-size: 18px;
    line-height: 33.2px;
    text-align: center; }
    @media (min-width: 768px) {
      .solution-item .solution-item-description {
        margin: 50px auto 20px auto;
        font-size: 20px;
        line-height: 36.2px; } }
    @media (min-width: 1400px) {
      .solution-item .solution-item-description {
        margin: 100px auto 40px auto;
        font-size: 24px;
        line-height: 43.2px; } }
  .solution-item .btn-default {
    border: 1px solid #D9D9D9;
    background: none;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 15px 30px;
    transition: 250ms; }
  .solution-item .solution-item-btn {
    text-align: center; }
  .solution-item.milos .solution-item-top {
    background-color: #ffd100;
    color: #000; }
  .solution-item.milos .link-more:hover {
    border-color: #ffd100; }
  .solution-item.milos .link-more-text {
    color: #ffd100; }
    .solution-item.milos .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(255, 209, 0, 0.9); }
      @media (min-width: 545px) {
        .solution-item.milos .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(255, 209, 0, 0.9); } }
  .solution-item.milos .link {
    color: #ffd100; }
  .solution-item.milos .image-nav-box.slick-current .image-nav-item {
    border-color: #ffd100; }
  .solution-item.milos .slick-arrow:not(.slick-disabled):hover {
    color: #ffd100; }
  .solution-item.milos .solution-product-item-link:hover {
    color: #ffd100; }
  .solution-item.milos .btn-default:hover {
    border-color: #ffd100;
    color: #ffd100; }
  .solution-item.prolyte .solution-item-top {
    background-color: #44a1d6;
    color: #fff; }
  .solution-item.prolyte .link-more:hover {
    border-color: #44a1d6; }
  .solution-item.prolyte .link-more-text {
    color: #44a1d6; }
    .solution-item.prolyte .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(68, 161, 214, 0.9); }
      @media (min-width: 545px) {
        .solution-item.prolyte .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(68, 161, 214, 0.9); } }
  .solution-item.prolyte .link {
    color: #44a1d6; }
  .solution-item.prolyte .image-nav-box.slick-current .image-nav-item {
    border-color: #44a1d6; }
  .solution-item.prolyte .slick-arrow:not(.slick-disabled):hover {
    color: #44a1d6; }
  .solution-item.prolyte .solution-product-item-link:hover {
    color: #44a1d6; }
  .solution-item.prolyte .btn-default:hover {
    border-color: #44a1d6;
    color: #44a1d6; }
  .solution-item.tomcat .solution-item-top {
    background-color: #00853D;
    color: #fff; }
  .solution-item.tomcat .link-more:hover {
    border-color: #00853D; }
  .solution-item.tomcat .link-more-text {
    color: #00853D; }
    .solution-item.tomcat .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(0, 133, 61, 0.9); }
      @media (min-width: 545px) {
        .solution-item.tomcat .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(0, 133, 61, 0.9); } }
  .solution-item.tomcat .link {
    color: #00853D; }
  .solution-item.tomcat .image-nav-box.slick-current .image-nav-item {
    border-color: #00853D; }
  .solution-item.tomcat .slick-arrow:not(.slick-disabled):hover {
    color: #00853D; }
  .solution-item.tomcat .solution-product-item-link:hover {
    color: #00853D; }
  .solution-item.tomcat .btn-default:hover {
    border-color: #00853D;
    color: #00853D; }
  .solution-item.jte .solution-item-top {
    background-color: #3700A9;
    color: #fff; }
  .solution-item.jte .link-more:hover {
    border-color: #3700A9; }
  .solution-item.jte .link-more-text {
    color: #3700A9; }
    .solution-item.jte .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(55, 0, 169, 0.9); }
      @media (min-width: 545px) {
        .solution-item.jte .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(55, 0, 169, 0.9); } }
  .solution-item.jte .link {
    color: #3700A9; }
  .solution-item.jte .image-nav-box.slick-current .image-nav-item {
    border-color: #3700A9; }
  .solution-item.jte .slick-arrow:not(.slick-disabled):hover {
    color: #3700A9; }
  .solution-item.jte .solution-product-item-link:hover {
    color: #3700A9; }
  .solution-item.jte .btn-default:hover {
    border-color: #3700A9;
    color: #3700A9; }
  .solution-item.exe .solution-item-top {
    background-color: #F73C37;
    color: #fff; }
  .solution-item.exe .link-more:hover {
    border-color: #F73C37; }
  .solution-item.exe .link-more-text {
    color: #F73C37; }
    .solution-item.exe .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(247, 60, 55, 0.9); }
      @media (min-width: 545px) {
        .solution-item.exe .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(247, 60, 55, 0.9); } }
  .solution-item.exe .link {
    color: #F73C37; }
  .solution-item.exe .image-nav-box.slick-current .image-nav-item {
    border-color: #F73C37; }
  .solution-item.exe .slick-arrow:not(.slick-disabled):hover {
    color: #F73C37; }
  .solution-item.exe .solution-product-item-link:hover {
    color: #F73C37; }
  .solution-item.exe .btn-default:hover {
    border-color: #F73C37;
    color: #F73C37; }
  .solution-item.litec .solution-item-top {
    background-color: #fff;
    color: #000; }
  .solution-item.litec .link-more:hover {
    border-color: #fff; }
  .solution-item.litec .link-more-text {
    color: #fff; }
    .solution-item.litec .link-more-text:before {
      box-shadow: 0px 0px 150px 40px rgba(255, 255, 255, 0.9); }
      @media (min-width: 545px) {
        .solution-item.litec .link-more-text:before {
          box-shadow: 0px 0px 190px 60px rgba(255, 255, 255, 0.9); } }
  .solution-item.litec .link {
    color: #fff; }
  .solution-item.litec .image-nav-box.slick-current .image-nav-item {
    border-color: #fff; }
  .solution-item.litec .slick-arrow:not(.slick-disabled):hover {
    color: #fff; }
  .solution-item.litec .solution-product-item-link:hover {
    color: #fff; }
  .solution-item.litec .btn-default:hover {
    border-color: #fff;
    color: #fff; }

.solution-image-item {
  background-position: center center !important;
  background-size: cover !important;
  position: relative; }
  .solution-image-item .solution-image-item-bgc {
    width: 100%; }
    .solution-image-item .solution-image-item-bgc img {
      width: 100%;
      height: auto !important; }
  .solution-image-item .solution-image-item-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .solution-image-item .solution-image-item-content {
    width: 100%;
    padding: 35px; }
    @media (min-width: 768px) {
      .solution-image-item .solution-image-item-content {
        padding: 45px; } }
    @media (min-width: 1400px) {
      .solution-image-item .solution-image-item-content {
        padding: 65px; } }
  .solution-image-item .solution-image-item-inner {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px; }
    @media (min-width: 1400px) {
      .solution-image-item .solution-image-item-inner {
        padding: 25px; } }
  .solution-image-item .solution-image-item-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: uppercase; }
    @media (min-width: 545px) {
      .solution-image-item .solution-image-item-title {
        font-size: 20px;
        line-height: 28px; } }
    @media (min-width: 768px) {
      .solution-image-item .solution-image-item-title {
        font-size: 25px;
        line-height: 32px; } }
    @media (min-width: 1400px) {
      .solution-image-item .solution-image-item-title {
        font-size: 32px;
        line-height: 40px; } }
  .solution-image-item .solution-image-item-btn {
    margin-top: 10px; }
  .solution-image-item .link {
    font-size: 16px;
    font-weight: 400;
    line-height: normal; }
  .solution-image-item .link-arrow:after {
    content: "\e940";
    font-size: 12px;
    margin-left: 10px;
    transition: 250ms; }
  .solution-image-item .link-arrow:hover:after {
    margin-left: 15px; }

.solution-image-nav {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  padding: 0 40px; }
  .solution-image-nav .image-nav-box {
    padding: 0 5px;
    width: 160px; }
  .solution-image-nav .image-nav-item {
    position: relative;
    width: 100%;
    display: flex;
    border: 3px solid transparent;
    cursor: pointer;
    transition: 250ms; }
    .solution-image-nav .image-nav-item:after {
      content: "";
      display: block;
      padding-top: 100%; }
    .solution-image-nav .image-nav-item .image-nav-item-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center center !important;
      background-size: cover !important; }
    .solution-image-nav .image-nav-item:hover {
      opacity: 0.8; }
  .solution-image-nav.slick-initialized {
    display: block; }
  .solution-image-nav .slick-arrow {
    font-size: 0;
    background: none;
    border: none;
    position: absolute;
    z-index: 33;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    width: 30px;
    padding: 0;
    height: 40px;
    outline: 0;
    cursor: pointer;
    transition: 250ms; }
    .solution-image-nav .slick-arrow:before {
      font-size: 40px; }
  .solution-image-nav .slick-disabled {
    color: rgba(255, 255, 255, 0.5);
    cursor: default; }
  .solution-image-nav .slick-prev {
    left: 0; }
    .solution-image-nav .slick-prev:before {
      content: "\e920"; }
  .solution-image-nav .slick-next {
    right: 0; }
    .solution-image-nav .slick-next:before {
      content: "\e91f"; }

.solution-slider {
  display: flex;
  overflow: hidden; }
  .solution-slider .solution-image-item {
    min-width: 100%; }
  .solution-slider.slick-initialized {
    display: block; }
    .solution-slider.slick-initialized .solution-image-item {
      min-width: unset;
      display: flex; }

.solution-item-products {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; }
  @media (min-width: 1200px) {
    .solution-item-products {
      margin-top: 100px;
      gap: 0; } }
  .solution-item-products .solution-product-item {
    width: calc(50% - 5px);
    min-width: 150px;
    min-height: 150px;
    position: relative; }
    @media (min-width: 545px) {
      .solution-item-products .solution-product-item {
        width: calc(50% - 5px); } }
    @media (min-width: 768px) {
      .solution-item-products .solution-product-item {
        width: calc(33.33% - 5px); } }
    @media (min-width: 1200px) {
      .solution-item-products .solution-product-item {
        width: 20%; } }
    .solution-item-products .solution-product-item:after {
      content: "";
      display: block;
      padding-top: 100%; }
  .solution-item-products .solution-product-item-link {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    transition: 250ms; }
    .solution-item-products .solution-product-item-link img {
      height: calc(100% - 50px) !important;
      object-fit: contain;
      margin-bottom: 20px;
      flex-grow: 1; }
  .solution-item-products .link-more {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
    transition: 250ms; }
  .solution-item-products .link-more-text {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: 24px;
    line-height: normal; }
    .solution-item-products .link-more-text:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 1px; }

.solution-images {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0; }
  @media (min-width: 768px) {
    .solution-images {
      padding: 60px 0; } }
  .solution-images img {
    max-height: 180px; }
  .solution-images .solution-image-item-text {
    padding-top: 20px;
    text-align: center;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px; }

.solution-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; }
  .solution-row .solution-50 {
    width: 100%; }
    @media (min-width: 992px) {
      .solution-row .solution-50 {
        width: calc(50% - 10px); } }
  @media (min-width: 992px) {
    .solution-row .solution-50 + .solution-50 {
      text-align: right; } }

.solution-title {
  font-weight: 900;
  font-size: 28px;
  line-height: 34px; }
  @media (min-width: 768px) {
    .solution-title {
      font-size: 34px;
      line-height: 40px; } }
  .solution-title .marker {
    color: #949494;
    font-size: 19px;
    line-height: 26px;
    position: relative;
    top: -5px; }
    @media (min-width: 768px) {
      .solution-title .marker {
        font-size: 25px;
        line-height: 30px; } }

.solution-list-text ul {
  margin-top: 15px; }
  .solution-list-text ul li {
    margin: 0 0 15px 0;
    font-size: 17px;
    line-height: 21px;
    padding: 0 0 0 30px; }
    @media (min-width: 768px) {
      .solution-list-text ul li {
        font-size: 20px;
        line-height: 24px; } }
    .solution-list-text ul li:before {
      content: "\e91d";
      background: none;
      color: #FF0707;
      width: 10px;
      height: 20px;
      top: 0px;
      left: -7px;
      font-size: 25px; }

.solution-banner {
  padding: 7rem 0; }
  .solution-banner img {
    border-radius: 3rem; }

.solution-tab-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem; }
  .solution-tab-nav .solution-tab-item {
    flex-grow: 1;
    background: #fff;
    color: #F73C37;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    min-height: 70px;
    font-weight: 900;
    padding: 10px;
    cursor: pointer;
    position: relative; }
    @media (min-width: 992px) {
      .solution-tab-nav .solution-tab-item:after {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 15px 17px 15px;
        border-color: transparent transparent #F73C37 transparent;
        transform: rotate(0deg);
        position: absolute;
        left: 50%;
        bottom: -19px;
        margin-left: -15px;
        display: none; } }
    @media (min-width: 545px) {
      .solution-tab-nav .solution-tab-item {
        width: calc(50% - 1rem); } }
    @media (min-width: 768px) {
      .solution-tab-nav .solution-tab-item {
        font-size: 20px;
        line-height: 27px; } }
    @media (min-width: 1200px) {
      .solution-tab-nav .solution-tab-item {
        font-size: 23px;
        line-height: 30px; } }
    @media (min-width: 992px) {
      .solution-tab-nav .solution-tab-item {
        width: calc(25% - 1.5rem); } }
    .solution-tab-nav .solution-tab-item.active {
      background: #F73C37;
      color: #fff; }
      .solution-tab-nav .solution-tab-item.active:after {
        display: block; }

.solution-tab-content {
  background: #F73C37;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  display: none;
  margin-top: 18px; }
  @media (min-width: 768px) {
    .solution-tab-content {
      font-size: 20px;
      line-height: 24px; } }
  .solution-tab-content.active {
    display: block; }

.solution-person {
  border-bottom: 3px solid #F73C37;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-top: 4rem; }
  @media (min-width: 545px) {
    .solution-person {
      padding-left: 5rem; } }
  .solution-person .solution-person-description {
    background: #fff;
    color: #000;
    font-size: 18px;
    line-height: 22px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .solution-person .solution-person-description {
        font-size: 20px;
        line-height: 24px; } }
    @media (min-width: 545px) {
      .solution-person .solution-person-description:before {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 10px 11px 10px 0;
        border-color: transparent #fff transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        top: 20px;
        left: -10px; } }
  .solution-person .solution-person-image {
    min-width: 200px;
    display: none; }
    @media (min-width: 545px) {
      .solution-person .solution-person-image {
        display: block; } }
  .solution-person .solution-person-content {
    max-width: 900px;
    padding-bottom: 20px; }
  .solution-person .solution-person-name {
    font-size: 22px;
    line-height: 27px;
    font-weight: 900;
    padding-left: 20px; }
    @media (min-width: 768px) {
      .solution-person .solution-person-name {
        font-size: 30px;
        line-height: 36px; } }
  .solution-person .solution-person-info {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    padding-left: 20px; }
    @media (min-width: 768px) {
      .solution-person .solution-person-info {
        font-size: 20px;
        line-height: 24px; } }

.solution-content {
  max-width: 1473px;
  margin: 0 auto; }

.solution-helper {
  display: flex;
  padding-top: 5px; }
  @media (min-width: 992px) {
    .solution-helper {
      justify-content: flex-end; } }

.solution-brands {
  display: grid;
  gap: 10px 15px;
  max-width: 300px;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-end; }
  .solution-brands a {
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .solution-brands a:hover, .solution-brands a:focus {
      text-decoration: none;
      font-weight: 400; }

.solution-bottom {
  padding-top: 6rem; }

.anchor-menu-label {
  padding-top: 6rem;
  font-size: 18px;
  line-height: 22px; }
  @media (min-width: 768px) {
    .anchor-menu-label {
      font-size: 20px;
      line-height: 24px; } }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?a399er");
  src: url("fonts/icomoon.eot?a399er#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?a399er") format("woff2"), url("fonts/icomoon.ttf?a399er") format("truetype"), url("fonts/icomoon.woff?a399er") format("woff"), url("fonts/icomoon.svg?a399er#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-flexa-logo:before {
  content: "\e93f"; }

.ico-prolyft-logo:before {
  content: "\e93d";
  color: #fff; }

.ico-stagedex-logo:before {
  content: "\e93e";
  color: #fff; }

.ico-afi-arrow-circle_reapired:before {
  content: "\e902";
  color: #fff; }

.ico-afi-menu-arrow_reapired:before {
  content: "\e904";
  color: #fff; }

.ico-tomcat-logo:before {
  content: "\e901";
  color: #fff; }

.ico-prolyte:before {
  content: "\e917"; }

.ico-prolyte-old:before {
  content: "\e90b"; }

.ico-exe-technology:before {
  content: "\e924"; }

.ico-mobiltechlifts:before {
  content: "\e925"; }

.ico-xstage:before {
  content: "\e926"; }

.ico-xtruss:before {
  content: "\e927"; }

.ico-logo2:before {
  content: "\e91c"; }

.ico-logo:before {
  content: "\e91b"; }

.ico-header-logo:before {
  content: "\e923"; }

.ico-Vector-2:before {
  content: "\e940";
  color: #ffdf00; }

.ico-Fill_3:before {
  content: "\e90a"; }

.ico-instagram:before {
  content: "\e900"; }

.ico-reddit-social-logo-character:before {
  content: "\e919";
  color: #043a63; }

.ico-x:before {
  content: "\e921";
  color: #ffd100; }

.ico-litec_arrow_detail_arrow_small-left:before {
  content: "\e920"; }

.ico-litec_arrow_detail_arrow_small:before {
  content: "\e91f"; }

.ico-ico-arrow-big-ng2:before {
  content: "\e90e"; }

.ico-ico-arrow3:before {
  content: "\e90c"; }

.ico-ico-arrow2:before {
  content: "\e90d"; }

.ico-ico-arrow-big-down:before {
  content: "\e903"; }

.ico-ico-region:before {
  content: "\e905"; }

.ico-ico-yt-icon:before {
  content: "\e906"; }

.ico-ico-arrow-big-ng:before {
  content: "\e907"; }

.ico-ico-arrow:before {
  content: "\e908"; }

.ico-ico-search:before {
  content: "\e909"; }

.ico-ico-share-button-linkedin-icon:before {
  content: "\e910"; }

.ico-ico-share-button-mail-icon:before {
  content: "\e911"; }

.ico-ico-share-button-tweet-icon:before {
  content: "\e912"; }

.ico-ico-share-button-vimeo-icon:before {
  content: "\e913"; }

.ico-ico-share-button-facebook-icon:before {
  content: "\e914"; }

.ico-ico-share-button-google-icon:before {
  content: "\e915"; }

.ico-ico-share-button-twitter-icon:before {
  content: "\e916"; }

.ico-chevron-down:before {
  content: "\e918"; }

.ico-chevron-left:before {
  content: "\e91a"; }

.ico-chevron-right:before {
  content: "\e91d"; }

.ico-chevron-small-down:before {
  content: "\e91e"; }

.ico-chevron-small-left:before {
  content: "\e928"; }

.ico-chevron-small-right:before {
  content: "\e929"; }

.ico-chevron-small-up:before {
  content: "\e92a"; }

.ico-chevron-thin-down:before {
  content: "\e92b"; }

.ico-chevron-thin-left:before {
  content: "\e92c"; }

.ico-chevron-thin-right:before {
  content: "\e92d"; }

.ico-chevron-thin-up:before {
  content: "\e92e"; }

.ico-chevron-up:before {
  content: "\e92f"; }

.ico-circle-with-minus:before {
  content: "\e930"; }

.ico-circle-with-plus:before {
  content: "\e931"; }

.ico-info:before {
  content: "\e932"; }

.ico-link:before {
  content: "\e933"; }

.ico-mail:before {
  content: "\e934"; }

.ico-map:before {
  content: "\e935"; }

.ico-paper-plane:before {
  content: "\e936"; }

.ico-phone:before {
  content: "\e937"; }

.ico-facebook:before {
  content: "\e938"; }

.ico-instagram1:before {
  content: "\e939"; }

.ico-linkedin:before {
  content: "\e93a"; }

.ico-pinterest:before {
  content: "\e93b"; }

.ico-twitter:before {
  content: "\e93c"; }

.ico-map-pin:before {
  content: "\e90f"; }

.ico-spinner:before {
  content: "\e922"; }

.ico-loading:before {
  content: "\e922"; }

.ico-loading-wheel:before {
  content: "\e922"; }

.ico-busy:before {
  content: "\e922"; }

.ico-wait:before {
  content: "\e922"; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  background: transparent;
  position: relative;
  overflow: hidden;
  border: 1px solid #ffffff;
  font-weight: 900;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  @media (min-width: 320px) {
    .selectric {
      height: 10rem; } }
  @media (min-width: 480px) {
    .selectric {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .selectric {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .selectric {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .selectric {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .selectric {
      height: 5.55556rem; } }
  @media (min-width: 1400px) {
    .selectric {
      height: 5rem; } }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 10rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 9.09091rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 8.33333rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 6.25rem; } }
    @media (min-width: 1200px) {
      .selectric .label {
        line-height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 10rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 9.09091rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 8.33333rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .selectric .label {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:after {
      font-size: 3rem;
      content: "";
      color: #fff;
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      font-weight: 900; }
      @media (min-width: 480px) {
        .selectric .button:after {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .selectric .button:after {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .selectric .button:after {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .selectric .button:after {
          font-size: 1.875rem; } }
      @media (min-width: 1200px) {
        .selectric .button:after {
          font-size: 1.66667rem; } }
      @media (min-width: 1400px) {
        .selectric .button:after {
          font-size: 1.5rem; } }

.selectric-focus .selectric {
  border: 2px solid #fff; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #ffffff;
    border-bottom: 1px solid #ffffff; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #ffffff;
    border-top: 1px solid #ffffff; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border: 2px solid #ffffff;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto;
    background-color: #f73c37; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #fff; }
    .selectric-items li.highlighted {
      color: #fff; }
    .selectric-items li:hover {
      background: #f6241e; }
    .selectric-items li:nth-child(odd) {
      background: rgba(255, 255, 255, 0.1); }
      .selectric-items li:nth-child(odd):hover {
        background: #f6241e; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #000 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #000; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: rgba(0, 0, 0, 0);
    vertical-align: middle;
    position: static;
    border: 2px solid rgba(0, 0, 0, 0); }
  .list-box .selectric-scroll ul li.selected:before {
    background: #f73c37; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

@media print {
  header,
  footer {
    display: none; } }
