@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.career-top {
    @include media(lg) {
        display: flex;
        border-bottom: 1px solid $color-gray-2;
    }
    &-content {
        @include media(lg) {
            flex: 0 1 60%;
            max-width: 60%;
            padding-bottom: 4.6rem;
            padding-top: 6rem;
        }
        .btn-red,
        .btn-blue {
            margin-top: 10px;
            margin-right: 4px;
            @include media(xs) {
                margin-top: 4rem;
            }
        }
        .btn-red {
            @include media(xxs, xxs) {
                margin-top: 25px;
            }
        }
    }
    &-image {
        border-bottom: 1px solid $color-gray-2;
        @include media(lg) {
            flex: 0 1 40%;
            max-width: 40%;
            border-bottom: 0px;
        }
    }
}

.career-top-font {
    h1 {
      @include pux-scale-with-min("font-size", 54px, 30px);
      @include pux-scale-with-min("line-height", 58px, 34px);
      font-weight: 900;
      margin-bottom: 2rem;
      //font-family: 'Zirkel-Black';
    }

    h2 {
      @include pux-scale-with-min("font-size", 34px, 24px);
      @include pux-scale-with-min("line-height", 38px, 28px);
      font-weight: 900;
      //font-family: 'Zirkel-Black';
    }
    h3 {
      @include pux-scale-with-min("font-size", 24px, 18px);
      @include pux-scale-with-min("line-height", 28px, 22px);
      font-weight: 900;
      //font-family: 'Zirkel-Black';
    }

    h4 {
      @include pux-scale-with-min("font-size", 18px, 16px);
      @include pux-scale-with-min("line-height", 22px, 20px);
      font-weight: 900;
      //font-family: 'Zirkel-Black';
    }
    ul {
        li {
            padding-left: 35px;
            &:before {
                border-radius: 0;
                width: 15px;
                height: 1px;
                top: 8px;
                @include media(md) {
                    top: 9px;
                }
                @include media(xl) {
                    width: 1.5rem;
                    top: 1rem;
                }
            }
        }
    }
}

.btn-red,
.btn-blue,
.btn-base {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: inherit;
    padding: 1rem 2.7rem;
    line-height: 1;
    font-weight: 900 !important;
    @include media(xl) {
        height: 5rem;
    }
}

.btn-red {
    background-color: $main-color;
    &:hover,
    &:focus {
        background-color: darken($main-color, 5);
    }
}
.btn-blue {
    background-color: $base-blue;
    &:hover,
    &:focus {
        background-color: darken($base-blue, 5);
    }
}

.top-base-padding {
    padding-top: 90px;
    @include media(md) {
        padding-top: 120px;
    }
    @include media(lg) {
        padding-top: 12rem;
    }
}

.career-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5rem;
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    @include media(lg) {
        padding-top: 0;
        height: 100%;
        margin: 0;
        max-width: none;
        align-items: flex-end;
    }
    &-tooltip {
        position: relative;
        align-self: flex-end;
        max-width: 310px;
        padding: 1.6rem 5rem 1.6rem 2.0rem;
        background-color: $base-black;
        color: $base-white;
        font-size: 15px;
        @include media(md) {
            font-size: 16px;
        }
        @include media(lg) {
            max-width: 27rem;
            font-size: 1.4rem;
        }
        strong {
            display: inline-block;
            padding-bottom: 0.2rem;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 100px;
            transform: translateY(100%);
            display: block;
            @include triangle-right(0px, 25px, 25px, $base-black);
            @include media(xs) {
                left: 4rem;
            }
            @include media(md) {
                left: 2rem;
            }
        }
    }
    img {
        margin-top: 1.4rem;
        max-width: 92%;
        max-height: 75vh;
        @include media(lg) {
            max-height: none;
        }
    }
}

.career-card {
    display: block;
    color: $base-black;
    background-color: #f5f5f5;
    margin-top: 10px;
    &-title {
        color: $base-white;
        text-transform: uppercase;
        font-size: 18px;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-weight: 900;
        line-height: 1;
        padding: 4px 20px;
        @include media(lg) {
            min-height: 4rem;
            font-size: 1.6rem;
            padding: 0.4rem 2.2rem;
        }
    }
    &-content {
        padding: 25px 20px 0;
        font-size: 15px;
        line-height: 1.4;
        @include media(md) {
            font-size: 16px;
        }
        @include media(lg) {
            padding: 2rem 2.2rem 0;
            font-size: 1.5rem;
        }
    }
    &-bottom {
        padding: 0 20px 25px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        line-height: 1.4;
        @include media(md) {
            font-size: 16px;
        }
        @include media(lg) {
            padding: 0 2.2rem 2rem;
            font-size: 1.5rem;
        }
        &-place,
        &-btn {
            margin-top: 20px;
            @include media(lg) {
                margin-top: 1.5rem;
            }
        }
        &-place {
            &:before {
                content: "\e90f";
                font-size: 24px;
                vertical-align: sub;
                @include media(lg) {
                    font-size: 2.2rem;
                }
            }
        }
        &-btn {
            color: $base-white;
        }
    }
    &:hover,
    &:focus {
        font-weight: 400;
        color: $base-black;
        text-decoration: none;
        background-color: darken(#f5f5f5, 4);
    }
    &.wide-cart {
        margin-top: 10px;
        @include media(lg) {
            margin-top: 2.8rem;
        }
    }
    @each $name, $color in $brand-colors {
        &.card-#{$name} {
            .career-card-title {
                background-color: $color;
            }
            .btn.btn-base {
                background-color: $color;
                &:hover,
                &:focus {
                    background-color: darken($color, 8);
                }
            }
            .circle-link {
                &:after {
                    background-color: $color;
                    border-color: $color;
                    color: $base-white;
                }
                &:hover,
                &:focus {
                    &:after {
                        background-color: darken($color, 8);
                        border-color: darken($color, 8);
                        color: $base-white;
                    }
                }
            }
        }
    }
}

.career-list {
    padding: 2rem 0 4rem;
    @include media(lg) {
        padding: 1rem 0 3rem;
    }
    &-filter {
        @include media(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &-title,
        &-content {
            margin-top: 3rem;
        }
    }
}

.career-filter {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
    @include transition;
    opacity: 0;
    @include media(lg) {
        margin-top: 1rem;
    }
    @include pux-scale-from("font-size", lg, 18px);
    &.active {
        opacity: 1;
    }
    &-title {
        flex: 0 0 auto;
        padding-right: 10px;
        font-size: 15px;
        transform: translateY(4%);
        @include media(md) {
            font-size: 16px;
        }
        @include media(lg) {
            font-size: 1.4rem;
        }
    }
    &-list {
        position: relative;
        overflow: hidden;
        &-inner {
            display: flex;
            //flex-wrap: wrap;
            justify-content: flex-start;
            overflow: auto;
        }
        .slick-prev {
            display: none !important;
        }
        &.active{
            .career-filter-item {
                &:last-of-type {
                    padding-right: 150px;
                }
            }
        }
        .arrow-next {
            display: none;
            &.active {
                display: flex;
            }
        }
    }
    &-item {
        cursor: pointer;
        margin-left: 16px;
        @include transition;
        user-select: none;
        outline: 0;
        white-space: nowrap;
        flex: 0 0 auto;
        font-size: 16px;
        font-weight: 700;
        @include media(md) {
            font-size: 18px;
        }
        @include media(lg) {
            margin-left: 1.6rem;
            font-size: 1.6rem;
        }
        &:hover,
        &:focus,
        &.active {
            text-decoration: underline;
        }
        @each $name, $color in $brand-colors {
            &[data-filter="#{$name}"] {
                &:hover,
                &:focus,
                &.active {
                    color: $color;
                }
            }
        }
    }
}

.career-reference {
    border-top: 1px solid $color-gray-2;
    &-item {
        text-align: center;
        margin-top: 25px;
        padding: 0 10vw;
        @include media(sm) {
            padding: 0 5vw;
        }
        @include media(md) {
            font-size: 16px;
            margin-top: 30px;
            padding: 0;
        }
        @include media(lg) {
            font-size: 1.5rem;
            margin-top: 3rem;
        }
        &-image {
            position: relative;
            overflow: hidden;
            &:before {
                content: "";
                display: block;
                padding-top: 45%;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                border-radius: 50%;
                overflow: hidden;
            }
        }
        &-title {
            margin-top: 20px;
            @include media(xxl) {
                margin-top: 2rem;
            }
        }
        &-position,
        &-text {
            margin-top: 8px;
            @include media(xxl) {
                margin-top: 0.8rem;
            }
        }
    }
}

.bottom-text {
    text-align: center;
    max-width: 60rem;
    padding: 50px 0 30px;
    position: relative;
    margin: auto;
    @include media(md) {
        padding: 8rem 0 3rem;
    }
}

.brand-location {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    @include media(xl) {
        font-size: 1.5rem;
    }
    &-company,
    &-state {
        margin-top: 25px;
        margin-right: 2rem;
        @include media(xl) {
            margin-top: 2.5rem;
        }
    }
    &-company {
        text-transform: uppercase;
        font-weight: 900;
    }
    &-state {
        &:before {
            content: "\e90f";
            font-size: 24px;
            vertical-align: sub;
            line-height: inherit;
            display: inline-block;
            margin-right: 4px;
            @include media(lg) {
                font-size: 2.2rem;
            }
        }
    }
}

.career-back {
    .btn {
        position: relative;
        color: $base-black;
        transform: none;
    }
}

.career-detail {
    border: 0;
    font-size: 14px;
    line-height: 1.45;
    @include media(md) {
        font-size: 15px;
    }
    @include media(lg) {
        font-size: 1.45rem;
    }
    .career-top-content {
        padding-top: 0;
        padding-bottom: 0;
    }
    .career-top-image {
        border: 0;
    }
    .career-image {
        justify-content: flex-start;
        padding-top: 6rem;
    }
    big {
        font-size: 16px;
        line-height: 1.2;
        @include media(md) {
            font-size: 17px;
        }
        @include media(lg) {
            font-size: 1.8rem;
        }
    }
}

.black-form {
    @include bs-grid(1.5rem);
    .ErrorLabel {
        padding-bottom: 2rem;
    }
    input,
    textarea {
        border-color: $base-black;
        color: $base-black;
        font-weight: 400;
        padding-left: 2rem;
        padding-right: 50px;
        @include transition;
        @include pux-scale-with-min("height", 45px, 45px);
        @include placeholder{
            color: $base-black;
        }
        &:focus {
            border: 1px solid $main-color;
            color: $base-black;
        }
    }
    textarea {
        height: 120px;
        resize: none;
    }
    .InfoLabel {
        font-size: 16px;
        font-weight: 600;
        @include media(lg) {
            font-size: 1.8rem;
        }
    }
    .Error,
    .form-validation-error {
        input[type=text],
        textarea {
            border: 1px solid $main-color !important;
        }
        &:after {
            background-color: $main-color;
            color: $base-white;
        }
    }
    [class^="col-"],
    .form-bottom > * {
        padding-top: 0;
        padding-bottom: 25px;
        @include media(lg) {
            padding-bottom: 3rem;
        }
    }
    .file label,
    input[type=submit] {
        font-weight: 900 !important;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: inherit;
        padding: 1rem 2.7rem;
        line-height: 1;
        white-space: nowrap;
        max-width: none;
        @include media(xl) {
            height: 5rem;
        }
    }
    .file label {
        background-color: $base-black;
        text-transform: none;
        width: auto;
    }
    .file-name {
        display: inline-block;
        word-break: break-all;
        word-wrap: break-word
    }
    input[type=submit] {
        color: $base-white;
        background-color: $main-color;
        &:hover,
        &:focus {
            background-color: darken($main-color, 5);
        }
    }
    .form-bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0;
    }
}

@each $name, $color in $brand-colors {
  .color-#{$name} {
      color: $color;
  }
}

.form-page-title {
    display: none;
}
