
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.label-form,
label{
  display: block;
  margin:1rem 0 0.5rem 0;
  font-size: 14px;
  font-weight: 500;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]{
  -moz-appearance:textfield;
}

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"]{
  width:100%;
  @include pux-scale-with-min("height", $input-height, 50px);
  padding: 0 1.5rem;
  border: 1px solid $border-color;
  background: $input-background;
  color:$base-white;
  font-weight: 900;
  @include pux-static-size("font-size", 14px);
  @include border-radius(0);
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    outline: 0!important;
    border: 2px solid $focus-border-color;
    background: rgba(#fff,0.1);
    color: $base-white;
  }
}

textarea.form-control,
textarea{
  @include pux-scale-with-min("height", $textarea-height, 100px);
  padding: 1.5rem;
    &:focus{
      border: 1px solid $focus-border-color;
    }
}


input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
    label{
        &:before{
            content:"*";
            display: inline-block;
            vertical-align: 0.6rem;
            margin: 0 2px 0 0;
            @include pux-static-size("font-size", 10px);
        }
    }
}

label{
  &.required{
    &:after{
        content:"*";
        display: inline-block;
        vertical-align: 0.6rem;
        margin: 0 2px 0 0;
        color:$main-color;
        @include pux-static-size("font-size", 10px);
    }
  }
}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      padding:1rem 2rem;
      text-transform:uppercase;
      color: $upload-color;
      background: $upload-background;
      cursor:pointer;
      @include pux-static-size("font-size", 14px);
      @include border-radius(0);
      max-width: 24rem;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
  }
  .file-name{
      padding:0.5rem;
      background: $file-name-background;
      color: $file-name-color;
  }
}

@if($use-blink-error == 1){
  .form-validation-error,
  .Error:not(.form-validation-valid){
      select,
      .selectric,
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      textarea{
          border: 2px solid $base-white !important;
          //@include box-shadow(inset 0 0 0 1px $error-input-border);
          @include animation(normal, blink, 1s, ease, 2);
      }
  }

  @include keyframes(blink){
      0% {
          background: $error-blink-start;
      }
      50% {
          background: $error-blink-final;
      }
      100% {
          background: $error-blink-start;
      }
  }
}

div.form-validation-valid{
  .FormErrorLabel,
  .EditingFormErrorLabel{
    display: none;
  }
}

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel{
    @include pux-static-size("font-size", 14px);
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.EditingFormErrorLabel{
  display: none;
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 1rem 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 1rem 25px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:16px;
                height:16px;
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $checkbox-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.form{
  padding-top: 5px;
  @include media(xl){
    padding-top: .5rem;
  }
}

.form-row{
  @include flex-block;
  @include flex-wrap(wrap);
  @include justify-content(center);
  margin: 0 -10px;
  @include media(xl){
    margin: 0 -1rem;
  }
  &.btn-row{
    padding-top: 25px;
    margin: 0 -5px;
    @include justify-content(flex-end);
    @include media(xl){
      padding-top: 2.5rem;
      margin: 0 -.5rem;
    }
  }
}

.row-form{
  [class^="col"]{
    padding-left:10px;
    padding-right:10px;
    @include media(xl){
      padding-left:1rem;
      padding-right:1rem;
    }
  }
}

.form-50{
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  @include media(md){
    width: 50%;
  }
  @include media(xl){
    padding-left:1rem;
    padding-right:1rem;
  }
}

.form-100{
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  @include media(xl){
    padding-left:1rem;
    padding-right:1rem;
  }
}

.form-container{
  padding: 15px 20px 30px;
  background: $main-color;
  max-width: 500px;
  color: $base-white;
  @include media(xl){
    max-width: 50rem;
    padding: 1.5rem 2rem 3rem;
  }
  &.newsletter-box{
    padding: 30px 20px 35px;
    margin: auto;
    text-align: center;
    @include media(xl){
      padding: 3rem 2rem 3.5rem;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}

.newsletter-row{
  padding: 2.5rem 0;
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  flex-direction: column;
  @include media(sm) {
      flex-direction: row;
  }
  //@include flex-wrap(wrap);
  .ErrorLabel {
      color: $base-black;
  }
  input {
      height: 50px;
      @include pux-scale-from("height", lg, 50px);
  }
  &-input{
    padding: 0 5px;
    @include media(xl){
      padding: 0 0.5rem;
    }
    input {
        width: 220px;
        box-sizing: border-box;
        @include media(xl) {
            width: 22rem;
        }
    }
  }
  &-btn{
    max-width: 110px;
    padding: 0 5px;
    margin-top: 10px;
    @include media(sm) {
        margin-top: 0;
    }
    @include media(xl){
      max-width: 11rem;
    }
    .btn {
      font-family: 'Zirkel';
      padding: 1.5rem 20px;
      @include media(xl){
        padding: 1.5rem 2rem;
      }
    }
  }
  &  * {
      @include media(xxs, xs) {
          width: 100% !important;
          max-width: 100% !important;
      }
  }
}

.iframe-newsletter {
  width: 100%;
  height: 350px;
  border: none;
  @include media(sm) {
    height: 300px;
  }
}

.nwl-box {
    .ErrorLabel {
        color: $base-black;
        display: block;
        transform: translateY(10px);
        font-size: 14px;
        @include media(lg) {
            font-size: 1.8rem;
        }
    }
}

.nwl-title {
    color: $base-black;
    display: block;
    font-size: 14px;
    margin: 10px 0;
    font-weight: 900;
    @include media(lg) {
        margin: 1rem 0;
        font-size: 1.8rem;
    }
}

.newsletter-text {
    position: relative;
    max-width: 300px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 700;
    @include media(lg) {
        max-width: 30rem;
    }
    @include pux-scale-from("font-size", lg, 14px);
    a {
        font-weight: 700 !important;
        text-decoration: underline;
    }
}

.editing-form-control-nested-control{
  position: relative;
  &:after{
    content: "\e921";
    position: absolute;
    right: 0px;
    top: 50%;
    @include transform(translate(-100%,-50%));
    display: none;
    font-size: 8px;
    color: $main-color;
    background: $base-white;
    @include border-radius(50%);
    @include align-items(center);
    @include justify-content(center);
    width: 18px;
    height: 18px;
    font-weight: 900;
    line-height: 1;
  }
  &.Error{
    &:after{
      @include flex-block;
    }
  }
}

.form-row{
  .placeholder{
    label{
      height: 10px;
      @include media(xl){
        height: 1rem;
      }
    }
  }
}
