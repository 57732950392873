@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@mixin solution-theme($color, $color-text){
  .solution-item-top{
    background-color: $color;
    color: $color-text;
  }
  .link-more:hover{
    border-color: $color;
  }
  .link-more-text{
    color: $color;
    &:before{
      box-shadow: 0px 0px 150px 40px rgba($color,0.9);
      @include media(sm){
        box-shadow: 0px 0px 190px 60px rgba($color,0.9);
      }
    }
  }
  .link{
    color: $color;
  }
  .image-nav-box.slick-current .image-nav-item{
    border-color: $color;
  }
  .slick-arrow:not(.slick-disabled):hover{
    color: $color;
  }
  .solution-product-item-link:hover{
     color: $color;
  }
  .btn-default:hover{
    border-color: $color;
    color: $color;
  }
}

.solution-top {
  min-height: 160px;
  background-position: top center !important;
  background-size: cover !important;
  position: relative;
  @include media(md){
    //height: 640px;
  }
  @include media(lg){
    //height: 840px;
  }
  .pux-container{
    width: 100%;
  }
  .solution-bgc{
    position: relative;
    width: 100%;
    img{
      width: 100%;
      height: auto !important;
    }
    &:after{
      content: "";
      width: 100%;
      height: 100px;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .solution-absolute{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include media(md){
      position: absolute;
    }
    .pux-container{
      width: 100%;
    }
  }
  .solution-top-inner{
    max-width: 1220px;
    margin: 0 auto;
    font-size: 20px;
    line-height: normal;
    font-weight: 900;
    //margin-top: 90px;
    letter-spacing: 1px;
    text-align: center;
    @include media(md){
      font-size: 25px;
      //margin-top: 130px;
    }
    @include media(lg){
      font-size: 31px;
      //margin-top: 181px;
    }
    h1{
      font-size: 25px;
      line-height: normal;
      font-weight: 900;
      margin: 0 0 20px 0;
      @include media(md){
        font-size: 40px;
        letter-spacing: 1.2px;
        margin: 0 0 30px 0;
      }
      @include media(lg){
        font-size: 74px;
        letter-spacing: 1.5px;
        margin: 0 0 50px 0;
      }
      @include media(xl){
        margin: 0 0 70px 0;
      }
    }
  }
  .solution-breacrumbs{
    position: absolute;
    left: 0;
    top: 8.5rem;
    width: 100%;
    padding-top: 35px;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    display: none;
    @include media(md){
      display: block;
    }
    .breadcrumbs-separator{
      margin: 0 5px;
    }
    .CMSBreadCrumbsCurrentItem{
      color: $main-color;
      font-weight: 500;
    }
    a{
      font-weight: 500;
      &:hover{
        font-weight: 500;
      }
    }
  }
}

.anchor-menu{
  display: flex;
  margin: 10px 0 25px 0;
  flex-wrap: wrap;
  gap: 3rem;
  @include media(md){
    flex-wrap: nowrap;
    margin: 10px 0 50px 0;
  }
  .anchor-item{
    //padding: 15px 20px 15px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    @include media(md){
      font-size: 18px;
    }
    &:before{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      opacity: 0;
      transition: 250ms;
    }
    &.milos{
      &:before{
        background-color: $milos-color;
      }
    }
    &.prolyte{
      &:before{
        background-color: $prolyte-color;
      }
    }
    &.litec{
      &:before{
        background-color: #fff;
      }
    }
    &.tomcat{
      &:before{
        background-color: $tomcat-color;
      }
    }
    &.jte{
      &:before{
        background-color: #3700A9;
      }
    }
    &.exe{
      &:before{
        background-color: $exe-color;
      }
    }
    &:focus{
      text-decoration: none;
    }
    &:hover{
      &::before{
        opacity: 1;
      }
    }
  }
}

.solution-item{
  margin-bottom: 50px;
  @include media(xl){
    margin-bottom: 100px;
  }
  .solution-item-top{
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @include media(sm){
      padding: 20px 30px;
      flex-wrap: nowrap;
    }
    @include media(md){
      padding: 30px 40px;
    }
    @include media(xxl){
      padding: 50px 60px;
    }
  }
  .solution-item-top-title{
    font-size: 28px;
    line-height: 37.6px;
    font-weight: 400;
    width: 100%;
    @include media(sm){
      width: auto;
    }
    @include media(lg){
      font-size: 38px;
      line-height: 47.6px;
    }
    @include media(xxl){
      font-size: 48px;
      line-height: 57.6px;
    }
  }
  .solution-item-top-logo img{
    //min-height: 50px;
    max-width: 160px;
    @include media(lg){
      max-width: 200px;
    }
    @include media(xxl){
      max-width: 260px;
    }
  }
  .solution-item-description{
    max-width: 970px;
    margin: 40px auto 20px auto;
    font-size: 18px;
    line-height: 33.2px;
    text-align: center;
    @include media(md){
      margin: 50px auto 20px auto;
      font-size: 20px;
      line-height: 36.2px;
    }
    @include media(xxl){
      margin: 100px auto 40px auto;
      font-size: 24px;
      line-height: 43.2px;
    }
  }
  .btn-default{
    border: 1px solid #D9D9D9;
    background: none;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 15px 30px;
    transition: 250ms;
  }
  .solution-item-btn{
    text-align: center;
  }
  &.milos{
    @include solution-theme($milos-color, #000);
  }
  &.prolyte{
    @include solution-theme($prolyte-color, #fff);
  }
  &.tomcat{
    @include solution-theme($tomcat-color, #fff);
  }
  &.jte{
    @include solution-theme(#3700A9, #fff);
  }
  &.exe{
    @include solution-theme($exe-color, #fff);
  }
  &.litec{
    @include solution-theme(#fff, #000);
  }
}



.solution-image-item{
  //height: 335px;
  //padding: 35px;
  background-position: center center !important;
  background-size: cover !important;
  position: relative;
  @include media(md){
    //padding: 45px;
    //height: 435px;
  }
  @include media(xxl){
    //padding: 65px;
    //height: 535px;
  }
  .solution-image-item-bgc{
    width: 100%;
    img{
      width: 100%;
      height: auto !important;
      //aspect-ratio: 16/9;
    }
  }
  .solution-image-item-absolute{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solution-image-item-content{
    width: 100%;
    padding: 35px;
    @include media(md){
      padding: 45px;
    }
    @include media(xxl){
      padding: 65px;
    }
  }
  .solution-image-item-inner{
    display: inline-block;
    background-color: rgba(0,0,0,0.8);
    padding: 15px;
    @include media(xxl){
      padding: 25px;
    }
  }
  .solution-image-item-title{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: uppercase;
    @include media(sm){
      font-size: 20px;
      line-height: 28px;
    }
    @include media(md){
      font-size: 25px;
      line-height: 32px;
    }
    @include media(xxl){
      font-size: 32px;
      line-height: 40px;
    }
  }
  .solution-image-item-btn{
    margin-top: 10px;
  }
  .link {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
  .link-arrow{
    &:after{
      content: "\e940";
      font-size: 12px;
      margin-left: 10px;
      transition: 250ms;
    }
    &:hover{
      &:after{
        margin-left: 15px;
      }
    }
  }
}

.solution-image-nav{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  padding: 0 40px;
  .image-nav-box{
    padding: 0 5px;
    width: 160px;
  }
  .image-nav-item{
    position: relative;
    width: 100%;
    display: flex;
    border: 3px solid transparent;
    cursor: pointer;
    transition: 250ms;
    &:after{
      content: "";
      display: block;
      padding-top: 100%;
    }
    .image-nav-item-image{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center center !important;
      background-size: cover !important;
    }
    &:hover{
      opacity: 0.8;
    }
  }
  &.slick-initialized{
    display: block;
  }
  .slick-arrow{
    font-size: 0;
    background: none;
    border: none;
    position: absolute;
    z-index: 33;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    width: 30px;
    padding: 0;
    height: 40px;
    outline: 0;
    cursor: pointer;
    transition: 250ms;
    &:before{
      font-size: 40px;
    }
  }
  .slick-disabled{
    color: rgba(255,255,255,0.5);
    cursor: default;
  }
  .slick-prev{
    left: 0;
    &:before{
      content: "\e920";
    }
  }
  .slick-next{
    right: 0;
    &:before{
      content: "\e91f";
    }
  }
}

.solution-slider{
  display: flex;
  overflow: hidden;
  .solution-image-item{
    min-width: 100%;
  }
  &.slick-initialized{
    display: block;
    .solution-image-item{
      min-width: unset;
      display: flex;
    }
  }
}

.solution-item-products{
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  @include media(xl){
    margin-top: 100px;
    gap: 0;
  }
  .solution-product-item{
    width: calc(50% - 5px);
    min-width: 150px;
    min-height: 150px;
    position: relative;
    @include media(sm){
      width: calc(50% - 5px);
    }
    @include media(md){
      width: calc(33.33% - 5px);
    }
    @include media(xl){
      width: 20%;
    }
    &:after{
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  .solution-product-item-link{
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    padding:25px;
    display: flex;
    flex-direction: column;
    transition: 250ms;
    img{
      height: calc(100% - 50px) !important;
      object-fit: contain;
      margin-bottom: 20px;
      flex-grow: 1;
    }
  }
  .link-more{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
    transition: 250ms;
  }
  .link-more-text{
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: 24px;
    line-height: normal;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 1px;
      height: 1px;
    }
  }
}

.solution-images{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0;
  @include media(md){
    padding: 60px 0;
  }
  img{
    max-height: 180px;
  }
  .solution-image-item-text{
    padding-top: 20px;
    text-align: center;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
  }
}

.solution-row{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .solution-50{
    width: 100%;
    @include media(lg){
      width: calc(50% - 10px);
    }
  }
  .solution-50 + .solution-50{
    @include media(lg){
      text-align: right;
    }
  }
}

.solution-title{
  font-weight: 900;
  font-size: 28px;
  line-height: 34px;
  @include media(md){
    font-size: 34px;
    line-height: 40px;
  }
  .marker{
    color: #949494;
    font-size: 19px;
    line-height: 26px;
    position: relative;
    top: -5px;
    @include media(md){
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.solution-list-text{
  ul{
    margin-top: 15px;
    li{
      margin: 0 0 15px 0;
      font-size: 17px;
      line-height: 21px;
      padding: 0 0 0 30px;
      @include media(md){
        font-size: 20px;
        line-height: 24px;
      }
      &:before{
        content: "\e91d";
        background: none;
        color: #FF0707;
        width: 10px;
        height: 20px;
        top: 0px;
        left: -7px;
        font-size: 25px;
      }
    }
  }
}

.solution-banner{
  padding: 7rem 0;
  img{
    border-radius: 3rem;
  }
}

.solution-tab-nav{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  .solution-tab-item{
    flex-grow: 1;
    background: #fff;
    color: #F73C37;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    min-height: 70px;
    font-weight: 900;
    padding: 10px;
    cursor: pointer;
    position: relative;
    &:after{
      @include media(lg){
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 15px 17px 15px;
        border-color: transparent transparent #F73C37 transparent;
        transform: rotate(0deg);
        position: absolute;
        left: 50%;
        bottom: -19px;
        margin-left: -15px;
        display: none;
      }
    }
    @include media(sm){
      width: calc(50% - 1rem);
    }
    @include media(md){
      font-size: 20px;
      line-height: 27px;
    }
    @include media(xl){
      font-size: 23px;
      line-height: 30px;
    }
    @include media(lg){
      width: calc(25% - 1.5rem);
    }
    &.active{
      background: #F73C37;
      color: #fff;
      &:after{
        display: block;
      }
    }
  }
}

.solution-tab-content{
  background: #F73C37;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  display: none;
  margin-top: 18px;
  @include media(md){
    font-size: 20px;
    line-height: 24px;
  }
  &.active{
    display: block;
  }
}

.solution-person{
  border-bottom: 3px solid #F73C37;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-top: 4rem;
  @include media(sm){
    padding-left: 5rem;  
  }
  .solution-person-description{
    background: #fff;
    color: #000;
    font-size: 18px;
    line-height: 22px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    @include media(md){
      font-size: 20px;
      line-height: 24px;
    }
    &:before{
      @include media(sm){
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 10px 11px 10px 0;
        border-color: transparent #fff transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        top: 20px;
        left: -10px;
      }
    }
  }
  .solution-person-image{
    min-width: 200px;
    display: none;
    @include media(sm){
      display: block;
    }
  }
  .solution-person-content{
    max-width: 900px;
    padding-bottom: 20px;
  }
  .solution-person-name{
    font-size: 22px;
    line-height: 27px;
    font-weight: 900;
    padding-left: 20px;
    @include media(md){
      font-size: 30px;
      line-height: 36px;
    }
  }
  .solution-person-info{
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    padding-left: 20px;
    @include media(md){
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.solution-content{
  max-width: 1473px;
  margin: 0 auto;
}

.solution-helper{
  display: flex;
  padding-top: 5px;
  @include media(lg){
    justify-content: flex-end;
  }
}

.solution-brands{
  display: grid;
  gap: 10px 15px;
  max-width: 300px;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-end;
  a{
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus{
      text-decoration: none;
      font-weight: 400;
    }
  }
}

.solution-bottom{
  padding-top: 6rem;
}

.anchor-menu-label{
  padding-top: 6rem;
  font-size: 18px;
  line-height: 22px;
  @include media(md){
    font-size: 20px;
    line-height: 24px;
  }
}