@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.talents-page{
    background: #fff;
    .btn-primary{
        background-color: $main-color;
        color: $base-white;
        border: none;
        padding: 1.4rem 3.5rem;
        border-radius: 5px;
        &:focus,
        &:hover{
            background-color: darken($main-color, 10);
        }
    }
    h2,
    h3{
        color: #000;
    }
    .h1{
        color: #fff;
    }
    .marker{
        color: $main-color;
    }
    .scroll-btn{
        width: 40px;
        height: 40px;
        background-color: $main-color;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 5rem;
        transform: translateX(-50%);
        &:before{
            content:"\e903";
            font-size: clamp(25px, 2.5rem, 2.5rem);
            font-weight: 700;
        }
        span{
            display: none;
        }
        &:focus,
        &:hover{
            text-decoration: none;
        }
        &:hover{
            background-color: darken($main-color, 10);
        }
    }
    .selectric{
        max-width: 200px;
        min-width: 200px;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid #A5A5A5;
        text-align: left;
        @include media(md){
            max-width: 280px;
            min-width: 280px;
        }
    }
    .selectric .button:after,
    .selectric .label{
        color: #000;
        font-weight: 500;
    }
    .selectric-items{
        border-radius: 5px;
        font-weight: 500;
        padding: 1rem 0;
        background-color: #fff;
        border: 1px solid #A5A5A5;
    }
    .selectric-items .selectric-scroll{
        background-color: #fff;
    }
    .selectric-items li:nth-child(odd){
        background-color: #fff;
    }
    .selectric-items li{
        color: #000;
    }
    .selectric-items li:hover,
    .selectric-items li:nth-child(odd):hover{
        background-color: #fff;
    }
    .selectric-items li.highlighted{
        background-color: #fff;
        color: #A5A5A5;
    }
    .selectric-open .selectric{
        border: 1px solid #A5A5A5;    
    }
    .selectric-open .selectric-items{
        border: 1px solid #A5A5A5;      
    }
    .selectric .button:after{
        content: "\e903";
        font-weight: 700;
        transform: rotate(0deg);
        font-size: 35px;
    }
    .talents-top-drop{
        display: flex;
        justify-content: flex-end;
    }
    .bread-container{
        display: none;
        @include media(lg){
            display: block;
        }
    }
    .bread-container{
     .arrow-next.black-mode{
        display: none;
     }   
    }
    .talents-bottom-text{
        text-align: center;
        padding: 50px 0;
        @include media(lg){
            padding: 80px 0;
        }
    }
}

.job-top{
    width: 100%;
    height: 66.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-position: top center !important;
    background-size: cover !important;
    position: relative;
    .job-top-inner{
        width: 100%;
    }
    .job-top-btn{
        margin-top: 3rem;
    }
    .job-top-absolute{
        position: absolute;
        left: 0;
        top: 13rem;
        width: 100%;
    }
    .job-top-setting{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include media(lg){
            justify-content: space-between;
        }
    }
}

.talents-top{
    width: 100%;
    height: 70vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-position: top center !important;
    background-size: cover !important;
    position: relative;
    @include media(lg){
        height: 100vh;
    }
    .talents-top-inner{
        width: 100%;
        text-align: center;
    }
    .talents-top-btn{
        margin-top: 3rem;
    }
    .talents-top-absolute{
        position: absolute;
        left: 0;
        top: 13rem;
        width: 100%;
    }
    .talents-top-setting{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include media(lg){
            justify-content: space-between;
        }
    }
}

.talents-numbers{
    padding: 9rem 0;
    text-align: center;
}

.talents-numbers-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4rem;
    //gap: 4rem;
    .talents-number-item{
        width: 100%;
        background-color: #f5f5f5;
        padding: 30px 5px;
        @include media(sm){
            width: 50%;
        }
        @include media(md){
            width: 33.33%;
        }
        &:nth-child(even){
            background-color: #ededed;
        }
        @include media(sm, sm){
            &:nth-child(3){
                background-color: #ededed;
            }
            &:nth-child(4){
                background-color: #f5f5f5;
            }
        }
    }
    .talents-number-item-ico{
        margin-bottom: 1rem;
    }
    .talents-number-item-number{
        color: $main-color;
        @include media(xxs, xxs) {
            font-size: 34px;
            line-height: 38px;
        }
        @include pux-scale-with-min("font-size", 72px, 34px);
        @include pux-scale-with-min("line-height", 76px, 38px);
        font-weight: 900;
    }
    .talents-number-item-title{
        color: #000;
        @include pux-scale-with-min("font-size", 20px, 16px);
        @include pux-scale-with-min("line-height", 26px, 20px);
        font-weight: 900;
        margin-top: 1rem;
    }
}


.talents-logos{
    background-color: #F5F5F5;
    padding: 9rem 0 12rem 0;
    position: relative;
    h2{
        br{
            display: none;
        }
        @include media(md){
            white-space: nowrap;
            br{
                display: block;
            }
        }
    }
}

.talents-logos-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5rem;
    @include media(md){
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.talents-logos-images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem 5rem;
    margin-bottom: 3rem;
    img{
        filter: grayscale(100%);
        max-height: 40px;
    }
    @include media(md){
        margin-bottom: 0;
        justify-content: flex-start;
    }
}

.talents-image{
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: top center !important;
    background-size: cover !important;
    background-color: #A5A5A5;
    position: relative;
    @include media(sm){
        height: 70vh;
    }
    @include media(lg){
        height: 100vh;
    }
    &.talents-image-small{
        @include media(sm){
            height: 70vh;
            max-height: 610px;
        }
        @include media(lg){
            height: 100vh;
            max-height: 810px;
        }
    }
    .talents-image-inner{
        width: 100%;
    }
    .talents-image-desc{
        @include pux-scale-with-min("font-size", 24px, 18px);
        @include pux-scale-with-min("line-height", 28px, 22px);
        font-weight: 900;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap:3rem;
        margin-top: 2rem;
    }
    h2{
        color: #fff;
    }
}

.talents-testimonial{
    padding: 8rem 0;
}

.talents-testimonial-item{
    max-width: 1055px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @include media(sm){
        flex-wrap: nowrap;
    }
    .talents-testimonial-item-image{
        min-width: 165px;
        width: 165px;
        margin: 0 auto 6rem auto;
        @include media(sm){
            margin: 0 4rem 0 0;
        }
    }
    .talents-testimonial-item-desc{
        background-color: #A5A5A5;   
        padding: 2.7rem 2.7rem 1.5rem 2.7rem;
        border-radius: 10px;
        position: relative;
        width: 100%;
        @include media(sm){
            width: auto;
            border-radius: 0px 10px 10px 10px;
        }
        &:before{
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent #A5A5A5 transparent;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            @include media(sm){
                top: 0;
                left: -20px;
                transform: translateX(0);
                border-width: 0 20px 20px 0;
                border-color: transparent #A5A5A5 transparent transparent;
            }
        }
    }
    .talents-testimonial-item-bottom{
        margin-top: 10px;
    }
    .talents-testimonial-item-name{
        display: inline-block;
        color: #000;
        font-weight: 600;
    }
}

.jobs-positions{
    padding: 10rem 0 3rem 0;
    text-align: center;
    .jobs-title{
        text-align: left;
        color: #000;
        @include pux-scale-with-min("font-size", 24px, 18px);
        @include pux-scale-with-min("line-height", 28px, 22px);
        font-weight: 900;
        margin-bottom: 3rem;
    }
    .jobs-position-container{
        display: grid;
        flex-wrap: wrap;
        grid-gap: 4rem;
        margin-bottom: 5rem;
        grid-template-columns: 1fr;
        @include media(sm){
            grid-template-columns: 1fr 1fr;
        }
        @include media(lg){
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    .job-item{
        width: 100%;
        color: #fff;
        text-align: left;
        .job-item-image{
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                aspect-ratio: 16/10;
                object-fit: cover;
            };
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(247, 60, 55, 0.80);
                opacity: 0;
                transition: 250ms;
            }
        }
        .job-item-bottom{
            background-color: $main-color;
            border-radius: 0px 0px 10px 10px;
            padding: 2.2rem;
        }
        .job-item-name{
            @include pux-scale-with-min("font-size", 18px, 16px);
            @include pux-scale-with-min("line-height", 22px, 20px);
            font-weight: 900;
            margin-bottom: 1rem;
        }
        .job-item-place{

        }
        &:focus,
        &:hover{
            text-decoration: none;
            font-weight: 400;
            .job-item-image:before{
                opacity: 1;
            }
        }
    }
}

.jobs-cta{
    padding: 2rem 0;
    text-align: center;
    .jobs-cta-btn{
        margin-top: 2rem;
    }
}

.jobs-testimonial{
    padding: 4rem 0 0 0;
}

.jobs-slider{
    position: relative;
    .slick-arrow{
        background: none;
        position: absolute;
        z-index: 88;
        font-size: 0;
        bottom: 5rem;
        color: #fff;
        padding: 0;
        border: none;
        cursor: pointer;
        &:hover{
            color: $main-color;
        }
        &:focus{
            outline: 0;
        }
        @include media(md){
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
        }
    }
    .slick-prev {
        left: 7rem;
        &:before{
            content:"\e90e";
            font-size: clamp(40px, 4.2rem, 4.2rem);
        }
    }
    .slick-next{
        right: 7rem;
        &:before{
            content:"\e907";
            font-size: clamp(40px, 4.2rem, 4.2rem);
        }
    }
}

.job-layout{
    color: #000;
    padding-bottom: 1px;
    display: flex;
    flex-wrap: wrap;
    @include media(md){
        flex-wrap: nowrap;
    }
    h3{
        margin: 5.5rem 0 2rem 0;
    }
    .job-layout-content{
        width: 100%;
        max-width: 920px;
    }
    .job-layout-sidebar{
        max-width: 540px;
        margin: 40px auto 0 auto;
        @include media(md){
            margin: 0 0 0 11rem;
        }
    }
}

main .job-layout ul li{
    margin: 0 0 0.5rem 0;
}
main .job-layout ul li:before{
    top: 1.3rem;
}

.job-interviews{
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 4rem;
    margin-bottom: 6rem;
    position: relative;
    z-index: 88;
    @include media(md){
        margin-top: -20rem;
    }
    @include media(xl){
        margin-top: -30rem;
    }
    h4{
        margin: 0;
    }
    .job-interview-item{
        border-bottom: 1px solid #a5a5a5;
        font-weight: 500;
        padding: 2rem 0;
        span{
            display: block;
            color: $main-color;
            font-weight: 900;
        }
    }
}

.job-personal{
    margin-bottom: 6rem;
    img{
        border-radius: 10px;
    }
}

.talents-top,
.job-top{
    .breadcrumbs a{
        color: $main-color;
        font-weight: 900;
        font-size: 18px;
        &:focus,
        &:hover{
            text-decoration: underline;
        }
    }
    .breadcrumbs-separator:before{
        content: "/";
        font-size: 18px;
    }
    .CMSBreadCrumbsCurrentItem{
        font-size: 18px;
    }
}

.job-form{
    background-color: $main-color;
    padding: 4rem;
    border-radius: 10px;
    .row{
        display: block;
    }
    .col-12,
    .col-sm-6{
        padding: 0 2.5rem 20px 2.5rem;
        width: 100% !important;
        max-width: 100% !important;
    }
    .form-control,
    input[type="text"]{
        background-color: #fff;
        color: #000;
        border-radius: 5px;
        border: 1px solid #fff;
        font-weight: 500;
        @include placeholder{
            color: #000;
        }
        &:focus{
            outline: 0!important;
            background: #fff;
            color: #000;
            border-color: #000;
        }
    }
    .file label{
        width: 100%;
        background-color: #A5A5A5;
        color: #fff;
        text-transform: none;
        font-weight: 900;
        border-radius: 5px;
        max-width: 100%;
        padding: 1.4rem;
        &:hover{
            background-color: darken(#A5A5A5, 10);
        }
    }
    .file .file-name{
        background-color: #fff;
        width: 100%;
        display: block;
        margin-top: 10px;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
    }
    input[type="submit"]{
        background-color: #fff;
        color: $main-color;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        padding: 1.4rem;
        &:hover{
            background-color: darken(#fff, 10);
        }
    }
}